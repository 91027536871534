export const attributes = [
  {
    name: "id",
    type: "string",
    description:
      "The forgein key/external system ID number of the Licence, Skill / Qualification. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "value",
    type: "string",
    description: "The name of the Licence, Skill / Qualification.",
  },
];

export const payload = [
  { id: "4802", value: "Backhoe" },
  { id: "3557", value: "Client Induction 1" },
  { id: "4803", value: "Compactor" },
  { id: "4941", value: "COVID Vaccinated" },
  { id: "4801", value: "Dozer" },
  { id: "4800", value: "Excavator" },
  { id: "3555", value: "First aid" },
  { id: "4807", value: "Forklift" },
  { id: "4804", value: "Grader" },
  { id: "3556", value: "Rail card" },
  { id: "4805", value: "Skid Steer" },
  { id: "4806", value: "Telehandler" },
  { id: "3553", value: "Traffic Control Ticket" },
  { id: "4815", value: "Ventia" },
  { id: "3554", value: "White Card" },
];
