import moment from "moment";
import isNil from "lodash/isNil";

export const toIsoDate = (date, inputFormat = "DD/MM/YYYY") => {
  let dt = moment.isMoment(date) ? date.toDate() : date;

  return isNil(dt) ||
    moment(dt).format("DD/MM/YYYY").toUpperCase() == "01 JAN 1900"
    ? ""
    : moment(dt, inputFormat).format("YYYY-MM-DD HH:mm:ss").toUpperCase();
};

export const toShortDate = (date) => {
  let result = moment(date).format("DD/MM/YYYY");
  return result === "Invalid date" ? date : result;
};

export const fromIsoDateToShortDate = (date, format= "DD/MM/YY") => {
  let result = moment(date, "YYYY-MM-DD HH:mm:ss").format(format);
  return result === "Invalid date" ? date : result;
};

export const toShortIsoDate = (date) => {
  let result = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
  return result === "Invalid date" ? date : result;
};
