import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "../../services/apiService";

const initialState = {
  formDefaults: {
    id: "",
    companyName: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    companyPhoneNumber: "",
    email: "",
    invoiceEmail: "",
    tenantCode: "",
  },
  loading: false,
};

// ---------------
// Redux Thunk
// ---------------
export const loadData = createAsyncThunk(
  "viewClients/customerInfo/loadData",
  async (id, thunkAPI) => {
    try {
      let result = await ApiService().getCustomerInformation(id);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch account information.")
      );
      throw err;
    }
  }
);

export const update = createAsyncThunk(
  "viewClients/customerInfo/update",
  async (data, thunkAPI) => {
    try {
      await ApiService().updateCustomerInformation(data.id, {
        ...data.values,
        emailAddress: data.values.email,
        id: data.id,
      });

      thunkAPI.dispatch(
        addNotification("Customer has been successfully updated.")
      );

      return true;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to update customer information.")
      );
      throw err;
    }
  }
);

// ---------------
// Slice
// ---------------
export const customerInfoSlice = createSlice({
  name: "viewClients/customerInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //
    // initLoadData
    //
    builder.addCase(loadData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadData.fulfilled, (state, action) => {
      state.loading = false;
      state.formDefaults = {...action.payload, email: action.payload.emailAddress};
    });

    builder.addCase(loadData.rejected, (state) => {
      state.loading = false;
    });

    //
    // save
    //
    builder.addCase(update.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(update.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(update.rejected, (state) => {
      state.loading = false;
    });
  },
});

//export const {  } = customerInfoSlice.actions;

export default customerInfoSlice.reducer;

// ----------------
// Selectors
// ----------------
const selectSelf = (state) => state.customerInfo;

export const selectCustomerInfo = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
