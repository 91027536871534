export const enumGender = [
  { value: "0", description: "Male" },
  { value: "1", description: "Female" },
  { value: "2", description: "Other" },
  { value: "3", description: "Prefer not to say" },
];
export const enumPronouns = [
  { value: "0", description: "He/Him" },
  { value: "1", description: "She/Her" },
  { value: "2", description: "They/Them" },
  { value: "3", description: "Unspecified" },
];
export const enumSocialInclusion = [
  { value: "0", description: "Aboriginal" },
  { value: "1", description: "Aged over 50" },
  { value: "2", description: "Aged over 55" },
  { value: "3", description: "Aged under 24" },
  { value: "4", description: "Asylum seeker" },
  {
    value: "5",
    description: "Department of justice client or criminal record",
  },
  { value: "6", description: "Disadvantaged postcode" },
  { value: "7", description: "Disadvantaged youth" },
  { value: "8", description: "International student" },
  { value: "9", description: "Long-term unemployed" },
  { value: "10", description: "New migrant" },
  { value: "11", description: "Refugee" },
  { value: "12", description: "Retrenched due to COVID-19" },
  { value: "13", description: "Retrenched/Ex-automotive worker" },
  { value: "14", description: "Single parent" },
  { value: "15", description: "Veteran" },
  { value: "16", description: "Working with a disability" },
  { value: "17", description: "Torres Strait Islander" },
];
export const enumDriversLicences = [
  { value: "0", description: "Car" },
  { value: "1", description: "Light Rigid (LR)" },
  { value: "2", description: "Medium Rigid (MR)" },
  { value: "3", description: "Heavy Rigid (HR)" },
  { value: "4", description: "Heavy Combination (HC)" },
  { value: "5", description: "Multi Combination (MC)" },
  { value: "7", description: "Learner Licence" },
  { value: "8", description: "Probationary Licence" },
];
