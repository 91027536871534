export const attributes = [
  {
    name: "uploadName",
    type: "string",
    description:
      "The name of the Licence, Skill or Qualification Uploaded by the Applicant.",
  },
  {
    name: "uploadUrls",
    type: "array of string",
    description:
      "A collection of the URIs of the uploaded supporting documentation.",
  },
  {
    name: "uploadNumber",
    type: "string",
    description:
      "The Document Number of the Licence, Skill or Qualification Uploaded by the Applicant (if applicable).",
  },
  {
    name: "uploadExpiry",
    type: "DateTime (nullable)",
    description:
      "The Expiry Date of the Licence, Skill or Qualification Uploaded by the Applicant (if applicable).",
  },
  {
    name: "uploadIssue",
    type: "DateTime (nullable)",
    description:
      "The Issue Date of the Licence, Skill or Qualification Uploaded by the Applicant (if applicable).",
  },
  {
    name: "isDriversLicence",
    type: "bool",
    description:
      "Indicates whether or not the Uploaded Licence, Skill or Qualification is a Drivers Licence.",
  },
  {
    name: "driversLicenceNumber",
    type: "string",
    description:
      "If the Uploaded Licence, Skill or Qualification is a Drivers Licence, the Drivers Licence Number.",
  },
  {
    name: "driversLicenceType",
    type: "DriversLicenceType (nullable)",
    description:
      "If the Uploaded Licence, Skill or Qualification is a Drivers Licence, the Drivers Licence Type.",
    linkToTag: "#drivers-licence-type",
  },
  {
    name: "driversLicenceExpiry",
    type: "DateTime (nullable)",
    description:
      "If the Uploaded Licence, Skill or Qualification is a Drivers Licence, the Drivers Licence Expiry Date.",
  },
  {
    name: "driversLicenceAutoOnly",
    type: "bool (nullable)",
    description:
      "If the Uploaded Licence, Skill or Qualification is a Drivers Licence, is the Drivers Licence for Automatic Gearboxes only.",
  },
];
