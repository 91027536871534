import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { addErrorNotification } from "redux/system/system-action-creators";
import SignupProgressService from "services/signup-progress-service";
import { AccountSetupStatusTypes } from "constants/account-setup-status-types";
import { selectProfileData } from "redux/system/user-profile-reducer";

const initialState = {
  userExists: false,
  status: null,
  isAdmin: false,
  loading: true,
};

// ---------------
// Action Thunks
// ---------------
export const checkSignupProgressStatus = createAsyncThunk(
  "signup/getSignupProgressStatus",
  async (data, thunkAPI) => {
    try {
      let result = await SignupProgressService().checkProgress(data);
      
      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch signup progress status.")
      );
    }
  }
);

// ------------
// Slice
// ------------
export const signupProgressSlice = createSlice({
  name: "signupProgress",
  initialState,
  reducers: {
    doSignupInitialSetup: (state, action) => {
      state.status = AccountSetupStatusTypes.INITIAL;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkSignupProgressStatus.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(checkSignupProgressStatus.fulfilled, (state, action) => {
      state.userExists = action.payload.userExists;
      state.status = action.payload.status;
      state.isAdmin= action.payload.isAdmin;
      state.loading = false;
    });

    builder.addCase(checkSignupProgressStatus.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { doSignupInitialSetup } = signupProgressSlice.actions;

export default signupProgressSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.signupProgress;

export const selectSignupProgress = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

// export const selectAppSetup = createDraftSafeSelector(
//   selectSelf,
//   (state) => state
// );
