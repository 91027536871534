import * as Yup from "yup";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string(),
  confirmPassword: Yup.string().required()
    .min(1)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default resetPasswordSchema;
