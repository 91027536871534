import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./services/apiService";

const initialState = {
  getInTouchLoading: false,
  bookADemoLoading: false,
};

// ---------------
// Action Thunks
// ---------------
export const submitContactDetails = createAsyncThunk(
  "vipOfferPage/submitContactDetails",
  async (data, thunkAPI) => {
    try {
      const { name, email, phone, message } = data;

      let payload = {
        name,
        email,
        phone,
        message,
      };
      let result = await ApiService().submitContactDetails(payload);

      thunkAPI.dispatch(
        addNotification(
          "Thanks for contacting us, a member of our team will be in touch shortly."
        )
      );

      return result;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to send message."));
      throw err;
    }
  }
);

export const bookADemo = createAsyncThunk(
  "vipOfferPage/bookADemo",
  async (data, thunkAPI) => {
    try {
      const {
        name,
        companyName,
        email,
        phone,
        preferredDay,
        preferredTime,
        message,
      } = data;

      let payload = {
        name,
        companyName,
        email,
        phone,
        preferredDay,
        preferredTime,
        message,
      };

      let result = await ApiService().bookADemo(payload);

      thunkAPI.dispatch(
        addNotification(
          "Thanks for requesting a demo, a member of our team will be in touch shortly."
        )
      );

      return result;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to send message."));
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const vipOfferPageSlice = createSlice({
  name: "vipOfferPage",
  initialState,
  extraReducers: (builder) => {
    //
    // Contact details
    //
    builder.addCase(submitContactDetails.pending, (state) => {
      state.getInTouchLoading = true;
    });

    builder.addCase(submitContactDetails.fulfilled, (state) => {
      state.getInTouchLoading = false;
    });

    builder.addCase(submitContactDetails.rejected, (state) => {
      state.getInTouchLoading = false;
    });

    //
    // Book a demo
    //
    builder.addCase(bookADemo.pending, (state) => {
      state.bookADemoLoading = true;
    });

    builder.addCase(bookADemo.fulfilled, (state) => {
      state.bookADemoLoading = false;
    });

    builder.addCase(bookADemo.rejected, (state) => {
      state.bookADemoLoading = false;
    });
  },
});

export default vipOfferPageSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.vipOfferPage;

export const selectVipOfferPage = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
