const defaultTimezone = "(UTC+10:00) Canberra, Melbourne, Sydney";
const defaultCountry = "Australia";

export function getCurrentTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getCurrentTimezoneLookupValue(lookups) {
  let curr = getCurrentTimezone();

  let timezone = null;

  switch (curr) {
    case "America/Anchorage":
      timezone = "(UTC-09:00) Alaska";
      break;
    case "America/Atikokan":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Bahia_Banderas":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Belize":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Boise":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Cambridge_Bay":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Cancun":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Cayman":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Chicago":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Chihuahua":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Costa_Rica":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Creston":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Dawson":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Dawson_Creek":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Denver":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Detroit":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Edmonton":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/El_Salvador":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Fort_Nelson":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Grand_Turk":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Guatemala":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Havana":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Hermosillo":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Indiana/Indianapolis":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Indiana/Knox":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Indiana/Marengo":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Indiana/Petersburg":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Indiana/Tell_City":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Indiana/Vevay":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Indiana/Vincennes":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Indiana/Winamac":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Inuvik":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Iqaluit":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Jamaica":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Juneau":
      timezone = "(UTC-09:00) Alaska";
      break;
    case "America/Kentucky/Louisville":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Kentucky/Monticello":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Los_Angeles":
      timezone = "(UTC-08:00) Pacific Time (US & Canada)";
      break;
    case "America/Managua":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Matamoros":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Mazatlan":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Menominee":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Merida":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Metlakatla":
      timezone = "(UTC-09:00) Alaska";
      break;
    case "America/Mexico_City":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Monterrey":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Nassau":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/New_York":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Nipigon":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Nome":
      timezone = "(UTC-09:00) Alaska";
      break;
    case "America/North_Dakota/Beulah":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/North_Dakota/Center":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/North_Dakota/New_Salem":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Ojinaga":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Panama":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Pangnirtung":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Phoenix":
      timezone = "(UTC-07:00) Arizona";
      break;
    case "America/Port-au-Prince":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Rainy_River":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Rankin_Inlet":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Regina":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Resolute":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Sitka":
      timezone = "(UTC-09:00) Alaska";
      break;
    case "America/Swift_Current":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Tegucigalpa":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Thunder_Bay":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Tijuana":
      timezone = "(UTC-08:00) Pacific Time (US & Canada)";
      break;
    case "America/Toronto":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "America/Vancouver":
      timezone = "(UTC-08:00) Pacific Time (US & Canada)";
      break;
    case "America/Whitehorse":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "America/Winnipeg":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "America/Yakutat":
      timezone = "(UTC-09:00) Alaska";
      break;
    case "America/Yellowknife":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "Antarctica/McMurdo":
      timezone = "(UTC+12:00) Auckland, Wellington";
      break;
    case "Australia/Adelaide":
      timezone = "(UTC+09:30) Adelaide";
      break;
    case "Australia/Brisbane":
      timezone = "(UTC+10:00) Brisbane";
      break;
    case "Australia/Broken_Hill":
      timezone = "(UTC+10:00) Canberra, Melbourne, Sydney";
      break;
    case "Australia/Darwin":
      timezone = "(UTC+09:30) Darwin";
      break;
    case "Australia/Eucla":
      timezone = "(UTC+08:00) Perth";
      break;
    case "Australia/Hobart":
      timezone = "(UTC+10:00) Hobart";
      break;
    case "Australia/Lindeman":
      timezone = "(UTC+10:00) Brisbane";
      break;
    case "Australia/Lord_Howe":
      timezone = "(UTC+10:00) Brisbane";
      break;
    case "Australia/Melbourne":
      timezone = "(UTC+10:00) Canberra, Melbourne, Sydney";
      break;
    case "Australia/Perth":
      timezone = "(UTC+08:00) Perth";
      break;
    case "Australia/Sydney":
      timezone = "(UTC+10:00) Canberra, Melbourne, Sydney";
      break;
    case "CST6CDT":
      timezone = "(UTC-06:00) Central Time (US & Canada)";
      break;
    case "EST":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "EST5EDT":
      timezone = "(UTC-05:00) Eastern Time (US & Canada)";
      break;
    case "Etc/GMT":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Etc/GMT+0":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Etc/GMT-0":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Etc/GMT0":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Etc/Greenwich":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Etc/UCT":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Etc/Universal":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Etc/UTC":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Europe/Isle_of_Man":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Europe/London":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "GB":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "GB-Eire":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "GMT":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "GMT+0":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "GMT-0":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "GMT0":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "Greenwich":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "HST":
      timezone = "(UTC-10:00) Hawaii";
      break;
    case "Iceland":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "MST":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "MST7MDT":
      timezone = "(UTC-07:00) Mountain Time (US & Canada)";
      break;
    case "Pacific/Auckland":
      timezone = "(UTC+12:00) Auckland, Wellington";
      break;
    case "Pacific/Chatham":
      timezone = "(UTC+12:00) Auckland, Wellington";
      break;
    case "Pacific/Honolulu":
      timezone = "(UTC-10:00) Hawaii";
      break;
    case "PST8PDT":
      timezone = "(UTC-08:00) Pacific Time (US & Canada)";
      break;
    case "Universal":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "UTC":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    case "WET":
      timezone = "(UTC+00:00) Dublin, Edinburgh, Lisbon, London";
      break;
    default:
      timezone = defaultTimezone;
      break;
  }

  return lookups.find((f) => f.label.includes(timezone));
}

export function getCurrentCountryLookupValue(lookups) {
  let curr = getCurrentTimezone();

  let country = null;

  switch (curr) {
    case "America/Anchorage":
      country = "United States";
      break;
    case "America/Atikokan":
      country = "United States";
      break;
    case "America/Bahia_Banderas":
      country = "United States";
      break;
    case "America/Belize":
      country = "United States";
      break;
    case "America/Boise":
      country = "United States";
      break;
    case "America/Cambridge_Bay":
      country = "United States";
      break;
    case "America/Cancun":
      country = "United States";
      break;
    case "America/Cayman":
      country = "United States";
      break;
    case "America/Chicago":
      country = "United States";
      break;
    case "America/Chihuahua":
      country = "United States";
      break;
    case "America/Costa_Rica":
      country = "United States";
      break;
    case "America/Creston":
      country = "United States";
      break;
    case "America/Dawson":
      country = "United States";
      break;
    case "America/Dawson_Creek":
      country = "United States";
      break;
    case "America/Denver":
      country = "United States";
      break;
    case "America/Detroit":
      country = "United States";
      break;
    case "America/Edmonton":
      country = "United States";
      break;
    case "America/El_Salvador":
      country = "United States";
      break;
    case "America/Fort_Nelson":
      country = "United States";
      break;
    case "America/Grand_Turk":
      country = "United States";
      break;
    case "America/Guatemala":
      country = "United States";
      break;
    case "America/Havana":
      country = "United States";
      break;
    case "America/Hermosillo":
      country = "United States";
      break;
    case "America/Indiana/Indianapolis":
      country = "United States";
      break;
    case "America/Indiana/Knox":
      country = "United States";
      break;
    case "America/Indiana/Marengo":
      country = "United States";
      break;
    case "America/Indiana/Petersburg":
      country = "United States";
      break;
    case "America/Indiana/Tell_City":
      country = "United States";
      break;
    case "America/Indiana/Vevay":
      country = "United States";
      break;
    case "America/Indiana/Vincennes":
      country = "United States";
      break;
    case "America/Indiana/Winamac":
      country = "United States";
      break;
    case "America/Inuvik":
      country = "United States";
      break;
    case "America/Iqaluit":
      country = "United States";
      break;
    case "America/Jamaica":
      country = "United States";
      break;
    case "America/Juneau":
      country = "United States";
      break;
    case "America/Kentucky/Louisville":
      country = "United States";
      break;
    case "America/Kentucky/Monticello":
      country = "United States";
      break;
    case "America/Los_Angeles":
      country = "United States";
      break;
    case "America/Managua":
      country = "United States";
      break;
    case "America/Matamoros":
      country = "United States";
      break;
    case "America/Mazatlan":
      country = "United States";
      break;
    case "America/Menominee":
      country = "United States";
      break;
    case "America/Merida":
      country = "United States";
      break;
    case "America/Metlakatla":
      country = "United States";
      break;
    case "America/Mexico_City":
      country = "United States";
      break;
    case "America/Monterrey":
      country = "United States";
      break;
    case "America/Nassau":
      country = "United States";
      break;
    case "America/New_York":
      country = "United States";
      break;
    case "America/Nipigon":
      country = "United States";
      break;
    case "America/Nome":
      country = "United States";
      break;
    case "America/North_Dakota/Beulah":
      country = "United States";
      break;
    case "America/North_Dakota/Center":
      country = "United States";
      break;
    case "America/North_Dakota/New_Salem":
      country = "United States";
      break;
    case "America/Ojinaga":
      country = "United States";
      break;
    case "America/Panama":
      country = "United States";
      break;
    case "America/Pangnirtung":
      country = "United States";
      break;
    case "America/Phoenix":
      country = "United States";
      break;
    case "America/Port-au-Prince":
      country = "United States";
      break;
    case "America/Rainy_River":
      country = "United States";
      break;
    case "America/Rankin_Inlet":
      country = "United States";
      break;
    case "America/Regina":
      country = "United States";
      break;
    case "America/Resolute":
      country = "United States";
      break;
    case "America/Sitka":
      country = "United States";
      break;
    case "America/Swift_Current":
      country = "United States";
      break;
    case "America/Tegucigalpa":
      country = "United States";
      break;
    case "America/Thunder_Bay":
      country = "United States";
      break;
    case "America/Tijuana":
      country = "United States";
      break;
    case "America/Toronto":
      country = "United States";
      break;
    case "America/Vancouver":
      country = "United States";
      break;
    case "America/Whitehorse":
      country = "United States";
      break;
    case "America/Winnipeg":
      country = "United States";
      break;
    case "America/Yakutat":
      country = "United States";
      break;
    case "America/Yellowknife":
      country = "United States";
      break;
    case "Antarctica/McMurdo":
      country = "New Zealand";
      break;
    case "Australia/Adelaide":
      country = "Australia";
      break;
    case "Australia/Brisbane":
      country = "Australia";
      break;
    case "Australia/Broken_Hill":
      country = "Australia";
      break;
    case "Australia/Darwin":
      country = "Australia";
      break;
    case "Australia/Eucla":
      country = "Australia";
      break;
    case "Australia/Hobart":
      country = "Australia";
      break;
    case "Australia/Lindeman":
      country = "Australia";
      break;
    case "Australia/Lord_Howe":
      country = "Australia";
      break;
    case "Australia/Melbourne":
      country = "Australia";
      break;
    case "Australia/Perth":
      country = "Australia";
      break;
    case "Australia/Sydney":
      country = "Australia";
      break;
    case "CST6CDT":
      country = "United States";
      break;
    case "EST":
      country = "United States";
      break;
    case "EST5EDT":
      country = "United States";
      break;
    case "Etc/GMT":
      country = "United Kingdom";
      break;
    case "Etc/GMT+0":
      country = "United Kingdom";
      break;
    case "Etc/GMT-0":
      country = "United Kingdom";
      break;
    case "Etc/GMT0":
      country = "United Kingdom";
      break;
    case "Etc/Greenwich":
      country = "United Kingdom";
      break;
    case "Etc/UCT":
      country = "United Kingdom";
      break;
    case "Etc/Universal":
      country = "United Kingdom";
      break;
    case "Etc/UTC":
      country = "United Kingdom";
      break;
    case "Europe/Isle_of_Man":
      country = "United Kingdom";
      break;
    case "Europe/London":
      country = "United Kingdom";
      break;
    case "GB":
      country = "United Kingdom";
      break;
    case "GB-Eire":
      country = "United Kingdom";
      break;
    case "GMT":
      country = "United Kingdom";
      break;
    case "GMT+0":
      country = "United Kingdom";
      break;
    case "GMT-0":
      country = "United Kingdom";
      break;
    case "GMT0":
      country = "United Kingdom";
      break;
    case "Greenwich":
      country = "United Kingdom";
      break;
    case "HST":
      country = "United States";
      break;
    case "Iceland":
      country = "United Kingdom";
      break;
    case "MST":
      country = "United States";
      break;
    case "MST7MDT":
      country = "United States";
      break;
    case "Pacific/Auckland":
      country = "New Zealand";
      break;
    case "Pacific/Chatham":
      country = "New Zealand";
      break;
    case "Pacific/Honolulu":
      country = "United States";
      break;
    case "PST8PDT":
      country = "United States";
      break;
    case "Universal":
      country = "United Kingdom";
      break;
    case "UTC":
      country = "United Kingdom";
      break;
    case "WET":
      country = "United Kingdom";
      break;

    default:
      country = defaultCountry;
      break;
  }

  return lookups.find((f) => f.label.includes(country));
}
