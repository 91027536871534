/**
 * Solvable default setup for block-ui component
 * Props
 * - blocking = true or false (show/hide)
 * - lowerZIndex = true or false (set to true if you want block ui to respect fixed fix headers/navbars)
 * - color = set color
 * rcnet @solvable 2019
 */
import React from "react";
import PropTypes from "prop-types";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";

const BlockUiFx = ({
  blocking,
  lowerZIndex,
  children,
  color,
  containerCss,
  ...rest
}) => (
  <BlockUi
    tag="div"
    blocking={blocking}
    style={{
      zIndex: lowerZIndex ? 5 : 1010,
    }}
    className={containerCss}
    {...rest}
    loader={<Loader active type={"ball-beat"} color={color} />}
  >
    {children}
  </BlockUi>
);

BlockUiFx.propTypes = {
  color: PropTypes.string,
  containerCss: PropTypes.string,
  lowerZIndex: PropTypes.bool,
};

BlockUiFx.defaultProps = {
  color: "#189ad3", // #02a17c
  containerCss: "",
  lowerZIndex: true,
};

export default BlockUiFx;
