//
// useScrollNavigate hook
//
// Note: FeatureDetails and ProductFeatures components were using this hook fx
// - observePageReload - Set to TRUE will observe the URL on the every page reload. If there's a hash link in the URL it will
//                       try to react with it by scrolling to the target section.
// 21.01.2022 rcnet@solvable
//
/*
Sample route in the landing page (Home). /feature1 path should also point to Home page to be able for the hooks to find the element and scroll to section.
{
  path: "/",
  name: "Home",
  component: HomeV2,
  privateRoute: false,
},
{
  path: "/feature1",
  name: "Home",
  component: HomeV2,
  privateRoute: false,
},
*/
import { useEffect } from "react";

function useScrollNavigate(observePageReload = false, elementOffset = 0) {
  useEffect(() => {
    // Observe Refresh or Page reload. If valid path detected and the section is available on that page then scroll to section.
    if (observePageReload) {
      // Wrap execution to setTimeout to avoid collision with ScrollToTop component.
      setTimeout(() => {
        // let url = window.location.href.split("/");
        // let target = url[url.length - 1].toLowerCase();
        let target = window.location.hash.replace("#", "");
        let element = document.getElementById(target);

        if (elementOffset === 0) {
          element &&
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        } else if (element) {
          let elementPosition = element.getBoundingClientRect().top;
          let offsetPosition =
            elementPosition + window.pageYOffset - elementOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Scroll to section without offset settings
   * @param {*} sectionId
   * @param {*} clickEvent
   * @param {*} alterHistoryState
   */
  const scrollToSection = (
    sectionId,
    clickEvent,
    alterHistoryState = false
  ) => {
    let section = document.getElementById(sectionId);
    clickEvent && clickEvent.preventDefault();
    section && section.scrollIntoView({ behavior: "smooth", block: "start" });
    alterHistoryState &&
      section &&
      window.history.pushState(sectionId, sectionId, `/${sectionId}`);
  };

  /**
   * Will scroll to section with offset computation. This will help if you have a Sticky NavBar that overlaps with your scrolled section.
   * @param {*} sectionId
   * @param {*} elementOffset
   * @param {*} clickEvent
   * @param {*} alterHistoryState
   * @returns
   */
  function scrollToSectionOffset(
    sectionId,
    elementOffset = 70,
    clickEvent,
    alterHistoryState = false
  ) {
    if (!sectionId.includes("#")) return;

    let finalSectionId = sectionId.split("#")[1];

    let section = document.getElementById(finalSectionId);
    clickEvent && clickEvent.preventDefault();

    if (section == null) return;

    let elementPosition = section.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - elementOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    alterHistoryState &&
      section &&
      window.history.pushState(sectionId, sectionId, `${sectionId}`);
  }

  /**
   * Will scroll to section with offset computation. This will help if you have a Sticky NavBar that overlaps with your scrolled section.
   * Perfect match for react-router hash link
   * @param {*} el
   * @param {*} clickEvent
   * @returns
   */
  function scrollToSectionOffsetByElement(el, clickEvent) {
    clickEvent && clickEvent.preventDefault();

    if (el == null) return;

    let elementPosition = el.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - elementOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  return {
    scrollToSection,
    scrollToSectionOffset,
    scrollToSectionOffsetByElement,
  };
}

export default useScrollNavigate;
