/**
 * ErrorHandler (Implementation of React Error Boundaries)
 * - It only catches runtime errors due to rendering not event handlers, api errors
 * 
 * Note: For API/Async call error just use the dedicated Redux error reducer.
 */
 import React, { Component } from 'react';
 import { Card, CardBody } from 'reactstrap';
 import PropTypes from 'prop-types';
 
 class ErrorBoundary extends Component {
     state = {
         errorOccured: false,
         error: null,
         errorInfo: null
     };
 
     componentDidCatch(error, info) {
         this.setState({
             errorOccured: true,
             error: error,
             errorInfo: info
         });
 
         // Todo: add error logger here
         console.error(info);
         console.error(error);
     };
 
 
     render() {
         const hasError = this.state.errorOccured;
 
         if (hasError) {
             return (
                 <div className="m-4">
                     <Card>
                         <CardBody>
                             <h1><i className="fas fa-exclamation-circle"></i> {this.props.errorMessage}</h1>
                         </CardBody>
                         <CardBody className="border-top">
                             <p className="text-danger">The error: {this.state.error.toString()}</p>
                             <p>Where it occured: {this.state.errorInfo.componentStack}</p>
                         </CardBody>
                     </Card>
                 </div>
             );
         }
         return this.props.children;
     };
 };
 
 ErrorBoundary.propTypes = {
     errorMessage: PropTypes.string
 };
 
 ErrorBoundary.defaultProps = {
     errorMessage: 'Oops, something went wrong :('
 };
 
 export default ErrorBoundary;