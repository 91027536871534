import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/signup";

  const trackSelectedPlan = (payload) =>
    httpClient.post(`${apiRootUrl}/track/selected-plan`, payload);
    
  return {
    trackSelectedPlan,
  };
};

export default ApiService;
