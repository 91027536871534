import { ActionTypes } from "./action-types";
import {
  addNotification,
  addErrorNotification,
  showSystemWideMessage,
  hideSystemWideMessage,
} from "redux/system/system-action-creators";
import { getConnectionStatus } from "./helpers";
import { MessageType } from "components/common/system-message/message-type";
import Config from "config";

// ============================
//  Add subscriber
// ============================
export const addSubscriber =
  (name, subscriptionFn) => (dispatch, getState, signalRManager) => {
    try {
      signalRManager.subscribe(name, subscriptionFn);
    } catch (err) {
      dispatch(addErrorNotification(err));
    }
  };

// ============================
//  Start connection
// ============================
export const startConnection =
  (config) => async (dispatch, getState, signalRManager) => {
    try {
      let state = _getRealTimeCommsState(getState);

      // Wire events
      if (!state.signalRInitialised) {
        _configureSignalR(signalRManager, dispatch, config);
        dispatch(initSignalR(true));
      }

      await signalRManager.startAsync();

      dispatch(setStatus(getConnectionStatus(signalRManager)));
    } catch (err) {
      dispatch(setStatus(getConnectionStatus(signalRManager)));
      dispatch(addErrorNotification(err));
    }
  };

const initSignalR = (data) => ({
  type: ActionTypes.SYSTEM_REAL_TIME_COMMS_INIT_SIGNALR,
  payload: data,
});

// ============================
//  Invoke
// ============================
export const invoke =
  (methodName, payload) => async (dispatch, getState, signalRManager) => {
    try {
      await signalRManager.invokeAsync(methodName, payload);
    } catch (err) {
      dispatch(addErrorNotification(err));
    }
  };

// ===========================================
//  Get instance (Not recommended to use)
//  Just use action creators when Invoking
//  a signalR method
// ============================================
export const getConnectionInstance =
  (data) => (dispatch, getState, signalRManager) =>
    new Promise(async (resolve, reject) => {
      try {
        resolve(signalRManager);
      } catch (err) {
        dispatch(addErrorNotification(err));
      }
    });

// ============================
//  Set Real-time comms status
// ============================
export const setStatus = (data) => (dispatch) => {
  try {
    dispatch(setStatusSuccess(data));
  } catch (err) {
    dispatch(addErrorNotification(err));
  }
};

const setStatusSuccess = (data) => ({
  type: ActionTypes.SYSTEM_REAL_TIME_COMMS_SET_STATUS_SUCCESS,
  payload: { ...data },
});

// ===================================
// App Login/Logout call this method
// ===================================
export const reinitialiseSignalR =
  () => async (dispatch, getState, signalRManager) => {
    try {
      await signalRManager.reinitialiseConnectionAsync();
    } catch (err) {
      dispatch(addErrorNotification(err, "Error logging out SignalR."));
    }
  };

// ========================
// Test SignalR
// ========================
export const pingPong =
  (data) => async (dispatch, getState, signalRManager) => {
    try {
      await signalRManager.invokeAsync("Ping", data);
    } catch (err) {
      dispatch(addErrorNotification(err, "Error invoking hub method."));
    }
  };

// ========================
// Helpers
// ========================
const _getRealTimeCommsState = (stateFn) => stateFn().realTimeComms;

/** SignalR configurations */
const _configureSignalR = (signalRManager, dispatch, config) => {
  signalRManager
    .useLogger(config.enableLogging)
    .onReconnecting((error) => {
      dispatch(
        showSystemWideMessage(
          Config.modules.signalR.connectionLostMessage,
          MessageType.MESSAGE_WITH_PROGRESS,
          "fa-bolt"
        )
      );
      dispatch(setStatus(getConnectionStatus(signalRManager)));
    })
    .onReconnected((newConnectionId) => {
      dispatch(hideSystemWideMessage());
      dispatch(setStatus(getConnectionStatus(signalRManager)));
    })
    .onClose((error) => {
      dispatch(
        showSystemWideMessage(
          Config.modules.signalR.refreshUIMessage,
          Config.modules.signalR.allowAutoRefresh
            ? MessageType.AUTO_REFRESH
            : MessageType.REFRESH_LINK,
          "fa-bolt"
        )
      );
      dispatch(setStatus(getConnectionStatus(signalRManager)));
    });
};
