const eLearningLearnMoreData = [
  {
    id: "feature-elearning",
    heading:
      "Interactive and engaging <span class='highlight-text'>eLearning on Any Device</span>",
    subHeading:
      "Empower your employees with the knowledge and skills they need to succeed by offering engaging and accessible eLearning. Enhance your team's training experience by creating personalised training modules that can be accessed from any device using our simple editor.",
    bulletPoints: [
      "Include text, images, downloadable resources and even videos",
      "Create questionnaires with scoring to assess understanding",
      "Customised company inductions tailored to your brand",
      "Manage external client inductions",
      "Issue a Certificate of Completion",
    ],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/my-elearning.webp",
    imageAlt: "Engaging and simple eLearning on any device",
    marginTop: "0",
  },
  {
    id: "elearning-made-simple",
    heading: "<span class='highlight-text'>eLearning Made Simple</span>",
    subHeadings: [
      "Build eLearning modules and push to individuals or all employees. Track the completion status with a simple whole of business view.",
      "Explore the option of including your own content and selected supporting images to provide a clear message to your teams.",
      "You can even create modules for Safety Alerts or Toolbox Meetings in a few simple steps.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/elearning-content.webp",
    imageAlt: "eLearning Made Simple",
  },
  {
    id: "your-own-engaging-content",
    heading: "<span class='highlight-text'>Your Own Engaging Content</span>",
    subHeadings: [
      "Add your own training videos to eLearning modules, thereby creating an engaging experience with an impactful message.",
      "Modules can be viewed and completed on any device, anywhere. Ease of use and accessibility are always key priorities.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/elearning-video.webp",
    imageAlt: "Your Own Engaging Content",
  },
  {
    id: "ensure-competency",
    heading: "<span class='highlight-text'>Ensure Competency</span>",
    subHeadings: [
      "Add quizzes or questionnaires around your company’s specialisation to provide verification of competency and understanding.",
      "All quizzes have the option of scored or non-scored questions to facilitate ease of review. The choice of approach is yours.",
      "Add a Verification of Competency component and allocate a suitably qualified person to review and verify competency. This includes automated email notifications.",
      "Issue a Certificate of Completion automatically if required.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/elearning-quiz.webp",
    imageAlt: "Ensure Competency",
  },
  {
    id: "company-inductions",
    heading: "<span class='highlight-text'>Company Inductions</span>",
    subHeadings: [
      "Have applicants complete the necessary company induction online with ease. Include text, images, and even videos.",
      "You can even push out re-inductions to your existing team and schedule automated yearly re-inductions.",
      "Staffd is designed to offer comprehensive services.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/company-induction.webp",
    imageAlt: "Company Inductions",
  },
  {
    id: "client-inductions",
    heading: "<span class='highlight-text'>Client Inductions</span>",
    subHeadings: [
      "Have your team complete your clients’ online inductions and upload certifications or tickets.",
      "These can be auto allocated to groups of employees in one simple step. You can even auto push these skills to our integration partners.",
      "Staffd making inductions easy!",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/client-induction.webp",
    imageAlt: "Client Inductions",
  },
];

export default eLearningLearnMoreData;
