export const attributes = [
  {
    name: "data",
    type: "EmployeeSkillData object",
    description: "The Employee's skill payload object.",
    linkToTag: "#create-employee-skill-data-object",
  },
  {
    name: "signature",
    type: "string",
    description: "A secure salted hash of the data object.",
  },
];

export const payload = {
  data: {
    externalUserId: "1234",
    externalSkillId: "68",
    skill: {
      uploadName: "Drivers Licence",
      uploadUrls: [
        "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-e04d9033-ec6b-43ea-8baa-3162b6cbe2ca.jpg",
        "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-e08e6cc2-de38-4a06-899f-b0569051bc0b.jpg",
      ],
      uploadNumber: null,
      uploadExpiry: null,
      uploadIssue: null,
      isDriversLicence: true,
      driversLicenceNumber: "4356345",
      driversLicenceType: 3,
      driversLicenceExpiry: "2025-05-28T00:00:00",
      driversLicenceAutoOnly: false,
    },
  },
  signature: "059392598ce733fd657c04bed8467e94",
};

export const responseAttributes = [
  {
    name: "id",
    type: "string (optional)",
    description:
      "An optional forgein key/external system ID number of the newly created Employee Skill. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "message",
    type: "string (optional)",
    description:
      "An optional message that can be saved as an Employee comment in Staffd. When a validation or system error occurs, it is recommended to include a human readable message for the admin to understand the reason why the sync failed.",
  },
];

export const successResponse = {
  id: null,
  message: null,
};

export const failureResponse = {
  id: null,
  message: "The employee was not found.",
};
