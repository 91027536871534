import React from "react";
import PropTypes from "prop-types";
import ContentSection from "components/common/content-section";
import "./arc.scss";

function Arc({
  children,
  containerCss,
  color,
  bgColor,
  contentPadding,
  ...rest
}) {
  return (
    <div className={`bridge-curve-content-fx ${containerCss}`}>
      <section className="top-curve">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="svg-art"
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
          style={{ backgroundColor: bgColor }}
        >
          <path
            stroke="null"
            d="m0,28.2544q50,-46.26665 100,0l0,69.39998l-100,0l0,-69.39998z"
            fill={color}
            id="svg_1"
          />
        </svg>
      </section>
      <ContentSection
        {...rest}
        specificClass="content-container"
        containerStyle={{ backgroundColor: color }}
        padding={contentPadding}
      >
        {children}
      </ContentSection>
      <section className="bottom-curve">
        <svg
          className="svg-art-bottom"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          style={{ backgroundColor: color }}
        >
          <path d="M0,100 Q50,50 100,100 L0,100 Z" fill={"#fff"}></path>
        </svg>
      </section>
    </div>
  );
}

Arc.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerCss: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  contentPadding: PropTypes.string, // Bootstrap padding, valid values:  "0, 1, 2, 3, 4, 5"
};

Arc.defaultProps = {
  data: {},
  containerCss: "",
  color: "#f7f8f9",
  bgColor: "#fff",
  contentPadding: "0",
};

export default Arc;
