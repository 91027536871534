import * as PublicContent from "../constants/public-content";

const ContentService = () => {
  const getContentPage = (pageName) => {
    let page = PublicContent.publicPages.filter(
      (page) => page.pageName === pageName
    );

    if (page.length === 0)
      page = PublicContent.publicPages.filter(
        (page) => page.pageName === "not-found"
      );

    return page[0];
  };

  return { getContentPage };
};

export default ContentService;
