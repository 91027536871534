import React from "react";
import { Redirect } from "react-router-dom";
import getRedirectionPath from "core/utilities/location-service";
import Auth from "core/auth/auth";
import BgContent from "components/features/bg-content/bg-content";
import { ContentFx } from "components/common/content-fx";
import Bs5FormInput from "components/common/form-fields/bs5-input/bs5-form-input";
import ButtonFx from "components/common/button-fx";
import "./forgot-password.scss";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const ForgotPassword = ({
  loading,
  sent,
  location,
  handleSubmit,
  errorMessage,
}) => {
  let history = useHistory();
  let redirectPath = getRedirectionPath(location);
  if (Auth.isAuthenticated()) return <Redirect to={redirectPath} />;

  return (
    <BgContent>
      <section className={`forgot-password-wrapper`}>
        <ContentFx
          fxType="plain"
          options={{ bgColor: "transparent" }}
          contentPadding="6"
          overrideContentSectionCss={true}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="form-container bg-">
              <div className="form-overlap-container"></div>
              <div className="form-inner-container">
                <h3 className="display-1 mb-4">Forgot Password</h3>
                <section
                  className={`d-flex flex-column justify-content-center`}
                >
                  {sent && (
                    <>
                      <p>
                        A password reset email will be sent to you if a matching
                        account was found.
                      </p>
                      <ButtonFx
                        className="sw-signup-btn-rounded-primary mt-3"
                        buttonSize={"md"}
                        isLoading={loading}
                        onClick={() => history.push("/login/")}
                      >
                        LOGIN
                      </ButtonFx>
                    </>
                  )}

                  {!sent && (
                    <>
                      <Bs5FormInput
                        size="xl"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        textboxCss="text-box"
                        disabled={loading}
                      />

                      <ButtonFx
                        type="submit"
                        className="sw-signup-btn-rounded-primary mt-4"
                        buttonSize={"md"}
                        isLoading={loading}
                        onClick={handleSubmit}
                      >
                        RESET PASSWORD
                      </ButtonFx>

                      <ButtonFx
                        type="submit"
                        className="sw-signup-btn-rounded-grey mt-3"
                        buttonSize={"md"}
                        onClick={() => history.push("/login/")}
                        disabled={loading}
                      >
                        LOGIN
                      </ButtonFx>

                      {errorMessage && (
                        <div className="error-message mt-3">{errorMessage}</div>
                      )}
                    </>
                  )}
                </section>
              </div>
            </div>
          </div>
        </ContentFx>
      </section>
    </BgContent>
  );
};

ForgotPassword.propTypes = {
  loading: PropTypes.bool,
  sent: PropTypes.bool,
  location: PropTypes.object,
};

export default ForgotPassword;
