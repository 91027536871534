import { SectionHeading } from "components/features/section-heading";
import React from "react";

export const corePublicPages = [
  {
    pageName: "privacy-policy",
    title: "Privacy Policy | Staffd",
    description:
      "By accessing or using Staffd or using any application that integrates with or makes use of Staffd, you agree to be bound by terms of this Privacy Policy.",
    content: (
      <>
        <SectionHeading
          heading="Privacy Policy"
          subHeading="(Last Updated: 02.03.2022)"
        >
          <div className="mt-4">
            <h3>Acceptance of Terms</h3>
            <ul className="dot-list">
              <li>
                staffd.me (&ldquo;Website&rdquo;) referred to as
                &ldquo;Staffd&rdquo; is owned and operated by Staffd Pty Ltd ACN
                655 566 591 (&ldquo;Owner&rdquo;), and will be referred to as
                &ldquo;We&rdquo;, &ldquo;our&rdquo; and &ldquo;us&rdquo; in this
                Privacy Policy.
              </li>
              <li>
                By accessing or using Staffd or using any application that
                integrates with or makes use of Staffd, you agree to be bound by
                terms of this Privacy Policy.
              </li>
              <li>
                The Privacy Policy relates to the collection and use of personal
                information you may supply to us through your use of Staffd.
              </li>
              <li>
                We reserve the right, at our discretion, to modify or remove
                portions of this Privacy Policy at any time. This Privacy Policy
                is in addition to any other terms and conditions applicable to
                Staffd. We do not make any representations about third party web
                sites that may be linked to Staffd.
              </li>
              <li>
                We recognise the importance of protecting the privacy of
                information collected about visitors to our web site, in
                particular information that is capable of identifying an
                individual (&ldquo;Personal Information&rdquo;). This Privacy
                Policy governs the manner in which your personal information,
                obtained through the web site, will be dealt with. This Privacy
                Policy should be reviewed periodically so that you are updated
                on any changes. We welcome your comments and feedback.
              </li>
            </ul>
            <h3>Personal Information</h3>
            <ul className="dot-list">
              <li>
                Personal Information about visitors to Staffd is collected only
                when knowingly and voluntarily submitted. For example, we may
                need to collect such information to provide you with further
                services or to answer or forward any requests or enquiries.
              </li>
              <li>
                It is our intention that this Privacy Policy will protect your
                Personal Information from being dealt with in any way that is
                inconsistent with applicable privacy laws in Australia.
              </li>
            </ul>
            <h3>Use of Information</h3>
            <ul className="dot-list">
              <li>
                Personal Information that visitors submit to Staffd is used only
                for the purpose for which it is submitted or for such other
                secondary purposes that are related to the primary purpose,
                unless we disclose other uses in this Privacy Policy or at the
                time of collection.
              </li>
              <li>
                Copies of correspondence sent from Staffd, that may contain
                Personal Information, are stored as archives for record-keeping
                and back-up purposes only.
              </li>
              <li>
                We may freely share any information collected with our related
                bodies corporate (as defined in the Corporations Act 2001
                (Cth)).
              </li>
              <li>
                Unless otherwise agreed in a contract with you, we may appoint
                third party service providers to assist us in providing services
                to you. We may disclose your information to these providers as
                necessary to provide services to you. These service providers
                may be located overseas in jurisdictions.
              </li>
            </ul>
            <h3>Collection of Personal Information of all users</h3>
            <ul className="dot-list">
              <li>
                Whenever users visit the Website, our servers automatically
                record information for statistical purposes about your usage of
                the Website such as;
                <ul>
                  <li>the type of browser used</li>
                  <li>the referring URL</li>
                  <li>the IP address</li>
                  <li>the number and type of pages viewed</li>
                  <li>the date and time of visits; and</li>
                  <li>the exit URL.</li>
                </ul>
                <li>
                  This information we collect about you is not Personal
                  Information and, in any event, remains anonymous. We do not
                  link the anonymous information to any other Personal
                  Information unless you have either registered with Staffd or
                  logged in at the time of use. Accordingly, if you have not
                  identified yourself on the Website, such as by registering or
                  logging in, we will not link a record of your usage of our
                  Website to you personally.
                </li>
              </li>
            </ul>
            <h3>Collection of Personal Information of Registered Users</h3>
            <ul className="dot-list">
              <li>
                Upon your registration with Staffd we collect information about
                you in order to provide you with access to a Platform. We
                collect non-identifying information as well as Personal
                Information from you directly when you first register and also
                from time to time thereafter if you provide us with additional
                information.
              </li>
              <li>
                Personal Information which may be collected by Staffd includes,
                without limitation;
              </li>
              <ul>
                <li>your full name</li>
                <li>your email address</li>
                <li>your birth date</li>
                <li>your country of residence</li>
              </ul>
            </ul>
            <h3>Information Collection Practices</h3>
            <ul className="dot-list">
              <li>
                We may use personal information you provide, such as contact
                details, for;
              </li>
              <ul>
                <li>providing access to Staffd and other Staffd services</li>
                <li>promoting and improving our products and services</li>
                <li>
                  promoting and advertising third party affiliate services to
                  you
                </li>
                <li>billing and account management; and</li>
              </ul>
              <li>
                We do not sell any personal information to any third party.{" "}
              </li>
              <li>
                Where you link to a third party site from Staffd, we may provide
                session information to the third party, and may receive
                commissions from affiliates if you proceed to purchase products
                or services from that third party.
              </li>
              <li>
                We may use and disclose your personal information to avoid an
                imminent threat to a person's life or to public safety, or for
                reasons related to law enforcement or internal investigations
                into unlawful activities.
              </li>
              <li>
                We may use and disclose your personal information to identify,
                contact or bring legal action against any entity we suspect to
                be violating our terms of use, privacy or any other policy, or
                who is causing injury or interference with our rights, property,
                customers, or any third party who could reasonably be harmed by
                their actions.
              </li>
            </ul>
            <h3>Credit Card Details</h3>
            <ul className="dot-list">
              <li>
                Credit Card details are securely stored with our payment gateway
                provider for the processing of payment and will be deleted upon
                cancellation of your subscription.
              </li>
            </ul>
            <h3>Disclosure</h3>
            <ul className="dot-list">
              <li>
                Apart from where you have consented or disclosure is necessary
                to achieve the purpose for which it was submitted, personal
                information may be disclosed in special situations where we have
                reason to believe that doing so is necessary to identify,
                contact or bring legal action against anyone damaging, injuring,
                or interfering (intentionally or unintentionally) with our
                rights or property, users, or anyone else who could be harmed by
                such activities. Also, we may disclose personal information when
                we believe in good faith that the law requires disclosure.
              </li>
              <li>
                We may engage third parties to provide you with goods or
                services on our behalf. In that circumstance, we may disclose
                your personal information to those third parties in order to
                meet your request for goods or services.
              </li>
              <li>
                For the avoidance of any doubt, we will not disclose any
                Personal Information about you without your consent unless we
                believe it is necessary;
              </li>
              <ul>
                <li>to comply with the law</li>
                <li>to provide you with a service which you have requested</li>
                <li>to implement our terms of service</li>
                <li>
                  to protect our rights or property, or the rights or property
                  of any of our other users or any member of the public; or
                </li>
                <li>
                  to lessen a serious threat to a person's health or safety.
                </li>
              </ul>
            </ul>
            <h3>Security</h3>
            <ul className="dot-list">
              <li>
                We strive to ensure the security, integrity and privacy of
                personal information submitted to our sites, and we review and
                update our security measures in light of current technologies.
              </li>
              <li>
                Unfortunately, no data transmission over the Internet can be
                guaranteed to be totally secure. However, we will endeavour to
                take all reasonable steps to protect the personal information
                you may transmit to us or from our online products and services.
                Once we do receive your transmission, we will also make our best
                efforts to ensure its security on our systems.
              </li>
              <li>
                In addition, our employees and the contractors who provide
                services related to our information systems are obliged to
                respect the confidentiality of any personal information held by
                us. However, we will not be held responsible for events arising
                from unauthorised access to your personal information.
              </li>
              <li>
                You can also play an important role in keeping your Personal
                Information secure by maintaining the confidentiality of any
                password, PINs and accounts used in Staffd. Please notify us
                immediately if there is any unauthorised use of your account by
                any other person or any other breach of security.
              </li>
            </ul>
            <h3>Collecting Information from Users</h3>
            <ul className="dot-list">
              <li>
                IP Addresses: Our web servers gather your IP address to assist
                with the diagnosis of problems or support issues with our
                services. Again, information is gathered in aggregate only and
                cannot be traced to an individual user.
              </li>
              <li>Cookies and Applets;</li>
              <ul>
                <li>
                  Cookies are pieces of information that a website transfers to
                  your computer's hard disk for record-keeping purposes. Most
                  web browsers are set to accept cookies. If you do not wish to
                  receive any cookies, you may set your browser to refuse
                  cookies. In some instances, this may mean you will not be able
                  to take full advantage of parts of the Website.
                </li>
                <li>
                  We use cookies to provide you with a better experience. These
                  cookies allow us to increase your security by storing your
                  session ID and are a way of monitoring single user access.
                </li>
              </ul>
              <li>
                This aggregate, non-personal information is collated and
                provided to us to assist in analysing the usage of the site.
              </li>
            </ul>
            <h3>Access to Information</h3>
            <ul className="dot-list">
              <li>
                We will endeavour to take all reasonable steps to keep secure
                any information which we hold about you, and to keep this
                information accurate and up to date. If, at any time, you
                discover that information held about you is incorrect, you may
                contact us to have the information corrected.
              </li>
              <li>
                In addition, our employees and the contractors who provide
                services related to our information systems are obliged to
                respect the confidentiality of any personal information held by
                us.
              </li>
            </ul>
            <h3>Links to other sites</h3>
            <ul className="dot-list">
              <li>
                We may provide links to web sites outside of Staffd, as well as
                to third party websites. These linked sites are not under our
                control, and we cannot accept responsibility for the conduct of
                companies linked to our Website.
              </li>
              <li>
                Before disclosing your personal information on any other
                website, we advise you to examine the terms and conditions of
                using that web site and its privacy statement.
              </li>
            </ul>
            <h3>Trans-Border Data Exchange</h3>
            <ul className="dot-list">
              <li>
                We may transfer (and you are deemed to consent to us
                transferring) your Personal Information outside of Australia;
              </li>
              <ul>
                <li>
                  where we reasonably believe that the person who will receive
                  your Personal Information is subject to effective data
                  protection obligations
                </li>
                <li>
                  where the transfer is necessary for the performance of any
                  contract between you and us or for the implementation of
                  pre-contractual measures
                </li>
                <li>
                  where the transfer is for your benefit and it is impractical
                  for us to obtain your consent, but if it were practical you
                  would be likely to consent; and
                </li>
                <li>
                  as required or authorised by or under law, and where you
                  subsequently consent from time to time.
                </li>
              </ul>
            </ul>
            <h3>&ldquo;Spam&rdquo; (Promotional Email)</h3>
            <ul className="dot-list">
              <li>
                We will not send you &ldquo;spam&rdquo; (advertising
                information) without first asking you whether you wish to have
                promotional information sent to you.
              </li>
              <li>
                If you are receiving promotional information from us and do not
                wish to receive this information any longer, you may remove your
                name from our list by contacting us and asking to be removed
                from our mailing list. Please allow five days for this request
                to be processed.
              </li>
            </ul>
            <h3>Problems or questions</h3>
            <ul className="dot-list">
              <li>
                If we become aware of any ongoing concerns or problems with
                Staffd, we will take these issues seriously and work to address
                these concerns. If you have any further queries relating to our
                Privacy Policy, or you have a problem or complaint, please
                contact us.{" "}
              </li>
              <li>
                For more information about privacy issues in Australia and
                protecting your privacy, visit the Australian Federal Privacy
                Commissioner's website:{" "}
                <a
                  href="http://www.privacy.gov.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.privacy.gov.au
                </a>
              </li>
            </ul>
          </div>
        </SectionHeading>
      </>
    ),
  },
  {
    pageName: "staffd-licence-agreement",
    title: "Licence Agreement & Terms of Service | Staffd",
    description: "",
    content: (
      <>
        <SectionHeading
          heading="Staffd Licence Agreement &amp; Terms of Service"
          subHeading="Last Updated: March 24, 2022"
        >
          <div className="mt-4">
            <p>
              <strong>Welcome to Staffd!</strong> Staffd is a Web Based
              Applicant Management, Employee onboarding and eLearning system
              designed to streamline your hiring processes and add automation.
            </p>
            <p>
              <strong>Staffd</strong> is provided and owned by{" "}
              <strong>Staffd Pty Ltd</strong> (Product Owner) and{" "}
              <strong>Staffd Australia Pty Ltd</strong> (The Licensee of Staffd)
              collectively known as <strong>“Staffd”</strong>.
            </p>
            <p>
              The following terms and conditions, along with Staffd Privacy
              Policy (collectively, the “Terms of Service”), govern your access
              to and use of Staffd and all Site Content and constitute a binding
              legal agreement between you and Staffd. Please read these Terms of
              Service carefully as they provide important information to you.
            </p>
            <ol>
              <li className="font-weight-bold">Terms of Use.</li>
              <ol type="a">
                <li>
                  Except as otherwise permitted under this agreement, the
                  Customer must, ensure that its Users do not: copy, reproduce,
                  lease, sub-licence, rent, licence, sell or otherwise make
                  available or transfer all or any part of Staffd to any other
                  person; make available to any third party any analysis of the
                  results of operation of Staffd, without the prior written
                  consent of Staffd; grant any security interest over Staffd;
                  attempt to disassemble, decompile or otherwise reverse
                  engineer Staffd, alter, customise, modify or create derivative
                  works of Staffd; remove, obliterate or alter any proprietary
                  notice on Staffd; use Staffd to access any data other than the
                  Customer Material; violate or attempt to violate the security
                  of Staffd; disrupt or interfere with Staffd, or any services,
                  system resources, accounts, servers or networks connected to
                  or accessible through Staffd; disrupt or interfere with any
                  other user’s enjoyment of Staffd; take any action that imposes
                  an unreasonable or disproportionately large load on the
                  infrastructure of Staffd; use any device, software or routine
                  to interfere or attempt to interfere with the proper working
                  of Staffd or any transaction or process being conducted on or
                  through it; use Staffd to breach any laws, including any
                  Privacy Laws, which are applicable to the Customer.
                </li>
              </ol>
              <li className="font-weight-bold">
                Intellectual Property Rights.
              </li>
              <ol type="a">
                <li>
                  The Customer acknowledges that Staffd Pty Ltd owns all
                  Intellectual Property Rights in and to Staffd.
                </li>
                <li>
                  Subject to the payment of all outstanding Fees by the
                  Customer, Staffd grants to the Customer a non-exclusive,
                  personal, non-transferable, non-sublicensable, revocable and
                  royalty free licence to use Staffd solely for the Subscription
                  Term to the limited extent necessary for the Customer to enjoy
                  the benefit of Staffd for the Customer’s internal business
                  purposes.
                </li>
              </ol>
              <li className="font-weight-bold">Confidentiality.</li>
              <ol type="a">
                <li>
                  Each party agrees to keep confidential, and not to use or
                  disclose, other than as permitted by this agreement, any
                  Confidential Information of the other party.
                </li>
              </ol>
              <li className="font-weight-bold">Licence Subscription.</li>
              <ol type="a">
                <li>
                  Free trial period. Your free trial period commences at the
                  time of sign-up and remains in effect until the period has
                  expired. You have the option to cancel at any time prior to
                  the end of your trial period.
                </li>
                <li>
                  Your Staffd Licence Subscription will continue until
                  terminated. To use Staffd you must have Internet access, and
                  provide us with one or more Payment Methods. “Payment Method”
                  means a current, valid, accepted method of payment, as may be
                  updated from time to time. Unless you cancel your subscription
                  before your billing date, you authorise us to charge the
                  subscription fee for the next billing period to your Payment
                  Method.
                </li>
                <li>
                  We may offer a number of subscription plans. Some subscription
                  plans may have differing conditions and limitations, which
                  will be disclosed at your sign-up.
                </li>
              </ol>
              <li className="font-weight-bold">Payment and Cancellation.</li>
              <ol type="a">
                <li>
                  Billing Cycle. Your billing cycle is selected during the
                  sign-up process.
                </li>
                <li>
                  Term. Your term will be based on the billing cycle selected
                  and will auto renew based on your previous selection for the
                  same period.
                </li>
                <li>
                  Subscription Fee. Your subscription fee is due and payable
                  based on the billing cycle selected on the monthly/yearly
                  anniversary of the initial signup date. The subscription fee
                  will be auto debited from the payment method provided. Failure
                  to make payment on the due date may be considered default and
                  may result in suspension or termination of your subscription.
                </li>
                <li>
                  Cancellation. You may cancel your subscription at any time,
                  and you will continue to have access to Staffd through the end
                  of your billing period. To the extent permitted by the
                  applicable law, payments are non-refundable and we do not
                  provide refunds or credits for any partial subscription
                  periods.
                </li>
              </ol>
              <li className="font-weight-bold">Limited Liabilities.</li>
              <ol type="a">
                <li>
                  Third Party Disputes. Staffd is not affiliated with any
                  third-party service provider or any integration partner, and
                  any dispute you have with any such third party, is directly
                  between you and such third party, and you irrevocably release
                  Staffd (and our directors, officers, subsidiaries, agents and
                  employees) from any and all claims, demands and damages
                  (actual and consequential) of every kind and nature, known and
                  unknown, arising out of or in any way connected with such
                  disputes.
                </li>
                <li>
                  Indemnity. You agree to defend, indemnify, and hold Staffd,
                  its officers, directors, employees and agents, harmless from
                  and against any claims, liabilities, damages, losses, and
                  expenses, including, without limitation, reasonable legal and
                  accounting fees, arising out of or in any way connected with
                  your access to or use of Staffd, or your violation of these
                  Terms of Service, including without limitation any breach of
                  any representations or warranties herein, any infringement by
                  you of Staffd intellectual property rights, and any claim by a
                  third. Staffd will defend, indemnify and hold you (your
                  directors, officers, employees and affiliates) harmless from
                  and against any and all costs, liabilities, losses and
                  expenses (including but not limited to reasonable attorneys’
                  fees), resulting from any claim, suit, action or proceeding
                  alleging that Staffd or any portion thereof infringes any
                  patent, copyright or other intellectual property, proprietary,
                  or other right of any third party.
                </li>
                <li>
                  Limitation of Liability. You acknowledge and agree that, to
                  the maximum extent permitted by law, the entire risk arising
                  out of your access to and use of Staffd remains with you.
                  Except for the indemnity obligations set forth herein, under
                  no circumstances, including, but not limited to, negligence,
                  system failure or network outage, will either Staffd or any
                  other person or entity involved in creating, producing, or
                  delivering Staffd be liable for any incidental, special,
                  exemplary or consequential damages, including lost profits,
                  loss of data or loss of goodwill, Staffd service interruption,
                  computer damage or system failure or from any communications,
                  interactions with other users of Staffd or other persons with
                  whom you communicate or interact as a result of your use of
                  Staffd.
                </li>
              </ol>
              <li className="font-weight-bold">Details and Information.</li>
              <ol type="a">
                <li>
                  You verify that any contact information provided to Staffd,
                  including, but not limited to, your Company name, ABN, e-mail
                  address, phone numbers and mailing address (if required) is
                  true and accurate. By providing us with your email address,
                  you agree to receive notices electronically, to that email
                  address. You further verify that you are the email account and
                  telephone subscriber and/or that you own any email account and
                  telephone numbers that you provide to Staffd. You acknowledge
                  that by voluntarily providing your telephone numbers and email
                  addresses to Staffd, you expressly agree to be contacted at
                  the telephone numbers or email addresses you provide relating
                  to this Agreement, any transaction with Staffd, matters
                  related to your account, updates and promotions from Staffd.
                  These communications may be made by or on behalf of Staffd,
                  even if your phone number is registered on any state or
                  federal Do Not Call list. Staffd will use your primary contact
                  details to send you operational communications concerning your
                  Account, updates concerning new and existing features in
                  Staffd, notifications about product updates and improvements,
                  company and industry news and events.
                </li>
              </ol>
              <li className="font-weight-bold">Modification.</li>
              <ol type="a">
                <li>
                  Staffd reserves the right, at its sole discretion, to modify
                  these Terms of Service, at any time and without prior notice.
                  If we modify these Terms of Service, we will post the
                  modification on the Site or provide you with notice of the
                  modification. We will also update the “Last Updated Date” at
                  the top of these Terms of Service or on the Site or
                  Application. By continuing to access or use Staffd after we
                  have posted a modification on the Site or Application or have
                  provided you with notice of a modification, you are indicating
                  that you agree to be bound by the modified Terms of Service.
                  If the modified Terms of Service are not acceptable to you,
                  you may contact us to discuss the related issue.
                </li>
              </ol>
              <li className="font-weight-bold">Hosting Services.</li>
              <ol type="a">
                <li>
                  Hosting is provided by Staffd using the Azure secure cloud
                  hosting platform. Staffd provides a managed hosting service
                  which includes platform hosting, data storage, email service,
                  security and server management.
                </li>
              </ol>
              <li className="font-weight-bold">Data Security.</li>
              <ol type="a">
                <li>
                  Staffd has been designed and maintained to ensure that best
                  practices for data security and system integrity are always at
                  the forefront of our development strategy, utilising a variety
                  of different up-to-date software frameworks, encryption
                  methods, and digitally signed web security signatures. Being
                  hosted within Microsoft Azure datacentres, we are able to
                  benefit from their state-of-the-art physical security,
                  security-hardened infrastructure and processes. All client
                  data is internally backed up in both the local datacentre in
                  Australia, and replicated via geo-redundant storage to another
                  region within Australia.{" "}
                </li>
              </ol>
              <li className="font-weight-bold">Entire agreement.</li>
              <ol type="a">
                <li>
                  This agreement supersedes all prior agreements, arrangements
                  and undertakings between the parties and constitutes the
                  entire agreement between the parties relating to Staffd.{" "}
                </li>
              </ol>
            </ol>
          </div>
        </SectionHeading>
      </>
    ),
  },
  {
    pageName: "not-found",
    title: "Page Not Found",
    description: "",
    content: (
      <>
        <SectionHeading
          heading="404 - Page Not Found"
          subHeading="We're sorry, the page you requested could not be found. Please go back to the homepage."
        ></SectionHeading>
      </>
    ),
  },
];
