import { ActionTypes } from "./action-types";

const initialState = {
  loading: false,
  sent: false,
  errorMessage: null
};

function ForgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        sent: false,
        errorMessage: null,
      };
    case ActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        sent: true,
        errorMessage: null
      };
    case ActionTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        sent: false,
        errorMessage: action.payload
      };

    case ActionTypes.FORGOT_PASSWORD_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}

export default ForgotPasswordReducer;
