import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/tenant-setup";

  const generateCode = (payload) =>
    httpClient.post(`${apiRootUrl}/generate-code?companyName=${payload}`);

  const validateCode = (payload) =>
    httpClient.post(`${apiRootUrl}/validate-code?code=${payload}`);

  const processLogo = (payload) =>
    httpClient.post(`${apiRootUrl}/process-logo`, payload);

  const provisionTenant = (payload) =>
    httpClient.post(`${apiRootUrl}`, payload);

  const getInitialTenantInfo = (id) =>
    httpClient.get(`${apiRootUrl}/initial-tenant-info/${id}`);

  return {
    generateCode,
    validateCode,
    processLogo,
    provisionTenant,
    getInitialTenantInfo,
  };
};

export default ApiService;
