import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./services/apiService";
import { AppUrls } from 'constants/app-urls';

const initialState = {
  loading: false,
  redirectTo: ""
};

// ---------------
// Action Thunks
// ---------------
export const createSubscription = createAsyncThunk(
  "accountCreditOption/createSubscription",
  async (data, thunkAPI) => {
    try {
      const { values, planTerms, planType, productCode } = data;

      let payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        cardNumber: values.cardNumber,
        expirationDate: values.expiryDate,
        securityCode: values.cvc,
        planType: planType,
        terms: planTerms,
        productCode: productCode,
        //idempotencyKey:
      };
      let result = await ApiService().createSubscription(payload);

      thunkAPI.dispatch(
        addNotification("Subscription has been successfully created.")
      );

      return result;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to create subscription.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const accountCreditOptionSlice = createSlice({
  name: "accountCreditOption",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createSubscription.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createSubscription.fulfilled, (state) => {
      state.loading = false;
      state.redirectTo = AppUrls.MANAGE_SETUP;
    });

    builder.addCase(createSubscription.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default accountCreditOptionSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.accountCreditOption;

export const selectAccountCreditOption = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
