import React from "react";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import "./multi-column.scss";
import ColumnItem from "./column-item";

function MultiColumn({ data, containerCss }) {
  return (
    <Row className="mt-5">
      {data.map((o, index) => (
        <ColumnItem
          key={index}
          companyName={o.companyName}
          imageUrl={o.imageUrl}
          bgColor={o.bgColor}
          tag={o.tag}
          link={o.link}
          contentLines={o.contentLines}
          containerCss={containerCss}
        />
      ))}
    </Row>
  );
}

MultiColumn.propTypes = {
  data: PropTypes.array,
  containerCss: PropTypes.string,
};

MultiColumn.defaultProps = { data: [] };

export default MultiColumn;
