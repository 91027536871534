import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./../../services/apiService";

const initialState = {
  loading: false,
  redirectTo: "",
};

// ---------------
// Action Thunks
// ---------------
export const createNewSubscription = createAsyncThunk(
  "manageSubscription/creditOption/createNewSubscription",
  async (data, thunkAPI) => {
    try {
      const { values, planTerms, planType, productCode, onPaymentCompleted } = data;

      let payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        cardNumber: values.cardNumber,
        expirationDate: values.expiryDate,
        securityCode: values.cvc,
        planType: planType,
        terms: planTerms,
        productCode: productCode,
      };
      let result = await ApiService().createNewSubscription(payload);

      thunkAPI.dispatch(
        addNotification("Subscription has been successfully created.")
      );
    
      //thunkAPI.dispatch(ManageSubscriptionPlanFormSlice.clearStates());

      onPaymentCompleted();

      return result;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to create subscription.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const manageSubscriptionCreditOptionSlice = createSlice({
  name: "manageSubscription/creditOption",
  initialState,
  reducers: {
    clearStates: (state) => {
      //state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewSubscription.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createNewSubscription.fulfilled, (state) => {
      state.loading = false;
      //state.redirectTo = AppUrls.MANAGE_SETUP;
    });

    builder.addCase(createNewSubscription.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { clearStates } = manageSubscriptionCreditOptionSlice.actions;

export default manageSubscriptionCreditOptionSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.manageSubscriptionCreditOption;

export const selectManageSubscriptionCreditOption = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
