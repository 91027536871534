import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();

  const submitContactDetails = (payload) =>
    httpClient.post(`/v1/contact-us/send`, payload);

  const bookADemo = (payload) =>
    httpClient.post(`/v1/book-a-demo/send`, payload);

  return {
    submitContactDetails,
    bookADemo,
  };
};

export default ApiService;
