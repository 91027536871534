import React, { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import "./page-scroll-to-top.scss";

const PageScrollToTop = ({ iconCss }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", scrollFn);

    return () => {
      document.removeEventListener("scroll", scrollFn);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollFn = (e) => {
    toggleVisibility();
  };

  const toggleVisibility = () => {
    setIsVisible(window.pageYOffset > 300);
  };

  const scrollToTop = () => {
    let currentPathName = window.location.pathname;
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.history.pushState(currentPathName, currentPathName, currentPathName);
  };

  return (
    <section className="page-scroll-to-top">
      {isVisible && (
        <div onClick={() => scrollToTop()}>
          <i className={`fas fa-chevron-up mr-1 ${iconCss}`}></i>
        </div>
      )}
    </section>
  );
};

PageScrollToTop.propTypes = {
  iconCss: PropTypes.string,
};

PageScrollToTop.defaultProps = {
  iconCss: "",
};

export default PageScrollToTop;
