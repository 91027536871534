const skillManagementLearnMoreData = [
  {
    id: "feature-skill-management",
    heading:
      "Save time and effort with <span class='highlight-text'>Automated Skill Management</span>",
    subHeading:
      "Efficiently manage and track your team's licences and skills with our automated system. Say goodbye to manual tracking and never miss an expiration date again with our reminders that keep you and your employees informed.",
    bulletPoints: [
      "Automated reminders for expiration dates",
      "Self-management for employees to update information",
      "Streamlined process saves time",
      "Ensures team compliance and readiness",
      "Integration with external systems.",
    ],
    imagePath: "https://staffd.azureedge.net/www/screenshots/v4/skills.webp",
    imageAlt: "Engaging and simple eLearning on any device",
    marginTop: "0",
  },
  {
    id: "self-managed-skills",
    heading: "<span class='highlight-text'>Employee Self Managed Skills</span>",
    subHeadings: [
      "At Staffd, we believe in empowering your employees to take control of their personal details and skills. Our platform provides full visibility for employees, allowing them to easily update and add new skills as required.",
      "And with automated reminders for expiring licences or tickets, you can rest assured that your team is always up to date.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/skills-update.webp",
    imageAlt: "Employee Self Managed Skills",
  },
  {
    id: "update-skills",
    heading: "<span class='highlight-text'>Upload New or Updated Skills</span>",
    subHeadings: [
      "We understand the importance of keeping track of your employees' licences and skills. That's why we have made the process of uploading new or updated skills as easy as possible. Our user-friendly platform provides a simple interface where employees can easily update their information, including details about their licences and skills.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/skills-upload.webp",
    imageAlt: "YUpload New or Updated Skills",
  },
  {
    id: "approve-skills",
    heading: "<span class='highlight-text'>Approve Updated Skills</span>",
    subHeadings: [
      "Accurate and up-to-date information is always important in business. That's why our platform provides an efficient way for employees to upload new or updated skills. However, to maintain data accuracy and compliance, the updates need to be approved by the relevant department or HR personnel.",
      "Our system allows for easy review and approval of employee change requests, ensuring that the correct information is being added. This way, you can be confident that the information you have on hand is accurate, up-to-date, and compliant with your company policies and legal requirements.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/skills-approve.webp",
    imageAlt: "Approve Updated Skills",
  },
];

export default skillManagementLearnMoreData;
