import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Auth from "core/auth/auth";
import Password from "components/common/password";
import getRedirectionPath from "core/utilities/location-service";
import BgContent from "components/features/bg-content/bg-content";
import { ContentFx } from "components/common/content-fx";
import ButtonFx from "components/common/button-fx";
import "./reset-password.scss";

const ResetPassword = ({
  loading,
  location,
  validateResetToken,
  isTokenValid,
  handleSubmit,
  errorMessage,
}) => {
  const search = useLocation().search;

  const resetToken = new URLSearchParams(search).get("t");

  useEffect(() => {
    if (resetToken) validateResetToken(resetToken);
  }, [resetToken]);

  let redirectPath = getRedirectionPath(location);

  if (Auth.isAuthenticated()) return <Redirect to={redirectPath} />;

  return (
    <BgContent>
      <section className={`reset-password-wrapper`}>
        <ContentFx
          fxType="plain"
          options={{ bgColor: "transparent" }}
          contentPadding="6"
          overrideContentSectionCss={true}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="form-container bg-">
              <div className="form-overlap-container"></div>
              <div className="form-inner-container">
                <h3 className="display-1 mb-4">Reset Password</h3>
                <section
                  className={`d-flex flex-column justify-content-center`}
                >
                  {isTokenValid && (
                    <>
                      <Password />

                      <ButtonFx
                        type="submit"
                        className="sw-signup-btn-rounded-primary mt-4"
                        buttonSize={"md"}
                        isLoading={loading}
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </ButtonFx>
                    </>
                  )}

                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </section>
              </div>
            </div>
          </div>
        </ContentFx>
      </section>
    </BgContent>
  );
};

export default ResetPassword;
