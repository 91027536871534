import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";

function ColumnItem({
  companyName,
  imageUrl,
  bgColor,
  tag,
  link,
  contentLines,
  containerCss,
}) {
  return (
    <Col id={tag} md={6} xl={4} className="multi-column-item-wrap">
      <div
        className={`item multi-column-item d-flex flex-column justify-content-top align-items-center p-3 ${containerCss}`}
      >
        {link && (
          <a href={link} target="_blank" without rel="noreferrer">
            <div
              className="rounded-avatar mb-4"
              style={{ backgroundColor: bgColor }}
            >
              <img src={imageUrl} alt={companyName} />
            </div>
            <h5 className="company-name">{companyName}</h5>
          </a>
        )}
        {!link && (
          <>
            <div
              className="rounded-avatar mb-4"
              style={{ backgroundColor: bgColor }}
            >
              <img src={imageUrl} alt={companyName} />
            </div>
            <h5 className="company-name">{companyName}</h5>
          </>
        )}

        {contentLines.map((line, i) => (
          <div
            className="integration-text"
            key={i}
            dangerouslySetInnerHTML={{ __html: line }}
          />
        ))}
      </div>
    </Col>
  );
}

ColumnItem.propTypes = {
  companyName: PropTypes.string,
  imageUrl: PropTypes.string,
  bgColor: PropTypes.string,
  containerCss: PropTypes.string,
  link: PropTypes.string,
  contentLines: PropTypes.array,
};

ColumnItem.defaultProps = { containerCss: "", contentLines: [] };

export default ColumnItem;
