import Auth from "../auth/auth";
import HttpClient from "core/http/httpClient";

const REQUEST_DELAY = 1000;

/**
 * Get access token from auth
 * @returns
 */
const getAccessToken = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(Auth.getToken());
    }, REQUEST_DELAY);
  });
};

const reAuthenticateAsync = async () => {
  let httpClient = HttpClient();

  await httpClient.get(`/v1/core/auth/signalr/ping`);
};

const isAuthenticated = ()=> {
  return Auth.isAuthenticated();
}

export { getAccessToken, reAuthenticateAsync, isAuthenticated };
