/**
 * Sign-in hooks
 */
import { useState } from "react";
import { useDispatch } from "react-redux";
import AuthTokenService from "services/auth-token-service";
import { setProfile } from 'redux/system/system-action-creators';

const useSigninAccount = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const signInAsync = (userName, password) =>
    new Promise(async (resolve, reject) => {
      setIsLoading(true);

      try {
        const result = await AuthTokenService().loginAsync(userName, password);
     
        // Fetch profile then load to redux
        dispatch(setProfile(result.profile));
        setErrorMessage("");

        resolve({
          loginResult: result,
          isSuccess: true,
        });
      } catch (err) {
        if (err.response?.status === 400) {
          resolve({
            isSuccess: false,
            errorMessage: err.response?.data["invalid_grant"][0],
          });

          setErrorMessage(err.response?.data["invalid_grant"][0]);
          return;
        }

        const errorMessage = err.response.data.Message

        resolve({
          isSuccess: false,
          errorMessage,
        });

        setErrorMessage(errorMessage);
      } finally {
        setIsLoading(false);
      }
    });

  return { signInAsync, errorMessage, isLoading };
};

export default useSigninAccount;
