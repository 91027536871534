import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { noop } from "lodash";
import * as SignupProgressSlice from "redux/signup-progress/signup-progress-slice";
import { AccountSetupStatusTypes } from "../constants/account-setup-status-types";
import { useLocation } from "react-router";
import { checkRedirectionRules } from "services/redirect-rules-service";
import { AppUrls } from "constants/app-urls";
import { selectProfileData } from "redux/system/user-profile-reducer";

function useSignupProgressState(
  redirectCustomRules = noop,
  handleRedirectionRules = true
) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [checkSignupProgressLoading, setCheckSignupProgressLoading] =
    useState(true);

  const signupProgressState = useSelector(
    SignupProgressSlice.selectSignupProgress
  );

  useEffect(() => {
    if (signupProgressState.isAdmin) {
      history.push(AppUrls.ADMIN);
      return;
    }

    if (!handleRedirectionRules) {
      setCheckSignupProgressLoading(false);
      return;
    }

    checkRedirectionPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupProgressState.status, signupProgressState.isAdmin]);

  const checkRedirectionPolicy = () => {
    if (!signupProgressState.loading && signupProgressState.status === null) {
      setCheckSignupProgressLoading(false);
      return;
    }

    if (
      signupProgressState.status === AccountSetupStatusTypes.ACCOUNT_CREATED
    ) {
      //setCheckSignupProgressLoading(false);
      if (
        [AppUrls.REGISTRATION, AppUrls.REGISTRATION_FORM].includes(
          location.pathname
        )
      ) {
        // Note: ORIGINAL CODE 
        //history.push(AppUrls.PLAN);
        history.push(AppUrls.FREE_TRIAL_OPTION);
        return;
      }
    }

    if (
      signupProgressState.status === AccountSetupStatusTypes.SIGNUP_COMPLETED
    ) {
      history.push(AppUrls.MANAGE_SETUP);
      setCheckSignupProgressLoading(false);
      return;
    }

    // Fallback redirection rules if requirements above not met
    let handled = redirectCustomRules();

    // Does the above URL rules satisfied
    !handled && setCheckSignupProgressLoading(false);
  };

  const handleFinishSignup = () => {
    var redirectTo = checkRedirectionRules(signupProgressState.status);
    history.push(redirectTo);
  };

  const checkAndUpdateSignupProgressStatus = (userName) =>
    dispatch(SignupProgressSlice.checkSignupProgressStatus(userName));

  return {
    checkSignupProgressLoading,
    handleFinishSignup,
    checkAndUpdateSignupProgressStatus,
  };
}

export default useSignupProgressState;
