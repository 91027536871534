export const integrationsData = [
  {
    companyName: "Traffio",
    imageUrl: "https://staffd.azureedge.net/www/integrations/traffio.webp",
    bgColor: "#302F33",
    tag: "traffio",
    link: "https://www.traffio.com.au/",
    contentLines: [
      "Traffio is a field workforce management and schedule platform that empowers businesses to digitalise and centralise their operations.",
      "Traffio’s real-time intelligent booking system makes resource management easy.  Traffio helps coordinate your bookings smarter and faster with less effort with dynamic customisations for staff availability, fatigue, and conflict management.",
      "Integrating with industry-leading accounting software, Traffio offers on-time invoicing and comprehensive payroll features to make bookkeeping fast and effective.",
      "Traffio is an independent, Australian owned and operated platform and has since expanded into the United Kingdom and New Zealand.  Working closely with customers, Traffio focuses extensively on operational efficiency to provide customers with software that allows them to grow.",
    ],
  },
  {
    companyName: "Employment Hero",
    imageUrl:
      "https://staffd.azureedge.net/www/integrations/EH-Logo-Black.webp",
    bgColor: "#fff",
    tag: "employmenthero",
    link: "https://www.keypay.com.au/?utm_source=staffd&utm_medium=directorylisting",
    contentLines: [
      "Employment Hero is an award winning cloud-based payroll and workforce management platform trusted by over 750 partners on the platform who service 200k businesses with Employment Hero. Processing over $26 billion in payment annually, Employment Hero is a fully automated payroll solution built for local payroll compliance. Employment Hero is available in Australia, the UK, New Zealand, Singapore and Malaysia.",
      "In a single, integrated platform, Employment Hero automates the flow of data from employees across rosters, timesheets, leave management, pay runs, and reporting. With everything under one roof, accountants, bookkeepers and outsourced payroll providers can save costs for their clients and free them from the stressors of payroll with a system that makes it simple.",
      "Change the way you work and pay with Employment Hero!",
    ],
  },
  {
    companyName: "Xero",
    imageUrl: "https://staffd.azureedge.net/www/integrations/xero.webp",
    bgColor: "#fbfbfb",
    tag: "xero",
    link: "https://www.xero.com/",
    contentLines: [
      "Xero accounting software is made for small businesses. Automate admin and work from anywhere, anytime.",
      "Send Invoices, Accept Payments and Create Expense Claims from Anywhere. Accounting software with all the time-saving tools you need. Track Cashflow. Real-time Data. Simple to Use. Invoice Creation. Services: Accounting Software, Payroll, Invoices, Expenses, GST.",
    ],
  },
  {
    companyName: "Myob",
    imageUrl: "https://staffd.azureedge.net/www/integrations/myob.webp",
    bgColor: "#fbfbfb",
    tag: "myob",
    link: "https://www.myob.com/",
    contentLines: [
      "Online accounting software that lets you focus on your business. A single system that automates your everyday tasks and helps your business look professional, while staying compliant.",
    ],
  },
  {
    companyName: "Assignar",
    imageUrl: "https://staffd.azureedge.net/www/integrations/assignar.webp",
    bgColor: "#fbfbfb",
    tag: "assignar",
    link: "https://www.assignar.com/",
    contentLines: [
      "Construction Software that makes scheduling & management easy. Schedule crews and equipment, manage compliance quality and safety, and monitor progress from a single software platform.",
    ],
  },
  {
    companyName: "Webhooks",
    imageUrl: "https://staffd.azureedge.net/www/integrations/webhooks.webp",
    bgColor: "#161d42",
    tag: "webhooks",
    contentLines: [
      "With the Staffd Webhooks functionality, we can easily integrate with any third party website or application.",
      "Using webhooks, we can push new employee information and completed eLearning modules into any website or application.",
      "Contact our support team on <a href='mailto:support@staffd.me'>support@staffd.me</a> or <a href='tel:1300732433'>1300&nbsp;732&nbsp;433</a> to learn more.",
    ],
  },
  {
    companyName: "Staffd API",
    imageUrl: "https://staffd.azureedge.net/www/integrations/staffd-api.webp",
    bgColor: "#161d42",
    tag: "staffd-api",
    contentLines: [
      "With the Staffd Public API, you can integrate with your own website, application or Learning Management System (LMS) to create employees or trainees and automatically assign eLearning modules.",
      "If you are a Registered Training Organisation (RTO) or you sell non-accredited training programs, you can integrate Staffd with your Learning Management System (LMS).",
      "Contact our support team on <a href='mailto:support@staffd.me'>support@staffd.me</a> or <a href='tel:1300732433'>1300&nbsp;732&nbsp;433</a> to learn more.",
    ],
  },
];
