/**
 * Custom Phone input
 * - Accepts numerics and spaces (note single space between chars)
 */
import React from "react";
import PropTypes from "prop-types";
import "./bs5-custom-phone-input.scss";
import { noop } from "lodash";
import ActivityIndicator from "../activity-indicator/activity-indicator";

const Bs5CustomPhoneInput = ({
  type,
  name,
  label,
  value,
  placeholder,
  size,
  containerCss,
  textboxCss,
  withBorderRadius,
  disabled,
  busyMode,
  maxDigitsIncludingSpaces,
  onChange,
  onBlur,
  onEnterKey,
  maxLength,
}) => {
  //const regexOnlyNumbers = /^[0-9\b]+$/;
  const regexOnlyNumbers = /^[0-9\s]*$/;

  const handleChange = (e) => {
    if (
      regexOnlyNumbers.test(e.target.value) &&
      e.target.value.length <= maxDigitsIncludingSpaces
    ) {
      // Sanitize value remove multiple spaces, we only allow single space
      let sanitizedValue = e.target.value.replace(/\s\s+/g, " ");
      onChange(sanitizedValue);
    } else {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    const keyWhitelist = [
      "Backspace",
      "Delete",
      "ArrowRight",
      "ArrowLeft",
      "ArrowUp",
      "ArrowDown",
      "Tab",
    ];

    //console.log(`Control key ${e.ctrlKey}`);
    //console.log(e.key);
    //console.log(e.repeat);
    //console.log(e.code);

    if (e.ctrlKey && e.code === "KeyV") return;

    if (e.ctrlKey && e.code === "KeyC") return;

    if (e.code === "Home") return;

    if (e.code === "End") return;

    if (e.shiftKey && e.key === "Home") return;

    if ((value == null || value === "") && e.key === " ") {
      e.preventDefault();
      return;
    }

    if (!regexOnlyNumbers.test(e.key) && !keyWhitelist.includes(e.code)) {
      e.preventDefault();
      return;
    }
  };

  const getSize = () => {
    switch (size) {
      case "xl":
        return "form-control-xl";
      case "lg":
        return "form-control-lg";
      case "sm":
        return "form-control-sm";

      default:
        return "form-control-md";
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      onEnterKey();
      event.preventDefault();
    }
  };

  return (
    <section className={`bs5-custom-phone-input-wrapper ${containerCss}`}>
      <div className="form-label-group">
        <input
          id={name}
          type={type}
          value={value}
          className={`form-control ${getSize()} ${textboxCss} ${
            withBorderRadius ? "with-border-radius" : ""
          }`}
          placeholder={placeholder === null ? label : placeholder}
          autoComplete="off"
          disabled={disabled || busyMode}
          onChange={handleChange}
          onBlur={onBlur}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          required
          maxLength={maxLength}
          //autoFocus
        />
        {busyMode && <ActivityIndicator />}
        <label htmlFor={name}>{label}</label>
      </div>
    </section>
  );
};

Bs5CustomPhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  maxDigitsIncludingSpaces: PropTypes.number,
  containerCss: PropTypes.string,
  textboxCss: PropTypes.string,
  withBorderRadius: PropTypes.bool,
  disabled: PropTypes.bool,
  busyMode: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onEnterKey: PropTypes.func,
  maxLength: PropTypes.number,
};

Bs5CustomPhoneInput.defaultProps = {
  type: "text",
  size: "md",
  textboxCss: "",
  maxDigitsIncludingSpaces: 15,
  withBorderRadius: true,
  placeholder: null,
  busyMode: false,
  onChange: noop,
  onBlur: noop,
  onEnterKey: noop,
  maxLength: 1000,
};

export default Bs5CustomPhoneInput;
