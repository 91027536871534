import React from "react";
import PropTypes from "prop-types";
import "./material-input.scss";

function MatInput({
  type,
  name,
  label,
  value,
  placeholder,
  textAreaSize,
  onChange,
  onBlur,
}) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  if (type === "textarea") {
    return (
      <section className="material-container">
        <div className="group">
          <textarea
            id={name}
            value={value}
            placeholder={placeholder}
            style={{ height: textAreaSize }}
            rows={3}
            autoComplete="off"
            onChange={handleChange}
            onBlur={onBlur}
            required
          />
          <label htmlFor={name}>{label}</label>
          <span className="highlight" />
          <span className="bar" />
        </div>
      </section>
    );
  }

  return (
    <section className="material-container">
      <div className="group">
        <input
          type={type}
          id={name}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          onChange={handleChange}
          onBlur={onBlur}
          required
        />
        <label htmlFor={name}>{label}</label>
        <span className="highlight" />
        <span className="bar" />
      </div>
    </section>
  );
}

MatInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  textAreaSize: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

MatInput.defaultProps = {
  textAreaSize: "150px",
  onChange: () => {},
  onBlur: () => {},
};

export default MatInput;
