export const attributes = [
  { name: "id", type: "string", description: "Id of the Employee in Staffd." },
  {
    name: "externalUserId",
    type: "string",
    description:
      "The Employee's forgein key/external system ID number. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "firstName",
    type: "string",
    description: "The First Name of the Employee.",
  },
  {
    name: "lastName",
    type: "string",
    description: "The Last Name of the Employee.",
  },
  {
    name: "email",
    type: "string",
    description: "The Email Address of the Employee.",
  },
  {
    name: "mobile",
    type: "string",
    description: "The Mobile Phone Number of the Employee.",
  },
  {
    name: "dateOfBirth",
    type: "DateTime (nullable)",
    description: "The Date of Birth of the Employee in ISO format.",
  },
  {
    name: "gender",
    type: "GenderType (nullable)",
    description:
      "The Gender of the Employee expressed as the integer value of the GenderType enum.",
    linkToTag: "#gender-type",
  },
  {
    name: "pronouns",
    type: "PronounsType (nullable)",
    description:
      "The Pronouns of the Employee expressed as the integer value of the PronounsType enum.",
    linkToTag: "#pronouns-type",
  },
  {
    name: "socialInclusions",
    type: "array of SocialInclusionType",
    description:
      "The Social Inclusions of the Employee expressed as an array of the integer values of the SocialInclusionType enum.",
    linkToTag: "#social-inclusion-type",
  },
  {
    name: "homeUnitNumber",
    type: "string",
    description: "The Employee's Home Address - Unit Number.",
  },
  {
    name: "homeStreetNumber",
    type: "string",
    description: "The Employee's Home Address - Street Number.",
  },
  {
    name: "homeStreetName",
    type: "string",
    description: "The Employee's Home Address - Street Name.",
  },
  {
    name: "homeCity",
    type: "string",
    description: "The Employee's Home Address - City/Town/Suburb.",
  },
  {
    name: "homeState",
    type: "string",
    description: "The Employee's Home Address - State.",
  },
  {
    name: "homePostCode",
    type: "string",
    description: "The Employee's Home Address - Postcode.",
  },
  {
    name: "homeFullAddress",
    type: "string",
    description: "The Employee's Home Address - Full Address.",
  },
  {
    name: "postalUnitNumber",
    type: "string",
    description: "The Employee's Postal Address - Unit Number.",
  },
  {
    name: "postalStreetNumber",
    type: "string",
    description: "The Employee's Postal Address - Street Number.",
  },
  {
    name: "postalStreetName",
    type: "string",
    description: "The Employee's Postal Address - Street Name.",
  },
  {
    name: "postalCity",
    type: "string",
    description: "The Employee's Postal Address - City/Town/Suburb.",
  },
  {
    name: "postalState",
    type: "string",
    description: "The Employee's Postal Address - State.",
  },
  {
    name: "postalPostCode",
    type: "string",
    description: "The Employee's Postal Address - Postcode.",
  },
  {
    name: "postalFullAddress",
    type: "string",
    description: "The Employee's Postal Address - Full Address.",
  },
  {
    name: "emergencyContactFirstName",
    type: "string",
    description: "The Employee's Emergency Contact - First Name.",
  },
  {
    name: "emergencyContactLastName",
    type: "string",
    description: "The Employee's Emergency Contact - Last Name.",
  },
  {
    name: "emergencyContactRelationship",
    type: "string",
    description: "The Employee's Emergency Contact - Relationship.",
  },
  {
    name: "emergencyContactPhone1",
    type: "string",
    description: "The Employee's Emergency Contact - Contact Phone Number.",
  },
  {
    name: "emergencyContactPhone2",
    type: "string",
    description:
      "The Employee's Emergency Contact - Alternative Contact Phone Number.",
  },
  {
    name: "emergencyAltContactFirstName",
    type: "string",
    description: "The Employee's Alternative Emergency Contact - First Name.",
  },
  {
    name: "emergencyAltContactLastName",
    type: "string",
    description: "The Employee's Alternative Emergency Contact - Last Name.",
  },
  {
    name: "emergencyAltContactRelationship",
    type: "string",
    description: "The Employee's Alternative Emergency Contact - Relationship.",
  },
  {
    name: "emergencyAltContactPhone1",
    type: "string",
    description:
      "The Employee's Alternative Emergency Contact - Contact Phone Number.",
  },
  {
    name: "emergencyAltContactPhone2",
    type: "string",
    description:
      "The Employee's Alternative Emergency Contact - Alternative Contact Phone Number.",
  },
  {
    name: "photoUrl",
    type: "string",
    description:
      "The Uri of the Uploaded Photo/Headshot of the Employee (if applicable).",
  },
  {
    name: "uploads",
    type: "array of SkillUpload",
    description:
      "A collection of the Licences, Skills or Qualifications uploaded by the Employee.",
    linkToTag: "#skill-upload-object",
  },
];
