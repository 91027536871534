export const attributes = [
  {
    name: "externalUserId",
    type: "string",
    description:
      "The Employee's forgein key/external system ID number. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "externalSkillId",
    type: "string",
    description:
      "The Licence, Skill or Qualification's forgein key/external system ID number. Values can include integers and GUIDs represented as a string value.",
  },
];
