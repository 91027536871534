/**
 * System Reducers
 */
import { combineReducers } from "redux";
import NotificationReducer from "./notification-reducer";
import ErrorNotificationReducer from "./error-notification-reducer";
import UserProfileReducer from "./user-profile-reducer";
import SystemWideMessageReducer from "./system-wide-reducer";

/**
 * System functionalities
 */
const SystemReducer = combineReducers({
  notification: NotificationReducer,
  errorNotification: ErrorNotificationReducer,
  userProfile: UserProfileReducer,
  systemWideMessage: SystemWideMessageReducer,
});

export default SystemReducer;
