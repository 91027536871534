import React from "react";
import PropTypes from "prop-types";
import "./section-heading.scss";

function SectionHeading({
  id,
  children,
  heading,
  subHeading,
  subSubHeading,
  containerCss,
  headingCss,
  separatorCss,
  subHeadingCss,
  subSubHeadingCss,
}) {
  return (
    <section
      id={id}
      className={`section-heading d-flex flex-row justify-content-center ${containerCss}`}
    >
      <div className="d-flex flex-column justify-content-center align-items-center heading-info-wrapper">
        <h1 className={`mb-3 text-center ${headingCss}`}>{heading}</h1>
        <div className={`separator mb-3 ${separatorCss}`}></div>
        <div className={`subHeading text-center ${subHeadingCss}`}>
          {subHeading}
        </div>
        {subSubHeading && (
          <div className={`subHeading mt-3 text-center ${subSubHeadingCss}`}>
            {subSubHeading}
          </div>
        )}
        {children && <>{children}</>}
      </div>
    </section>
  );
}

SectionHeading.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerCss: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  subSubHeading: PropTypes.string,
  headingCss: PropTypes.string,
  separatorCss: PropTypes.string,
  subHeadingCss: PropTypes.string,
  subSubHeadingCss: PropTypes.string,
};

SectionHeading.defaultProps = {
  containerCss: "",
  headingCss: "",
  separatorCss: "",
  subHeadingCss: "",
  subSubHeadingCss: "",
};

export default SectionHeading;
