import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/signup";

  const createSubscription = (payload) => httpClient.post(`${apiRootUrl}/subscribe/free-trial`, payload);

  return {
    createSubscription
  };
};

export default ApiService;
