const planFormData = [
  {
    term: "Monthly",
    description:
      "Terms & Conditions apply. All prices exclude GST. Custom integrations are POA.",
    planSelectors: [
      {
        key: "basic",
        name: "Basic",
        productCode: "MBASIC",
        price: "$99",
      },
      {
        key: "standard",
        name: "Standard",
        productCode: "MSTD",
        price: "$249",
      },
      {
        key: "standardplusintegrations",
        name: "Standard + Integrations",
        productCode: "MSTDP",
        price: "$299",
      },
      {
        key: "premium",
        name: "Premium",
        productCode: "MPRM",
        price: "$450",
      },
    ],
    features: [
      {
        feature: "Monthly Price",
        basic: "$99",
        standard: "$249",
        standardplusintegrations: "$299",
        premium: "$450",
        isPriceRow: true,
      },
      {
        feature: "Applicants per month",
        basic: "10",
        standard: "50",
        standardplusintegrations: "50",
        premium: "Unlimited",
      },
      {
        feature: "Employees (active)",
        basic: "50",
        standard: "100",
        standardplusintegrations: "100",
        premium: "Unlimited",
      },
      {
        feature: "Applicant Management & Employee Onboarding",
        basic: "Yes",
        standard: "Yes",
        standardplusintegrations: "Yes",
        premium: "Yes",
      },
      {
        feature: "Employee eLearning",
        basic: "--",
        standard: "Yes",
        standardplusintegrations: "Yes",
        premium: "Yes",
      },
      {
        feature: "Employee Forms",
        basic: "--",
        standard: "--",
        standardplusintegrations: "--",
        premium: "Yes",
      },
      {
        feature: "Integrations",
        basic: "--",
        standard: "--",
        standardplusintegrations: "Yes",
        premium: "Yes",
      },
      {
        feature: "Customised Workflows",
        basic: "--",
        standard: "--",
        standardplusintegrations: "--",
        premium: "Yes",
      },
    ],
  },
  {
    term: "Yearly",
    description:
      "Terms & Conditions apply. All prices exclude GST. * Yearly plans are billed 12 months in advance. Custom integrations are POA.",
    planSelectors: [
      {
        key: "basic",
        name: "Basic",
        productCode: "YBASIC",
        price: "$1,069.20"
      },
      {
        key: "standard",
        name: "Standard",
        productCode: "YSTD",
        price: "$2,689.20"
      },
      {
        key: "standardplusintegrations",
        name: "Standard + Integrations",
        productCode: "YSTDP",
        price: "$3,229.20"
      },
      {
        key: "premium",
        name: "Premium",
        productCode: "YPRM",
        price: "$4,860"
      },
    ],
    features: [
      {
        feature: "Monthly Price*",
        basic: "$89.10",
        standard: "$224.10",
        standardplusintegrations: "$269.10",
        premium: "$405",
        isPriceRow: true,
      },
      {
        feature: "Applicants per month",
        basic: "10",
        standard: "50",
        standardplusintegrations: "50",
        premium: "Unlimited",
      },
      {
        feature: "Employees (active)",
        basic: "50",
        standard: "100",
        standardplusintegrations: "100",
        premium: "Unlimited",
      },
      {
        feature: "Applicant Management & Employee Onboarding",
        basic: "Yes",
        standard: "Yes",
        standardplusintegrations: "Yes",
        premium: "Yes",
      },
      {
        feature: "Employee eLearning",
        basic: "--",
        standard: "Yes",
        standardplusintegrations: "Yes",
        premium: "Yes",
      },
      {
        feature: "Employee Forms",
        basic: "--",
        standard: "--",
        standardplusintegrations: "--",
        premium: "Yes",
      },
      {
        feature: "Integrations",
        basic: "--",
        standard: "--",
        standardplusintegrations: "Yes",
        premium: "Yes",
      },
      {
        feature: "Customised Workflows",
        basic: "--",
        standard: "--",
        standardplusintegrations: "--",
        premium: "Yes",
      },
    ],
  },
];

export default planFormData;
