import React from "react";
import { Link } from "react-router-dom";

const FeaturesCallToAction = () => {
  return (
    <div className="mt-4 mb-4 mb-md-0">
      <Link
        className="btn sw-feature-details-btn btn-lg mr-2"
        to={"/#book-a-demo"}
      >
        Book a Demo
      </Link>
      <Link
        className="btn sw-feature-alt-details-btn btn-lg"
        to={"/signup"}
      >
        Try for Free
      </Link>
    </div>
  );
};

export default FeaturesCallToAction;
