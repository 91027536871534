const employeeOnboardingLearnMoreData = [
  {
    id: "feature-employee-onboarding",
    heading:
      "Seamless and consistent <span class='highlight-text'>Employee Onboarding Online</span>",
    subHeading:
      "Streamline your onboarding process and provide new hires with a consistent and comprehensive experience that accurately reflects your company's brand and values. Our secure Form Uploader and Offer Letter Generator improve HR compliance and meet legal requirements.",
    bulletPoints: [
      "Provide the latest company policies",
      "Upload completed Tax forms",
      "Gather bank and Super details with ease",
      "Offer Letters that can be digitally signed on any device.",
    ],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/application-and-onboarding-steps.webp",
    imageAlt: "Complete Employee Onboarding Online",
    marginTop: "0",
  },
  {
    id: "policy-downloads",
    heading: "<span class='highlight-text'>Policy Downloads</span>",
    subHeadings: [
      "Add your company policies for applicants to view, download and accept online as part of the onboarding process, saving time during induction.",
      "The system allows for easily accessed tracking to ensure all necessary policies have been viewed, as per your company requirements.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/policy-downloads.webp",
    imageAlt: "Policy Downloads",
  },
  {
    id: "health-declaration",
    heading: "<span class='highlight-text'>Health Declaration</span>",
    subHeadings: [
      "Our simple and easy to use form allows you to gather and file the relevant medical information required from all applicants.",
      "You will acquire the medical knowledge needed to protect your employees, cover your business, and ensure legal compliance.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/health-declaration.webp",
    imageAlt: "Health Declaration",
  },
  {
    id: "form-uploads",
    heading: "<span class='highlight-text'>Form Uploads</span>",
    subHeadings: [
      "The applicant can quickly download and complete any required forms, and then upload the details for use by your admin team.",
      "Our platform means no more worrying about missing hardcopy paperwork or delays in acquiring the information you need.",
    ],
    bulletPoints: [],
    imagePath: "https://staffd.azureedge.net/www/screenshots/v4/tax-form.webp",
    imageAlt: "Form Uploads",
  },
  {
    id: "downloads",
    heading: "<span class='highlight-text'>Document Downloads</span>",
    subHeadings: [
      "Provide your new employee with all the legally required documents so they have all the information they need.",
      "Ensure compliance with government requirements with evidence of all documents supplied.",
    ],
    bulletPoints: [],
    imagePath: "https://staffd.azureedge.net/www/screenshots/v4/downloads.webp",
    imageAlt: "Document Downloads",
  },
  {
    id: "offer-letter",
    heading: "<span class='highlight-text'>Offer Letter</span>",
    subHeadings: [
      "Offer Letters are generated based on the role applied for. The applicant can then review the offer and digitally sign on any device.",
      "By adding your own auto generated Offer Letter, your company can meet your legal requirements in a few simple steps.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/offer-letter.webp",
    imageAlt: "Offer Letter",
  },
];

export default employeeOnboardingLearnMoreData;
