import React from "react";
import PropTypes from "prop-types";
import Arc from "./arc/arc";
import WaveCurve from "./wave-curve/wave-curve";
import Linear from "./linear/linear";
import Angle from "./angle/angle";
import Plain from "./plain/plain";

function ContentFxFactory({ type, options, ...rest }) {
  switch (type) {
    case "arc":
      return <Arc {...rest} color={options.color} bgColor={options.bgColor} />;

    case "waveCurve":
      return (
        <WaveCurve {...rest} color={options.color} bgColor={options.bgColor} />
      );

    case "linear":
      return (
        <Linear {...rest} color={options.color} bgColor={options.bgColor} />
      );

    case "angle":
      return (
        <Angle {...rest} color={options.color} bgColor={options.bgColor} />
      );

    case "plain":
      return <Plain {...rest} bgColor={options.bgColor} />;

    default:
      return <h4>Invalid design type.</h4>;
  }
}

ContentFxFactory.propTypes = {
  type: PropTypes.string,
  options: PropTypes.object,
};

ContentFxFactory.defaultProps = { type: "", options: {} };

export default ContentFxFactory;
