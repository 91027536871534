import loadable from "@loadable/component";
import LoginLayout from "layouts/login-layout";
const FullLayout = loadable(() => import("../layouts/full-layout"));
const BlankLayout = loadable(() => import("../layouts/blank-layout"));
const SignupLayout = loadable(() =>
  import("../layouts/sign-up-layout-wrapper")
);
const ManageLayout = loadable(() => import("./../layouts/manage-layout"));
const AdminLayout = loadable(() => import("layouts/admin-layout"));

var indexRoutes = [
  { path: "/pages", name: "System Misc. Pages", component: BlankLayout },
  { path: "/login", name: "Login page", component: LoginLayout },
  { path: "/forgot-password", name: "Forgot Password", component: LoginLayout },
  { path: "/reset-password", name: "Reset Password", component: LoginLayout },
  { path: "/signup", name: "Sign up Pages", component: SignupLayout },
  { path: "/manage", name: "Manage Pages", component: ManageLayout },
  { path: "/admin", name: "Admin", component: AdminLayout },
  { path: "/", name: "Home", component: FullLayout },
];

export default indexRoutes;
