import React from "react";
import { useLocation } from "react-router";
import DocumentMeta from "react-document-meta";
import ContentService from "../../services/content-service";
import ContentPageWrapper from "./content-wrapper/content-page-wrapper";
import useScrollNavigate from "hooks/use-scroll-navigate";

function ContentPageView() {
  const location = useLocation();
  useScrollNavigate(true, 100);

  const getPageName = (pathName) => {
    return pathName
      .replace(new RegExp("^[/]+"), "")
      .replace(new RegExp("[/]+$"), "")
      .toLowerCase();
  };

  const getMeta = (page) => {
    return {
      title: page.title,
      description: page.description,
      canonical: `https://staffd.me/${page.pageName}/`,
      meta: {
        charset: "utf-8",
      },
    };
  };

  const pageName = getPageName(location.pathname);
  const page = ContentService().getContentPage(pageName);

  return (
    <DocumentMeta {...getMeta(page)}>
      {!page.noContentSection && (
        <ContentPageWrapper containerCss="pt-0">
          {page.content}
        </ContentPageWrapper>
      )}
      {page.noContentSection && page.content}
    </DocumentMeta>
  );
}

export default ContentPageView;
