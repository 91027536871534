import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { addErrorNotification } from "redux/system/system-action-creators";
import ApiService from "./services/apiService";
import UserIdentity from "core/auth/userIdentity";

const initialState = {
  entities: [],
  settings: {}, // Last current settings of solvable dataTable
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
export const getInvoices = createAsyncThunk(
  "taxInvoice/getInvoices",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().getTaxInvoices(
        UserIdentity().getUserId()
      );

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch tax invoice(s).")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const taxInvoiceSlice = createSlice({
  name: "taxInvoice",
  initialState,
  reducers: {
    keepDataTableSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    });
    builder.addCase(getInvoices.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { keepDataTableSettings } = taxInvoiceSlice.actions;

export default taxInvoiceSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.taxInvoices;

export const selectTaxInvoice = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
