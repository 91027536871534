import React from "react";
import PropTypes from "prop-types";
import "./bg-content.scss";

function BgContent({ children }) {
  return (
    <section className="bg-content-wrapper">
      <div className="bg-img-section">
        <div className="bg-img">
          <img
            src="https://staffd.azureedge.net/www/signup/signup-bg.webp"
            alt=""
          />
        </div>
      </div>
      <div className="content-section-fluid d-flex flex-column">{children}</div>
    </section>
  );
}

BgContent.propTypes = {
  children: PropTypes.object,
};

BgContent.defaultProps = {};

export default BgContent;
