import { HttpClient } from "core/http";

const SignupProgressService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/signup";

  const checkProgress = (userName) => httpClient.get(`${apiRootUrl}/check-progress-status/${userName}`);

  return {
    checkProgress
  };
};

export default SignupProgressService;
