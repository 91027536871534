const countryData = [
  {
    label: "Australia",
    value: 0,
  },
  {
    label: "New Zealand",
    value: 1,
  },
  {
    label: "United States",
    value: 2,
  },
  {
    label: "United Kingdom",
    value: 3,
  },
];

export default countryData;
