import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./../../services/companyContactApiService";

const initialState = {
  entities: [],
  settings: {},

  loading: false,
};

// --------------
// Action Thunks
// --------------
export const loadData = createAsyncThunk(
  "viewClients/companyContacts/loadData",
  async (id, thunkAPI) => {
    try {
      let result = await ApiService().getTenantCompanyContacts(id);
      return result.data;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to fetch data."));
      throw err;
    }
  }
);

export const addTenantCompanyContact = createAsyncThunk(
  "viewClients/companyContacts/add",
  async (payload, thunkAPI) => {
    try {
      let result = await ApiService().addTenantCompanyContact(payload);

      thunkAPI.dispatch(
        addNotification("Contact has been successfully added.")
      );

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to add the contact.")
      );
      throw err;
    }
  }
);

export const updateTenantCompanyContact = createAsyncThunk(
  "viewClients/companyContacts/update",
  async (payload, thunkAPI) => {
    try {
      await ApiService().updateTenantCompanyContact(payload);

      thunkAPI.dispatch(
        addNotification("Contact has been successfully updated.")
      );

      return payload;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to update the contact.")
      );
      throw err;
    }
  }
);

export const deleteCompanyContact = createAsyncThunk(
  "viewClients/companyContacts/delete",
  async (payload, thunkAPI) => {
    try {
      await ApiService().deleteTenantCompanyContact(payload);

      thunkAPI.dispatch(
        addNotification("Contact has been successfully deleted.")
      );

      return payload.tenantCompanyContactId;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to delete the contact.")
      );
      throw err;
    }
  }
);

// ---------------
// Slice
// ---------------
export const companyContactsSlice = createSlice({
  name: "viewClients/companyContacts",
  initialState,
  reducers: {
    keepDataTableSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    //
    // initLoadData
    //
    builder.addCase(loadData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadData.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = action.payload;
    });

    builder.addCase(loadData.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteCompanyContact.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteCompanyContact.fulfilled, (state, action) => {
      state.loading = false;
      state.entities = state.entities.filter((x) => x.id !== action.payload);
    });

    builder.addCase(deleteCompanyContact.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addTenantCompanyContact.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addTenantCompanyContact.fulfilled, (state, action) => {
      delete action.payload.tenantCompanyUserId;

      state.loading = false;
      state.entities = [...state.entities, action.payload];
    });

    builder.addCase(addTenantCompanyContact.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateTenantCompanyContact.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateTenantCompanyContact.fulfilled, (state, action) => {
      delete action.payload.tenantCompanyUserId;

      state.loading = false;
      state.entities = state.entities.map((x) => {
        if (x.id === action.payload.id) return action.payload;
        return x;
      });
    });

    builder.addCase(updateTenantCompanyContact.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { keepDataTableSettings } = companyContactsSlice.actions;

export default companyContactsSlice.reducer;

// ----------------
// Selectors
// ----------------
const selectSelf = (state) => state.companyContacts;

export const selectCompanyContacts = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
