import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  hasBeenSubmitted: false,
  form: null,
};

export default function FormBuilderReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FORM_OPERATE_REQUEST:
    case ActionTypes.FORM_OPERATE_SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FORM_DETAILS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        form: action.payload,
      };
    case ActionTypes.FORM_DETAILS_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasBeenSubmitted: true,
      };
    case ActionTypes.FORM_OPERATE_FAILURE:
    case ActionTypes.FORM_OPERATE_HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.FORM_DETAILS_CLEAN_UP:
      return initialState;

    default:
      return state;
  }
}
