import {
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";

const initialState = {
  activeTab: "1",
  tabData: [
    { id: "1", label: "Client Information" },
    { id: "2", label: "Contacts" },
    { id: "3", label: "Subscriptions" },
    { id: "4", label: "Transaction Logs" },
    { id: "5", label: "Tax Invoices" },
    { id: "6", label: "Comments" },
  ],

  loading: false,
};

// ---------------
// Slice
// ---------------
export const viewClientSlice = createSlice({
  name: "viewClients",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    clearStates: (state) => {
      state.activeTab = "1";
    },
  },
  extraReducers: (builder) => {},
});

export const { setActiveTab, clearStates } = viewClientSlice.actions;

export default viewClientSlice.reducer;

// ----------------
// Selectors
// ----------------
const selectSelf = (state) => state.viewClient;

export const selectViewClients = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
