import React from "react";
import { useFormikContext } from "formik";
import { ErrorMessage } from "formik";
import "./validation-message.scss";

const ValidationMessage = ({ name, containerCss }) => {
  const { status } = useFormikContext();
  return (
    <div className={containerCss}>
      {status && status[name] ? (
        <>
          <div className="has-error mt-1">{status[name]}</div>
        </>
      ) : (
        <ErrorMessage name={name} component="div">
          {(msg) => <div className="has-error mt-1">{msg}</div>}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ValidationMessage;
