import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
  } from "@reduxjs/toolkit";
  import { addErrorNotification } from "redux/system/system-action-creators";
  import ApiService from "./../../services/apiService";
  
  const initialState = {
    entities: [],
    settings: {}, // Last current settings of solvable dataTable
    loading: false,
  };
  
  // ---------------
  // Action Thunks
  // ---------------
  export const getInvoices = createAsyncThunk(
    "viewClients/taxInvoice/getInvoices",
    async (customerId, thunkAPI) => {
      try {
        let result = await ApiService().getTaxInvoices(customerId);
  
        return result.data;
      } catch (err) {
        thunkAPI.dispatch(
          addErrorNotification(err, "Unable to fetch tax invoice(s).")
        );
        throw err;
      }
    }
  );
  
  // ------------
  // Slice
  // ------------
  export const clientTaxInvoiceSlice = createSlice({
    name: "viewClients/taxInvoice",
    initialState,
    reducers: {
      keepDataTableSettings: (state, action) => {
        state.settings = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getInvoices.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      });
      builder.addCase(getInvoices.rejected, (state) => {
        state.loading = false;
      });
    },
  });
  
  export const { keepDataTableSettings } = clientTaxInvoiceSlice.actions;
  
  export default clientTaxInvoiceSlice.reducer;
  
  // ------------
  // Selectors
  // ------------
  const selectSelf = (state) => state.clientTaxInvoices;
  
  export const selectClientTaxInvoice = createDraftSafeSelector(
    selectSelf,
    (state) => state
  );
  