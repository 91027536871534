/**
 * Custom Checkbox without Formik
 * rcnet@solvable
 */
import React from "react";
import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";
import { noop } from "lodash";
import "./custom-checkbox.scss";

const CustomCheckbox = ({
  name,
  label,
  value,
  placeholder,
  showLabel,
  showRequiredLabel,
  containerCss,
  checkBoxCss,
  labelCss,
  labelBold,
  onChecked,
  ...rest
}) => {
  const handleOnChecked = (evt) => onChecked(evt.target.checked);

  return (
    <div className={`sw-input-field ${containerCss}`}>
      <Input
        {...rest}
        id={name}
        name={name}
        style={{
          height: "15px",
          width: "15px",
          position: "inherit",
          marginLeft: 0,
        }}
        className={`ml-2 ${checkBoxCss}`}
        type="checkbox"
        checked={value}
        placeholder={placeholder}
        onChange={handleOnChecked}
      />
      <Label
        className={`${
          labelBold ? "fw-600 text-indent-label" : "text-indent-label"
        } ${labelCss}`}
        htmlFor={name}
      >
        {/* {showLabel && <>{label}</>} */}
        {showLabel && <div dangerouslySetInnerHTML={{ __html: label }}></div>}
      </Label>
      {showRequiredLabel && <span className="text-danger">*</span>}
    </div>
  );
};

CustomCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  labelBold: PropTypes.bool,
  checkBoxCss: PropTypes.string,
  labelCss: PropTypes.string,
  containerCss: PropTypes.string,
  onChange: PropTypes.func,
};

CustomCheckbox.defaultProps = {
  showRequiredLabel: false,
  showLabel: true,
  labelBold: false,
  checkBoxCss: "with-font",
  onChange: noop,
};

export default React.memo(CustomCheckbox);
