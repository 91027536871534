import React from "react";
import PropTypes from "prop-types";
import "./wave-curve.scss";
import ContentSection from "components/common/content-section";

function WaveCurve({
  children,
  containerCss,
  color,
  bgColor,
  contentPadding,
  showTopCurve,
  ...rest
}) {
  return (
    <div className={`wave-curve-content-fx ${containerCss}`}>
      {showTopCurve && (
        <section className="top-curve">
          <svg
            className="svg-art-top"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={{ backgroundColor: bgColor }}
          >
            <path
              d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z"
              fill={color}
            ></path>
          </svg>
        </section>
      )}
      <div
        className="content-wrapper"
        style={{
          paddingTop: "65px",
          paddingBottom: "65px",
          backgroundColor: color,
        }}
      >
        <ContentSection
          {...rest}
          specificClass="content-container"
          containerStyle={{ backgroundColor: color }}
          padding={contentPadding}
        >
          {children}
        </ContentSection>
      </div>
      <section className="bottom-curve">
        <div className="svg-art-bottom-container">
          <svg
            className="svg-art-bottom"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={{ backgroundColor: color }}
          >
            <path
              d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z"
              fill={bgColor}
            ></path>
          </svg>
        </div>
      </section>
    </div>
  );
}

WaveCurve.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerCss: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  contentPadding: PropTypes.string, // Bootstrap padding, valid values:  "0, 1, 2, 3, 4, 5"
  showTopCurve: PropTypes.bool,
};

WaveCurve.defaultProps = {
  data: {},
  containerCss: "",
  color: "#42387a",
  bgColor: "#fff",
  contentPadding: "4",
  showTopCurve: true,
};

export default WaveCurve;
