export const attributes = [
  {
    name: "name",
    type: "string",
    description:
      "The original file name of the uploaded file, with file extension.",
  },
  {
    name: "fileBase64",
    type: "string",
    description:
      "The uploaded represented as a Base64 string. Valid files include PDF, JPG, JPEG and PNG file types.",
  },
];
