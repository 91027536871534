import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/my-account";

  // const getDetails = (id) =>
  //   httpClient.get(`${apiRootUrl}/company-details/${id}`);

  const getAccountInfo = (id) => httpClient.get(`${apiRootUrl}/${id}`);

  const changePassword = (payload) =>
    httpClient.post(`v1/core/accounts/password/change`, payload);

  const updateDetails = (id, payload) =>
    httpClient.post(`${apiRootUrl}/${id}/company`, payload);

  const updatePaymentDetails = (id, payload) =>
    httpClient.patch(`${apiRootUrl}/${id}/payment-details`, payload);

  return {
    getAccountInfo,
    updatePaymentDetails,
    changePassword,
    updateDetails,
  };
};

export default ApiService;
