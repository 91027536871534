import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/admin-manage-clients";
  let apiTagsUrl = "/v1/manage-tags";

  const getClients = () => httpClient.get(apiRootUrl);

  const deleteClient = (id) => httpClient.patch(`${apiRootUrl}/delete/${id}`);

  const undeleteClient = (id) =>
    httpClient.patch(`${apiRootUrl}/undelete/${id}`);

  const getTags = () => httpClient.get(apiTagsUrl);

  const saveTags = clientTagsStr => {
    let payload = {
      clientTags: clientTagsStr.split(/[\r\n]+/).map(tag => tag.trim())
    }
    return httpClient.post(apiTagsUrl, payload)
  }

  const attachTags = payload => httpClient.put(`${apiRootUrl}/attach-tags`, payload)

  return {
    getClients,
    deleteClient,
    undeleteClient,
    getTags,
    saveTags,
    attachTags
  };
};

export default ApiService;
