import { MessageType } from "components/common/system-message/message-type";
import { ActionTypes } from "./system-action-types";

const initialState = {
  message: "",
  messageType: MessageType.DEFAULT,
  iconType: null,
  show: false,
};

export default function SystemWideMessageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SYS_SHOW_SYSTEM_WIDE_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        messageType: action.payload.messageType ?? MessageType.DEFAULT,
        iconType: action.payload.iconType,
        show: true,
      };

    case ActionTypes.SYS_HIDE_SYSTEM_WIDE_MESSAGE:
      return {
        ...state,
        show: false,
      };

    default:
      return state;
  }
}

/**
 * Selectors
 */

export const selectSystemWideMessage = (state) =>
  state.system.systemWideMessage;
