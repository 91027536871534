import React from "react";
import { corePublicPages } from "./public-content-core";
import { apiDocsPublicPages } from "./public-content-api-docs";
import { FeatureDetails } from "components/features/feature-details";
import applicantManagementLearnMoreData from "constants/data/applicant-management-learn-more-data";
import employeeOnboardingLearnMoreData from "constants/data/employee-onboarding-learn-more-data";
import eLearningLearnMoreData from "constants/data/elearning-learn-more-data";
import skillManagementLearnMoreData from "./data/skill-management-learn-more-data";
import clientOnboardingLearnMoreData from "./data/client-onboarding-learn-more-data";
import IntegrationDetails from "components/features/integrations-details/integrations-details";
import { integrationsData } from "constants/data/integrations-data";
import FeaturesCallToAction from "./../components/features/features-call-to-action/features-call-to-action";

export const publicPages = [
  ...corePublicPages,
  ...apiDocsPublicPages,
  {
    pageName: "applicant-management",
    title: "Application Management | Applicant Tracking System | Staffd",
    description:
      "Staffd takes the hard work out of managing employee applications allowing prospective employees to apply online anywhere on any device.",
    content: (
      <>
        <div className="content-page-heading-wrapper">
          <h1 className="mb-3">Application Management</h1>
          <div className="separator mb-3"></div>
        </div>
        <FeatureDetails
          data={applicantManagementLearnMoreData}
          initialTextDirection="left"
        />
        <FeaturesCallToAction />
      </>
    ),
  },
  {
    pageName: "employee-onboarding",
    title: "Employee Onboarding Online | Paperless Onboarding Process | Staffd",
    description:
      "Enjoy complete & consistent Employee Onboarding Online. Streamline your onboarding processes & ensure new hires experience your business brand & values in a reliable way.",
    content: (
      <>
        <div className="content-page-heading-wrapper">
          <h1 className="mb-3">Employee Onboarding</h1>
          <div className="separator mb-3"></div>
        </div>
        <FeatureDetails
          data={employeeOnboardingLearnMoreData}
          initialTextDirection="left"
        />

        <div
          className="content-page-heading-wrapper"
          style={{ marginTop: "120px" }}
        >
          <h1 className="mb-3">Push the Data where you need it</h1>
          <div className="separator mb-3"></div>
        </div>
        <div className="row">
          <div className="col-md-6 text-center">
            <img
              src="https://staffd.azureedge.net/www/features/staffd-integration.webp"
              alt=""
            />
            <p className="heading mb-4p5">
              Push data to your HRIS, Payroll or Job Management system to
              seamlessly get the data where you need it without data entry.
            </p>
          </div>
          <div className="col-md-6 text-center">
            <img
              src="https://staffd.azureedge.net/www/features/staffd-emailing.webp"
              alt=""
            />
            <p>
              Automated email notifications to your team and the applicant so
              they can take action when needed. Stay on top of the current
              status.
            </p>
          </div>
        </div>
        <FeaturesCallToAction />
      </>
    ),
  },
  {
    pageName: "elearning",
    title: "eLearning on Any Device | Build Engaging Training Modules | Staffd",
    description:
      "Upskill your workforce to improve safety & employee engagement. Build your own training modules for your team which can be viewed & completed on any device.",
    content: (
      <>
        <div className="content-page-heading-wrapper">
          <h1 className="mb-3">eLearning</h1>
          <div className="separator mb-3"></div>
        </div>
        <FeatureDetails
          data={eLearningLearnMoreData}
          initialTextDirection="left"
        />
        <FeaturesCallToAction />
      </>
    ),
  },
  {
    pageName: "skill-management",
    title: "Skill Management | Staffd",
    description: "Blah",
    content: (
      <>
        <div className="content-page-heading-wrapper">
          <h1 className="mb-3">Skill Management</h1>
          <div className="separator mb-3"></div>
        </div>
        <FeatureDetails
          data={skillManagementLearnMoreData}
          initialTextDirection="left"
        />
        <FeaturesCallToAction />
      </>
    ),
  },
  {
    pageName: "client-onboarding",
    title: "Client Onboarding | Staffd",
    description: "Blah",
    content: (
      <>
        <div className="content-page-heading-wrapper">
          <h1 className="mb-3">Client Onboarding</h1>
          <div className="separator mb-3"></div>
        </div>
        <FeatureDetails
          data={clientOnboardingLearnMoreData}
          initialTextDirection="left"
        />
        <FeaturesCallToAction />
      </>
    ),
  },
  {
    pageName: "our-partners-and-integrations",
    title: "Our Partners & Integrations | Staffd",
    description:
      "Staffd can push data to your HRIS, Payroll or Job Management system to seamlessly get the data where you need it without data entry.",
    content: (
      <>
        <IntegrationDetails data={integrationsData} />
      </>
    ),
  },
];
