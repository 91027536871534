import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./services/apiService";
import { AppUrls } from "constants/app-urls";
import planFormData from "constants/data/plan-form-data";
import { getPlanTerms, getPlanType } from "../../services/plan-value-mapping";

const initialState = {
  loading: false,
  redirectTo: "",
};

// ---------------
// Action Thunks
// ---------------
export const createSubscription = createAsyncThunk(
  "accountFreeTrialOption/createSubscription",
  async (data, thunkAPI) => {
    try {
      const { values, productCode } = data;

      let payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        mobileNumber: values.mobileNumber,
        cardNumber: "bypass-process",
        expirationDate: "bypass-process",
        securityCode: "bypass-process",
        productCode: productCode,
      };

      let result = await ApiService().createSubscription(payload);

      thunkAPI.dispatch(
        addNotification(
          "Awesome, we have set up your free trial, we just need a little more info to get you up and running."
        )
      );

      return result;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to create subscription.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const accountFreeTrialOptionSlice = createSlice({
  name: "accountFreeTrialOption",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createSubscription.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createSubscription.fulfilled, (state) => {
      state.loading = false;
      state.redirectTo = AppUrls.MANAGE_SETUP;
    });

    builder.addCase(createSubscription.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default accountFreeTrialOptionSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.accountFreeTrialOption;

export const selectAccountFreeTrialOption = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

// Note: Temporary due to change request: ticket #8823
export const selectSelectedPlanInfo = createDraftSafeSelector(
  selectSelf,
  (state) => {
    let planState = planFormData[0];

    let planSelector = planFormData[0].planSelectors.find(
      (f) => f.key === "premium"
    );

    let planName = planSelector?.name;
    let productCode = planSelector?.productCode;

    return {
      price: planSelector.price,
      term: planState.term,
      priceText: `${planSelector.price}/${
        planState.term === "Monthly" ? "month" : "year"
      }`,
      plan: `${planName} Plan`,
      planTerms: getPlanTerms(planState.term),
      planType: getPlanType(state.selectedPlanData),
      productCode,
    };
  }
);
