export const attributes = [
  {
    name: "id",
    type: "string",
    description:
      "The forgein key/external system ID number of the Employee Classification / Type. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "value",
    type: "string",
    description: "The name of the Employee Classification / Type.",
  },
];

export const payload = [
  { id: "751", value: "Casual - Traffic Controller" },
  { id: "752", value: "Labourer" },
  { id: "753", value: "Plant Operator" },
];
