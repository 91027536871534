import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import Auth from 'core/auth/auth';
import UserIdentity from 'core/auth/userIdentity';

const AuthSubscriber = ({ addSubscriber, invoke }) => {
 
  useEffect(() => {
    // Define SignalR listeners
    addSubscriber("ForceLogoutClient", forceLogout);
    addSubscriber("ResetRefreshTokenClient", resetRefreshToken);
  }, []);

  const forceLogout = (userId) => {
    if (UserIdentity().getUserId() !== userId) return;
    
    // Logout session
    Auth.removeToken();
    localStorage.clear();
    window.location.reload();
  };

  const resetRefreshToken = (userId) => {
    if (UserIdentity().getUserId() !== userId) return;

    // Perform refresh token login or dispatch redux method to reset Refresh token
    // Note: Implement an API BE logic to get a new Refresh Token and update the FE AuthIdentity to reflect the new token.
  };

  return <section></section>;
};

AuthSubscriber.propTypes = {
  addSubscriber: PropTypes.func,
  invoke: PropTypes.func,
};

AuthSubscriber.defaultProps = { addSubscriber: noop, invoke: noop };

export default AuthSubscriber;
