// Used by Manage screens (manage/setup, manage/my-account)
import { AccountSetupStatusTypes } from "constants/account-setup-status-types";
import { AppUrls } from "constants/app-urls";

export function checkRedirectionRules(status) {
  switch (status) {
    case AccountSetupStatusTypes.ACCOUNT_CREATED:
      // Note: ORIGINAL CODE
      //return AppUrls.PLAN
      return AppUrls.FREE_TRIAL_OPTION;
    case AccountSetupStatusTypes.SIGNUP_COMPLETED:
      return AppUrls.MANAGE_SETUP;
    case AccountSetupStatusTypes.TENANT_SETUP_COMPLETED:
      return AppUrls.MY_ACCOUNT;
    default:
      return AppUrls.SIGNUP;
  }
}
