import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./../../services/apiService";
import UserIdentity from "core/auth/userIdentity";

const initialState = {
  loading: false,
  entryFormDefaults: {},
};

// ---------------
// Action Thunks
// ---------------
export const updatePaymentDetails = createAsyncThunk(
  "manageSubscription/changePaymentDetails/update",
  async (data, thunkAPI) => {
    try {
      const { cardNumber, expiryDate, cvc } = data.values;

      let payload = {
        userId: UserIdentity().getUserId(),
        cardNumber,
        expirationDate: expiryDate,
        securityCode: cvc,
      };

      await ApiService().updatePaymentDetails(
        UserIdentity().getUserId(),
        payload
      );

      thunkAPI.dispatch(
        addNotification("Payment details has been successfully updated.")
      );

      data.onUpdateSuccess();

      return true;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to update details."));
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const manageSubscriptionChangePaymentDetailsSlice = createSlice({
  name: "manageSubscription/changePaymentDetails",
  initialState,
  extraReducers: (builder) => {
    //
    // Update details
    //
    builder.addCase(updatePaymentDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updatePaymentDetails.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updatePaymentDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default manageSubscriptionChangePaymentDetailsSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.manageSubscriptionChangePaymentDetails;

export const selectPaymentDetails = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
