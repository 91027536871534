export const attributes = [
  {
    name: "employee",
    type: "Employee object",
    description:
      "The Employee's personal details provided during the re-induction process or when updating the employee's details.",
    linkToTag: "#employee-object",
  },
  {
    name: "signature",
    type: "string",
    description: "A secure salted hash of the employee object.",
  },
];

export const payload = {
  employee: {
    id: "0163f24e-d978-4016-caa6-08da33010a3b",
    externalUserId: "1234",
    firstName: "Sally",
    lastName: "Templeton",
    email: "sally@staffd.me",
    mobile: "0499857574",
    dateOfBirth: "1988-10-13T00:00:00",
    gender: 1,
    pronouns: 3,
    socialInclusions: [8, 9],
    homeUnitNumber: "",
    homeStreetNumber: "231",
    homeStreetName: "Elizabeth St",
    homeCity: "Sydney",
    homeState: "NSW",
    homePostCode: "2000",
    homeFullAddress: "231 Elizabeth St, Sydney NSW 2000",
    postalUnitNumber: null,
    postalStreetNumber: null,
    postalStreetName: null,
    postalCity: null,
    postalState: null,
    postalPostCode: null,
    postalFullAddress: null,
    emergencyContactFirstName: "Tom",
    emergencyContactLastName: "Templeton",
    emergencyContactRelationship: "Husband",
    emergencyContactPhone1: "0400 099 000",
    emergencyContactPhone2: "",
    emergencyAltContactFirstName: null,
    emergencyAltContactLastName: null,
    emergencyAltContactRelationship: null,
    emergencyAltContactPhone1: null,
    emergencyAltContactPhone2: null,
    photoUrl:
      "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-3a0fc74f-1f13-4723-bbc9-f82611d8d677.jpg",
    uploads: [
      {
        uploadName: "TC Ticket",
        uploadUrls: [
          "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-7f6209ed-e0e1-4c77-9789-8c5a0e6629cb.jpg",
          "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-23f743b5-fc6d-4a1d-8e91-5ca96aaf1f0e.jpg",
        ],
        uploadNumber: "54363456",
        uploadExpiry: "2025-05-28T00:00:00",
        uploadIssue: null,
        isDriversLicence: false,
        driversLicenceNumber: null,
        driversLicenceType: null,
        driversLicenceExpiry: null,
        driversLicenceAutoOnly: null,
      },
      {
        uploadName: "White Card",
        uploadUrls: [
          "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-af78ee00-8e5c-4b2b-b55f-f05f3739e6b5.jpg",
          "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-a894145d-53bd-4ec0-a557-04c03df594d9.jpg",
        ],
        uploadNumber: null,
        uploadExpiry: null,
        uploadIssue: null,
        isDriversLicence: false,
        driversLicenceNumber: null,
        driversLicenceType: null,
        driversLicenceExpiry: null,
        driversLicenceAutoOnly: null,
      },
      {
        uploadName: "Drivers Licence",
        uploadUrls: [
          "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-e04d9033-ec6b-43ea-8baa-3162b6cbe2ca.jpg",
          "https://staffd.blob.core.windows.net/demo-uploads/0163f24e-d978-4016-caa6-08da33010a3b-e08e6cc2-de38-4a06-899f-b0569051bc0b.jpg",
        ],
        uploadNumber: null,
        uploadExpiry: null,
        uploadIssue: null,
        isDriversLicence: true,
        driversLicenceNumber: "4356345",
        driversLicenceType: 3,
        driversLicenceExpiry: "2025-05-28T00:00:00",
        driversLicenceAutoOnly: false,
      },
    ],
  },
  signature: "6097942c30f77d62093baab06143f325",
};

export const responseAttributes = [
  {
    name: "message",
    type: "string (optional)",
    description:
      "An optional message that can be saved as an Employee comment in Staffd. When a validation or system error occurs, it is recommended to include a human readable message for the admin to understand the reason why the sync failed.",
  },
];

export const successResponse = {
  message: null,
};

export const failureResponse = {
  message: "Another employee exists with that email address.",
};
