import React from "react";
import PropTypes from "prop-types";
import SectionHeading from "components/features/section-heading/section-heading";
import MultiColumn from "./multi-column/multi-column";
import "./integrations-details.scss";

const IntegrationDetails = ({
  heading,
  subHeading,
  data,
  containerCss,
  columnItemCss,
}) => {
  return (
    <div className={`integrations-details-container ${containerCss} `}>
      <SectionHeading heading={heading} subHeading={subHeading}>
        <MultiColumn data={data} containerCss={columnItemCss} />
      </SectionHeading>
    </div>
  );
};

IntegrationDetails.propTypes = {
  data: PropTypes.array,
  containerCss: PropTypes.string,
  columnItemCss: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
};

IntegrationDetails.defaultProps = {
  heading: "Our Partners & Integrations",
  subHeading:
    "Staffd can push data to your HRIS, Payroll or Job Management system to seamlessly get the data where you need it without data entry.",
  containerCss: "",
  columnItemCss: "",
};

export default IntegrationDetails;
