import { combineReducers } from "redux";
import CreateUpdateUserReducer from "./../manage-users/components/create-update-user/redux/reducer";
import ManageUsersReducer from "./../manage-users/redux/reducer";

const AdminReducer = combineReducers({
  manageUsers: ManageUsersReducer,
  createUpdateUser: CreateUpdateUserReducer,
});

export default AdminReducer;
