import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/admin-manage-subscriptions";
  let myAccountRootUrl = "/v1/my-account";
  let productUrl = "/v1/products";
  let manageSubsUrl = "/v1/manage-subscriptions";

  const getAccountAndLookupsData = (id) => {
    let p1 = getAccountInfo(id);
    let p2 = getPlanLookups();

    let arrPromises = [p1, p2];

    const promiseDeferred = new Promise((resolve, reject) => {
      Promise.all(arrPromises)
        .then((resp) => {
          let result = null;

          result = {
            accountInfo: resp[0].data,
            planLookups: resp[1].data.map((o) => ({
              label: o.productName,
              value: o.productCode,
            })),
          };

          resolve({ data: result });
        })
        .catch((err) => reject(err));
    });

    return promiseDeferred;
  };

  const getAccountInfo = (id) => httpClient.get(`${myAccountRootUrl}/${id}`);

  const getPlanLookups = () => httpClient.get(`${productUrl}`);

  const updatePaymentDetails = (id, payload) =>
    httpClient.patch(`${myAccountRootUrl}/${id}/payment-details`, payload);

  const createNewSubscription = (payload) =>
    httpClient.post(`${apiRootUrl}`, payload);

  const updateSubscription = (id, payload) =>
    httpClient.patch(`${apiRootUrl}/${id}`, payload);

  const cancelSubscription = (id) =>
    httpClient.patch(`${manageSubsUrl}/cancel/${id}`);

  return {
    getAccountAndLookupsData,
    getAccountInfo,
    updatePaymentDetails,
    createNewSubscription,
    updateSubscription,
    cancelSubscription,
  };
};

export default ApiService;
