import React from "react";
import PropTypes from "prop-types";
import { ContentFx } from "components/common/content-fx";

function ContentPageWrapper({ children, containerCss }) {
  return (
    <section className={containerCss}>
      <ContentFx
        fxType="arc"
        options={{ color: "#f7f8f9", bgColor: "#fff" }}
        contentPadding="0"
      >
        {children}
      </ContentFx>
    </section>
  );
}

ContentPageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerCss: PropTypes.string,
};

ContentPageWrapper.defaultProps = {
  containerCss: "",
};

export default ContentPageWrapper;
