//import { combineReducers } from "redux";
import SystemReducer from "./system/system-reducer";
import ForgotPasswordReducer from "views/forgot-password/redux/reducer";
import ResetPasswordReducer from "views/reset-password/redux/reducer";
import AdminReducer from "views/admin/redux/reducer";
import SystemRealTimeCommsReducer from "./system-real-time-comms/reducer";
import FormBuilderUploaderReducer from "views/admin/form-builder-uploader/redux/reducer";
import FormBuilderReducer from "components/common/form-builder/redux/reducer";
import AccountPlanFormReducer from "views/account-plan-form/account-plan-form-slice";
import AccountCreditReducer from "views/account-credit-option/account-credit-option-slice";
import SignupProgressReducer from "./signup-progress/signup-progress-slice";
import AppSetupV2Reducer  from "views/app-setup-v2/app-setup-slice";
import AppSetupReducer from "views/app-setup/app-setup-slice";
import MyAccountReducer from "views/my-account/my-account-slice";
import MyAccountChangePasswordReducer from "views/my-account/containers/change-password/change-password-slice";
import MyAccountDetailsReducer from "views/my-account/containers/update-details/update-details-slice";
import MyAccountPaymentDetailsReducer from "views/my-account/containers/change-payment-details/change-payment-details-slice";
import TaxInvoicesReducer from "views/tax-invoice/tax-invoice-slice";
import ManageSubscriptionReducer from "views/manage-subscription/manage-subscription-slice";
import ManageSubscriptionPlanFormReducer from "views/manage-subscription/containers/plan-form/plan-form-slice";
import ManageSubscriptionCreditOptionReducer from "views/manage-subscription/containers/credit-option/credit-option-slice";
import ManageSubscriptionChangePaymentDetailsReducer from "views/manage-subscription/containers/change-payment-details/change-payment-details-slice";
import HomeReducer from "views/home-v2/home-slice";
import VipOfferReducer from "views/vip-offer/vip-offer-slice";
import ManageSubscriptionAdminReducer from "views/admin/manage-subscription/manage-subscription-admin-slice";
import ManageSubscriptionAdminCreateSubsReducer from "views/admin/manage-subscription/containers/create-subscription/create-subscription-slice";
import ManageSubscriptionAdminChangeSubsReducer from "views/admin/manage-subscription/containers/change-subscription/change-subscription-slice";
import ManageSubscriptionAdminPaymentDetailsReducer from "views/admin/manage-subscription/containers/change-payment-details/change-payment-details-slice";
import AccountFreeTrialOptionReducer from "views/account-free-trial-option/account-free-trial-option-slice";
import ManageClientsReducer from "views/admin/manage-clients/manage-clients-slice";
import ViewClientReducer from "views/admin/view-client/view-client-slice";
import CustomerInfoReducer from "views/admin/view-client/containers/customer-information/customer-info-slice";
import ClientSubscriptionReducer from "views/admin/view-client/containers/subscriptions/subscriptions-slice";
import TransactionLogsReducer from "views/admin/view-client/containers/transaction-logs/transaction-logs-slice";
import ClientTaxInvoiceReducer from "views/admin/view-client/containers/tax-invoice/tax-invoice-slice";
import ClientCommentsReducer from "views/admin/view-client/containers/comments/comments-slice";
import CreateInstanceReducer from "views/admin/manage-clients/dialogs/create-instance/create-instance-slice";
import CompanyContactsReducer from "views/admin/view-client/containers/company-contacts/company-contacts-slice";

const RootReducer = {
  system: SystemReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  admin: AdminReducer,
  formBuilderUploader: FormBuilderUploaderReducer,
  formBuilder: FormBuilderReducer, // Form Builder
  realTimeComms: SystemRealTimeCommsReducer,
  accountPlanForm: AccountPlanFormReducer,
  accountCreditOption: AccountCreditReducer,
  signupProgress: SignupProgressReducer,
  //appSetup: AppSetupReducer,
  appSetupV2: AppSetupV2Reducer,
  myAccount: MyAccountReducer,
  myAccountChangePassword: MyAccountChangePasswordReducer,
  myAccountDetails: MyAccountDetailsReducer,
  myAccountPaymentDetails: MyAccountPaymentDetailsReducer,
  taxInvoices: TaxInvoicesReducer,
  manageSubscription: ManageSubscriptionReducer,
  manageSubscriptionPlanForm: ManageSubscriptionPlanFormReducer,
  manageSubscriptionCreditOption: ManageSubscriptionCreditOptionReducer,
  manageSubscriptionChangePaymentDetails:
    ManageSubscriptionChangePaymentDetailsReducer,
  homePage: HomeReducer,
  vipOfferPage: VipOfferReducer,
  manageSubscriptionAdmin: ManageSubscriptionAdminReducer,
  manageSubscriptionAdminCreateSubs: ManageSubscriptionAdminCreateSubsReducer,
  manageSubscriptionAdminChangeSubs: ManageSubscriptionAdminChangeSubsReducer,
  manageSubscriptionAdminPaymentDetails:
    ManageSubscriptionAdminPaymentDetailsReducer,
  accountFreeTrialOption: AccountFreeTrialOptionReducer,
  manageClients: ManageClientsReducer,
  viewClient: ViewClientReducer,
  customerInfo: CustomerInfoReducer,
  clientSubscriptions: ClientSubscriptionReducer,
  transactionLogs: TransactionLogsReducer,
  clientTaxInvoices: ClientTaxInvoiceReducer,
  clientComments: ClientCommentsReducer,
  createInstance: CreateInstanceReducer,
  companyContacts: CompanyContactsReducer
};

export default RootReducer;
