//
// Feature details
// Note: This component can be link with ProductFeatures component. Check props of ProductFeatures.
// 20.01.2021 rcnet@solvable
//
import React from "react";
import PropTypes from "prop-types";
import "./feature-details.scss";
import FeatureDetailItem from "./feature-detail-item";

function FeatureDetails({ data, initialTextDirection, containerCss }) {
  const getDirection = (index) => {
    let oddDirection = initialTextDirection;
    let evenDirection = initialTextDirection === "left" ? "right" : "left";
    return index % 2 === 0 ? evenDirection : oddDirection;
  };

  return (
    <section className={containerCss}>
      {data.map((detail, index) => (
        <section id={detail.id} key={index}>
          <FeatureDetailItem
            detail={detail}
            direction={getDirection(index + 1)}
          />
        </section>
      ))}
    </section>
  );
}

FeatureDetails.propTypes = {
  data: PropTypes.array,
  initialTextDirection: PropTypes.string,
  containerCss: PropTypes.string,
};

FeatureDetails.defaultProps = {
  data: [],
  initialTextDirection: "left", // valid values: left or right
  containerCss: "",
};

export default FeatureDetails;
