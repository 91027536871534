export const attributes = [
  {
    name: "externalUserId",
    type: "string",
    description:
      "The Employee's forgein key/external system ID number. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "externalSkillId",
    type: "string",
    description:
      "The Licence, Skill or Qualification's forgein key/external system ID number. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "uploadedFiles",
    type: "array of EmployeeVocUpload",
    description:
      "A collection of the Licences, Skills, Qualifications or Supporting Documents uploaded by the Employee.",
    linkToTag: "#employee-voc-data-upload-object",
  },
  {
    name: "vocPdfBase64",
    type: "string",
    description:
      "The Verification of Competency (VOC) PDF file represented as a Base64 string (if applicable).",
  },
];
