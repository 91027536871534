import React from "react";
import validatePassword from "./components/password-validation";
import PasswordErrorsComponent from "./components/password-errors-component";
import Bs5FormInput from "components/common/form-fields/bs5-input/bs5-form-input";
import { useFormikContext } from "formik";

const Password = () => {
  const { values } = useFormikContext();

  return (
    <div>
      <Bs5FormInput
        size="xl"
        name="password"
        label="Password"
        placeholder="Password"
        textboxCss="text-box"
        type="password"
        showValidationMessage={false}
        validate={(val) => validatePassword(val, values.confirmPassword)}
      />

      <PasswordErrorsComponent />

      <Bs5FormInput
        size="xl"
        name="confirmPassword"
        label="Confirm Password"
        placeholder="Confirm Password"
        textboxCss="text-box"
        type="password"
        showValidationMessage={false}
      />
    </div>
  );
};

export default React.memo(Password);
