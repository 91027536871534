import { ActionTypes } from "./action-types";

const initialState = {
  loading: false,
  resetToken: null,
  isTokenValid: false,
  isValidating: false,
  errorMessage: null,
};

function ResetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        sent: false,
        errorMessage: null,
      };
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isTokenValid: false,
        resetToken: null,
        loading: false,
        errorMessage: null,
      };
    case ActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case ActionTypes.RESET_PASSWORD_CLEAN_UP:
      return initialState;
    case ActionTypes.VALIDATE_PASSWORD_RESET_TOKEN_REQUEST:
      return {
        ...state,
        isValidating: true,
        errorMessage: null,
      };
    case ActionTypes.VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        isTokenValid: true,
        resetToken: action.payload,
        isValidating: false,
        errorMessage: null,
      };
    case ActionTypes.VALIDATE_PASSWORD_RESET_TOKEN_FAILURE:
      return {
        ...state,
        isTokenValid: false,
        isValidating: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
}

export default ResetPasswordReducer;
