/**
 * HttpInterceptor abstraction
 * To encapsulate http provider details like FETCH Api or Axios.
 *
 * Provider: Axios
 * - rcnet @solvable
 */

import axios from "axios";
import Auth from "../auth/auth";
import postal from "postal";
import Config from "./../../config";

const AUTH_LOGIN_URL = "/login";
const AUTH_TOKEN_URL = "/v1/core/auth/token";
const MAINTENANCE_PAGE_URL = "/pages/maintenance";
const FORBIDDEN_PAGE_URL = "/pages/error-403";
const HTTP_TIMEOUT = 300000;

var authTokenRequestPromise;

const HttpInterceptor = () => {
  const api = axios.create({ baseURL: Config.apiUrl, timeout: HTTP_TIMEOUT });

  // By default interceptors were enabled, this will impact setAuthToken rendering it no effect at all.
  // Todo: Provide a way to enable /disable interceptor specially the auth alteration when setAuthToken method is called.
  const interceptorReqAuth = (config) => {
    const token = Auth.getToken();
    if (token != null) config.headers.Authorization = `Bearer ${token}`;

    return config;
  };

  // AuthTokenRequestPromise plays a very important role in this code base, it handles concurrent/parallel requests which puts into queue.
  // To prevent multiple refreshtoken request.
  const interceptorRespError = (error) => {
    const err = error.response;
    console.log(err);

    // Check if api server is down
    if (error.message === "Network Error" && err === undefined) {
      //historyObject.push(MAINTENANCE_PAGE_URL);

      _pubMessage("interceptor.navigate", {
        cmd: "navigate",
        data: MAINTENANCE_PAGE_URL,
      });

      return Promise.reject(error);
    }

    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
      console.log("Requesting new access token...");

      return _getAuthToken()
        .then((resp) => {
          Auth.setToken(
            resp.data.access_token,
            resp.data.refresh_token,
            resp.data.expires_in
          );

          //err.config.__isRetryRequest = true;
          err.config.headers.Authorization = `Bearer ${resp.data.access_token}`;

          console.log("Successfully requested access token...");

          return axios.request(err.config);
        })
        .catch((e) => {
          let ex = e.response;

          if (ex.status === 400 && ex.data && ex.data.invalid_refreshtoken) {
            console.log("Invalid refresh token!");
            console.log("Redirect to login.");

            Auth.removeToken();
            //historyObject.push(AUTH_LOGIN_URL);

            _pubMessage("interceptor.navigate", {
              cmd: "navigate",
              data: AUTH_LOGIN_URL,
            });
          }

          return Promise.reject({
            ...error,
            handled: true,
            handledBy: "interceptor",
          });
        });
    } else if (err.status === 403) {
      //historyObject.push(FORBIDDEN_PAGE_URL);

      _pubMessage("interceptor.navigate", {
        cmd: "navigate",
        data: FORBIDDEN_PAGE_URL,
      });

      let newErrObj = Object.assign({}, error);

      newErrObj.response.data["Message"] = "Unable to access page.";

      return Promise.reject(newErrObj);
    } else {
      return Promise.reject(error);
    }
  };

  /**
   * Request new Access Token
   */
  const _getAuthToken = () => {
    if (!authTokenRequestPromise) {
      const refreshTokenPayload = Auth.getRefreshTokenPayload(Config.clientId);

      // Perform dual lock to prevent thread Race Condition when requesting new Access Token.
      if (!authTokenRequestPromise) {
        console.log("Access token requested...");

        authTokenRequestPromise = api.post(AUTH_TOKEN_URL, refreshTokenPayload);

        authTokenRequestPromise
          .then(_resetAuthTokenRequestPromise)
          .catch(_resetAuthTokenRequestPromise);
      }
    }

    return authTokenRequestPromise;
  };

  /**
   * Reset singleton token promise
   */
  const _resetAuthTokenRequestPromise = () => (authTokenRequestPromise = null);

  /**
   * Publish data to message bus
   */
  const _pubMessage = (topic, data) => {
    postal.publish({
      channel: "httpInterceptorChannel",
      topic,
      data,
    });
  };

  return {
    interceptorReqAuth,
    interceptorRespError,
  };
};

export default HttpInterceptor;
