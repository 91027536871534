const applicantManagementLearnMoreData = [
  {
    id: "feature-applicant-management",
    heading:
      "Remove all manual processes and <span class='highlight-text'>Efficiently Manage Applications</span>",
    subHeading:
      "Say goodbye to manual processes and manage applications with ease. Our online application system allows prospective employees to apply from anywhere, on any device, streamlining the recruitment process. Our goal is to facilitate access to top talent and improve data accuracy and compliance. ",
    bulletPoints: [
      "Eliminate the need to follow up on paperwork",
      "An enjoyable application experience for candidates",
      "Automated email notifications for timely action.",
    ],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/manage-applicants.webp",
    imageAlt:
      "Manage Your Applications With Ease and remove all those manual processes",
    marginTop: "0",
  },
  {
    id: "add-applicants",
    heading: "<span class='highlight-text'>Add Applicants</span>",
    subHeadings: [
      "You can add your applicants via a simple entry form or allow candidates to sign up through your company website or online job advertisement via a unique link.",
      "An email will welcome each applicant, and direct them to a simple online application process. A stress-free, time efficient way to get started with your business.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/add-applicant.webp",
    imageAlt: "Add Applicants",
  },

  {
    id: "manage-applicants",
    heading: "<span class='highlight-text'>Manage Applicants</span>",
    subHeadings: [
      "Now you can view all your applicants in one place, track their status, and take action without facing a complex paper trail or complicated program.",
      "From personal details and qualifications to aptitude tests and work history, all relevant information is at your fingertips thanks to our streamlined system.",
      "Simply review their application, and when ready, progress the candidate to the next stage with a few easy clicks. What could be an easier process?",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/manage-applicants.webp",
    imageAlt: "Manage Applicants",
  },

  {
    id: "application-form",
    heading: "<span class='highlight-text'>Application Form</span>",
    subHeadings: [
      "Our simple application form includes the option of customisation, and has been designed by our team of experts to ensure the data entry process is a breeze on any device.",
      "Whether using our standard format or selecting the application questions that meet your needs, gather all the data you need to make the right hiring choice in just a few clicks.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/application-form.webp",
    imageAlt: "Application Form",
  },

  {
    id: "uploads",
    heading: "<span class='highlight-text'>Uploads</span>",
    subHeadings: [
      "Applicants can upload copies of licences and qualifications and provide relevant data for ease of validation. This capability greatly streamlines the selection process.",
      "Select your required skills or qualifications for each role and mark them as required or optional to ensure you are gathering the right skillsets that benefit your business.",
      "Data and uploads can be integrated with other systems where available removing additional data entry. The Staffd team can assist with any queries regarding integration options.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/view-uploads.webp",
    imageAlt: "Uploads",
  },

  {
    id: "progress-applications",
    heading: "<span class='highlight-text'>Progress Applications</span>",
    subHeadings: [
      "Receive automated emails alerting you of hold-points that allow for review and ability to progress the applicant to the next stage, or initiate rejection.",
      "Enjoy the capability to review application details in a simple online view. This way your company can make informed recruiting decisions in a few easy steps.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/progress-applications.webp",
    imageAlt: "Progress Applications",
  },
];

export default applicantManagementLearnMoreData;
