export const attributes = [
  {
    name: "id",
    type: "string",
    description:
      "The forgein key/external system ID number of the Branch / Depot / Location. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "value",
    type: "string",
    description: "The name of the Branch / Depot / Location.",
  },
];

export const payload = [
  { id: "1", value: "Dandenong" },
  { id: "2", value: "Glendenning" },
  { id: "3", value: "Springwood" },
];
