import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { addErrorNotification } from "redux/system/system-action-creators";
import ApiService from "./services/apiService";
import timeZoneData from "constants/data/time-zone-data";
import {
  getCurrentCountryLookupValue,
  getCurrentTimezoneLookupValue,
} from "./helpers/helper";
import { checkRedirectionRules } from "services/redirect-rules-service";
import { AccountSetupStatusTypes } from "constants/account-setup-status-types";
import countryData from "./../../constants/data/country-data";
import { AppUrls } from 'constants/app-urls';

const initialState = {
  timezoneLookups: timeZoneData,
  countryLookups: countryData,
  entryFormDefaults: {
    staffdUrl: "",
    invalidCode: false,
    firstName: "",
    lastName: "",
    companyName: "",
    invoicingEmail: "",
    timezone: "",
    country: "",
  },

  staffdUrlToUpdate: "",

  logoInfo: {
    processedImageBase64: "",
    width: "",
    height: "",
    hasBackgroundColour: false,
    bgColour: "",
  },

  oneTimeLinkRedirect: "",
  nextProcessRedirectTo: "",

  companyBusyMode: false,
  validateCodeBusyMode: false,
  processLogoBusyMode: false,
  loading: false,
  provisioning: false,
  provisioningFailed: false,
};

// ---------------
// Action Thunks
// ---------------
export const generateTenantCode = createAsyncThunk(
  "appSetup/generateTenantCode",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().generateCode(data);

      if (typeof result.data === "object") return "";

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to generate code."));
      throw err;
    }
  }
);

export const validateCode = createAsyncThunk(
  "appSetup/validateCode",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().validateCode(data);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to validate code."));
      throw err;
    }
  }
);

export const processLogo = createAsyncThunk(
  "appSetup/processLogo",
  async (data, thunkAPI) => {
    try {
      let payload = { base64Image: data.imageStr };
      let result = await ApiService().processLogo(payload);

      return {
        ...result.data,
        hasBackgroundColour: data.hasBackgroundColour,
        bgColour: data.bgColour,
      };
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to process image."));
      throw err;
    }
  }
);

export const provisionTenant = createAsyncThunk(
  "appSetup/provisionTenant",
  async (data, thunkAPI) => {
    let logoInfoState = getLogoInfo(thunkAPI.getState);

    let payload = {
      tenantCode: data.staffdUrl,
      timezoneId: data.timezone.value,
      companyName: data.companyName,
      companyPhoneNumber: data.companyPhoneNumber,
      adminUserFirstName: data.firstName,
      adminUserLastName: data.lastName,
      adminUserMobileNumber: data.mobileNumber,
      invoicingEmail: data.invoicingEmail,
      logoImage: data.logoImage.replace("data:image/png;base64,", ""),
      useLogoBackground: logoInfoState.hasBackgroundColour,
      logoBgColor: logoInfoState.bgColour,
      websiteUrl: data.websiteUrl,
      websiteContactUsUrl: data.contactUrl,
      onboardingFromEmail: data.onboardingEmail,
      onboardingAdminToEmail: data.onboardingAdminEmail,
      learningFromEmail: data.learningEmail,
      learningAdminToEmail: data.learningAdminEmail,
      countrySetupType: data.country.value,
    };

    let result = await ApiService().provisionTenant(payload);

    return result.data;
  }
);

export const initLoadData = createAsyncThunk(
  "appSetup/initLoadData",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().getInitialTenantInfo(data);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to load data."));
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const appSetupSlice = createSlice({
  name: "appSetup",
  initialState,
  reducers: {
    updateLogoInfo: (state, action) => {
      state.logoInfo.hasBackgroundColour = action.payload.hasBackgroundColour;
      state.logoInfo.bgColour = action.payload.bgColour;
    },
    hideProvisioningError: (state) => {
      state.provisioningFailed = false;
    },
    testToggle: (state, action) => {
      state.provisioning = !state.provisioning;
    },
  },
  extraReducers: (builder) => {
    //
    // generateTenantCode extra reducers
    //
    builder.addCase(generateTenantCode.pending, (state) => {
      state.companyBusyMode = true;
    });

    builder.addCase(generateTenantCode.fulfilled, (state, action) => {
      state.companyBusyMode = false;
      state.staffdUrlToUpdate = action.payload;
      //state.entryFormDefaults.staffdUrl = action.payload;
      state.entryFormDefaults.invalidCode = false;
    });

    builder.addCase(generateTenantCode.rejected, (state, action) => {
      state.companyBusyMode = false;
    });

    //
    // validateCode extra reducers
    //
    builder.addCase(validateCode.pending, (state) => {
      state.validateCodeBusyMode = true;
    });

    builder.addCase(validateCode.fulfilled, (state, action) => {
      state.validateCodeBusyMode = false;
      state.entryFormDefaults.invalidCode = !action.payload.isSuccess;
    });

    builder.addCase(validateCode.rejected, (state, action) => {
      state.validateCodeBusyMode = false;
    });

    //
    // processLogo extra reducers
    //
    builder.addCase(processLogo.pending, (state) => {
      state.processLogoBusyMode = true;
    });

    builder.addCase(processLogo.fulfilled, (state, action) => {
      state.logoInfo.processedImageBase64 = `data:image/png;base64,${action.payload.imageBase64}`;
      state.logoInfo.width = action.payload.width;
      state.logoInfo.height = action.payload.height;
      state.logoInfo.hasBackgroundColour = action.payload.hasBackgroundColour;
      state.logoInfo.bgColour = action.payload.bgColour;
      state.processLogoBusyMode = false;
    });

    builder.addCase(processLogo.rejected, (state) => {
      state.processLogoBusyMode = false;
    });

    //
    // provision tenant
    //
    builder.addCase(provisionTenant.pending, (state) => {
      state.provisioning = true;
    });

    builder.addCase(provisionTenant.fulfilled, (state, action) => {
      state.provisioning = false;
      state.oneTimeLinkRedirect = action.payload;
    });

    builder.addCase(provisionTenant.rejected, (state) => {
      state.provisioning = false;
      state.provisioningFailed = true;
    });

    //
    // get initial tenant
    //
    builder.addCase(initLoadData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(initLoadData.fulfilled, (state, action) => {
      if (action.payload.isAdmin) {
        state.nextProcessRedirectTo = AppUrls.ADMIN;
        return;
      }

      let redirecTo = checkRedirectionRules(
        action.payload.accountStatus.status
      );

      if (
        action.payload.accountStatus.status !==
        AccountSetupStatusTypes.SIGNUP_COMPLETED
      ) {
        state.nextProcessRedirectTo = redirecTo;
        return;
      }

      state.loading = false;
      state.entryFormDefaults.userId = action.payload.userId;
      state.entryFormDefaults.firstName = action.payload.firstName;
      state.entryFormDefaults.lastName = action.payload.lastName;
      state.entryFormDefaults.companyName = action.payload.companyName;
      state.entryFormDefaults.invoicingEmail = action.payload.email;
      state.entryFormDefaults.staffdUrl = action.payload.tenantCode;

      let tzLookups = state.timezoneLookups.map((o, i) => ({
        label: o.label,
        value: o.value,
      }));
      state.entryFormDefaults.timezone =
        getCurrentTimezoneLookupValue(tzLookups);

      let countryLookups = state.countryLookups.map((o, i) => ({
        label: o.label,
        value: o.value,
      }));
      state.entryFormDefaults.country =
        getCurrentCountryLookupValue(countryLookups);
    });

    builder.addCase(initLoadData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateLogoInfo, testToggle, hideProvisioningError } =
  appSetupSlice.actions;

export default appSetupSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.appSetup;

export const selectAppSetup = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

// -----------
// Helpers
// -----------
const getLogoInfo = (stateFn) => {
  return stateFn().appSetup.logoInfo;
};
