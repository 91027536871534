import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
function FeatureDetailItem({ detail, direction, containerCss }) {
  const {
    heading,
    subHeading,
    subHeadings,
    bulletPoints,
    imagePath,
    imageAlt,
    moreDetailsUrl,
    marginTop,
  } = detail;

  return (
    <>
      {direction === "left" ? (
        <section
          className={`features-details d-flex flex-lg-row flex-column justify-content-between align-items-center mt-${
            marginTop === undefined ? 6 : marginTop
          } ${containerCss}`}
        >
          <div className="feature-info">
            <div
              className="heading mb-4p5"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            {subHeadings !== undefined &&
              subHeadings.length > 0 &&
              subHeadings.map((subHeading, i) => (
                <div key={i} className="subHeading text-left mt-3">
                  {subHeading}
                </div>
              ))}
            {(subHeadings === undefined || subHeadings.length === 0) && (
              <div className="subHeading text-left mb-4p5">{subHeading}</div>
            )}
            <div>
              {bulletPoints !== undefined && bulletPoints.length > 0 && (
                <ul>
                  {bulletPoints.map((bulletText, index) => (
                    <li key={index}>
                      <i className="fas fa-check"></i>
                      {bulletText}
                    </li>
                  ))}
                </ul>
              )}

              {moreDetailsUrl && (
                <div className="mt-4 mb-4 mb-md-0">
                  <Link
                    className="btn sw-feature-details-btn btn-lg"
                    to={moreDetailsUrl}
                  >
                    Learn More
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="feature-image d-flex flex-row justify-content-lg-end justify-content-center">
            <img src={imagePath} alt={imageAlt} />
          </div>
        </section>
      ) : (
        <section
          className={`features-details d-flex flex-lg-row flex-column justify-content-between align-items-center mt-6 ${containerCss}`}
        >
          <div className="feature-image d-flex flex-row justify-content-lg-start justify-content-center order-lg-1 order-2">
            <img src={imagePath} alt={imageAlt} />
          </div>
          <div className="feature-info order-lg-2 order-1">
            <div
              className="heading mb-4p5"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            {subHeadings !== undefined &&
              subHeadings.length > 0 &&
              subHeadings.map((subHeading, i) => (
                <div key={i} className="subHeading text-left mt-3">
                  {subHeading}
                </div>
              ))}
            {(subHeadings === undefined || subHeadings.length === 0) && (
              <div className="subHeading text-left mb-4p5">{subHeading}</div>
            )}
            <div>
              {bulletPoints !== undefined && bulletPoints.length > 0 && (
                <ul>
                  {bulletPoints.map((bulletText, index) => (
                    <li key={index}>
                      <i className="fas fa-check"></i>
                      {bulletText}
                    </li>
                  ))}
                </ul>
              )}
              {moreDetailsUrl && (
                <div className="mt-4 mb-4 mb-md-0">
                  <Link
                    className="btn sw-feature-details-btn btn-lg"
                    to={moreDetailsUrl}
                  >
                    Learn More
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

FeatureDetailItem.propTypes = {
  detail: PropTypes.object,
  direction: PropTypes.string,
  containerCss: PropTypes.string,
};

FeatureDetailItem.defaultProps = {
  data: {},
  containerCss: "",
};

export default FeatureDetailItem;
