import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { noop } from "lodash";
import "./sign-in.scss";
import { ContentFx } from "components/common/content-fx";
import EntryForm from "./form/entry-form";

function SignIn({userName, validator, containerCss, onFormSubmit, ...rest }) {
  return (
    <section className={`sign-in-wrapper ${containerCss}`}>
      <ContentFx
        fxType="plain"
        options={{ bgColor: "transparent" }}
        contentPadding="6"
        overrideContentSectionCss={true}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="form-container">
            <div className="form-overlap-container"></div>
            <div className="form-inner-container">
              <h3 className="display-1 mb-4">Sign in</h3>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: userName ? userName : "",
                  password: "",
                }}
                validationSchema={validator}
                onSubmit={onFormSubmit}
              >
                {({ handleSubmit, isSubmitting, ...formRest }) => (
                  <>
                    <EntryForm
                      {...formRest}
                      {...rest}
                      onSubmit={handleSubmit}
                    />
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </ContentFx>
    </section>
  );
}

SignIn.propTypes = {
  userName: PropTypes.string,
  validator: PropTypes.object,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  containerCss: PropTypes.string,
  onFormSubmit: PropTypes.func,
};

SignIn.defaultProps = {
  loading: false,
  containerCs: "",
  onFormSubmit: noop,
};

export default SignIn;
