import React from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import { useFormikContext, useField } from "formik";
import ValidationMessage from "../validation-message/validation-message";
import MatSelectField from "./mat-select-field";

function MatFormSelect({ name, label, ...rest }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name });

  const handleChange = (value) => {
    setFieldValue(name, value);
  };

  return (
    <FormGroup>
      <MatSelectField
        {...rest}
        name={name}
        label={label}
        value={field.value}
        onChange={handleChange}
      />
      <ValidationMessage name={name} />
    </FormGroup>
  );
}

MatFormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

MatFormSelect.defaultProps = {
  onChange: () => {},
};

export default MatFormSelect;
