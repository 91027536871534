import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { addNotification } from "redux/system/system-action-creators";
import { useDispatch } from "react-redux";

const PingSubscriber = ({ addSubscriber, invoke }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Define SignalR listeners
    addSubscriber("ReceivePingClient", (message) => {
      dispatch(addNotification(message));
    });

    addSubscriber("PingPongClient", (message) => {
      dispatch(addNotification(message));

      // Invoke message from server
      setTimeout(() => invoke("SendPing"), 2000);
    });
  }, []);

  return <section></section>;
};

PingSubscriber.propTypes = {
  addSubscriber: PropTypes.func,
  invoke: PropTypes.func,
};

PingSubscriber.defaultProps = { addSubscriber: noop, invoke: noop };

export default PingSubscriber;
