import React, { useEffect } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import loadable from "@loadable/component";
import BlockUiFx from "components/common/block-ui-fx";
import { PageScrollToTop } from "components/common/page-scroll-to-top";
import "./login-layout.scss";
import PrivateRoute from "components/common/private-route";
import loginRoutes from "routes/login-routes";

function LoginLayout() {
  const NavBar = loadable(() => import("components/nav-bar/sign-up-navbar"));

  useEffect(() => {
    document.body.classList.add("body-dark-mode");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentRouteLoader = () => {
    return (
      <div className="login-layout-wrapper">
        <Switch>
          {loginRoutes.map((prop, key) => {
            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;

            if (prop.privateRoute) {
              return (
                <PrivateRoute
                  exact
                  path={prop.path}
                  key={key}
                  claimsAuthorisationHook={prop.claimsAuthorisationHook}
                  component={prop.component}
                  loginUrl={prop.loginUrl}
                  {...prop}
                />
              );
            }

            return (
              <Route
                path={prop.path}
                component={prop.component}
                key={key}
                exact={prop.exact}
              />
            );
          })}
        </Switch>
      </div>
    );
  };

  return (
    <BlockUiFx blocking={false} containerCss="block-ui-style">
      <NavBar navType="transparent" />
      <section className="content-wrapper">
        <main role="main">{componentRouteLoader()}</main>
      </section>
      <PageScrollToTop />
    </BlockUiFx>
  );
}

export default LoginLayout;
