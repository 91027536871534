export const attributes = [
  { name: "id", type: "string", description: "Id of the Applicant in Staffd." },
  {
    name: "firstName",
    type: "string",
    description: "The First Name of the Applicant.",
  },
  {
    name: "lastName",
    type: "string",
    description: "The Last Name of the Applicant.",
  },
  {
    name: "email",
    type: "string",
    description: "The Email Address of the Applicant.",
  },
  {
    name: "mobile",
    type: "string",
    description: "The Mobile Phone Number of the Applicant.",
  },
  {
    name: "dateOfBirth",
    type: "DateTime (nullable)",
    description: "The Date of Birth of the Applicant in ISO format.",
  },
  {
    name: "gender",
    type: "GenderType (nullable)",
    description:
      "The Gender of the Applicant expressed as the integer value of the GenderType enum.",
    linkToTag: "#gender-type",
  },
  {
    name: "pronouns",
    type: "PronounsType (nullable)",
    description:
      "The Pronouns of the Applicant expressed as the integer value of the PronounsType enum.",
    linkToTag: "#pronouns-type",
  },
  {
    name: "socialInclusions",
    type: "array of SocialInclusionType",
    description:
      "The Social Inclusions of the Applicant expressed as an array of the integer values of the SocialInclusionType enum.",
    linkToTag: "#social-inclusion-type",
  },
  {
    name: "positionApplied",
    type: "string",
    description:
      "The name or ID of the Position / Role the Applicant applied for. By default, the Position / Role name will be provided, however, your Staffd instance can be configured to use the Employee Classifications / Types Lookup to provide you back your forgein key/external system ID number of the Employee Classification.",
  },
  {
    name: "depot",
    type: "string",
    description:
      "The name or ID of the Branch / Depot / Location (if applicable) the Applicant applied for. By default, the Branch / Depot / Location name will be provided, however, your Staffd instance can be configured to use the Branches / Depots / Locations Lookup to provide you back your forgein key/external system ID number of the Branch / Depot / Location.",
  },
  {
    name: "homeUnitNumber",
    type: "string",
    description: "The Applicant's Home Address - Unit Number.",
  },
  {
    name: "homeStreetNumber",
    type: "string",
    description: "The Applicant's Home Address - Street Number.",
  },
  {
    name: "homeStreetName",
    type: "string",
    description: "The Applicant's Home Address - Street Name.",
  },
  {
    name: "homeCity",
    type: "string",
    description: "The Applicant's Home Address - City/Town/Suburb.",
  },
  {
    name: "homeState",
    type: "string",
    description: "The Applicant's Home Address - State.",
  },
  {
    name: "homePostCode",
    type: "string",
    description: "The Applicant's Home Address - Postcode.",
  },
  {
    name: "homeFullAddress",
    type: "string",
    description: "The Applicant's Home Address - Full Address.",
  },
  {
    name: "postalUnitNumber",
    type: "string",
    description: "The Applicant's Postal Address - Unit Number.",
  },
  {
    name: "postalStreetNumber",
    type: "string",
    description: "The Applicant's Postal Address - Street Number.",
  },
  {
    name: "postalStreetName",
    type: "string",
    description: "The Applicant's Postal Address - Street Name.",
  },
  {
    name: "postalCity",
    type: "string",
    description: "The Applicant's Postal Address - City/Town/Suburb.",
  },
  {
    name: "postalState",
    type: "string",
    description: "The Applicant's Postal Address - State.",
  },
  {
    name: "postalPostCode",
    type: "string",
    description: "The Applicant's Postal Address - Postcode.",
  },
  {
    name: "postalFullAddress",
    type: "string",
    description: "The Applicant's Postal Address - Full Address.",
  },
  {
    name: "emergencyContactFirstName",
    type: "string",
    description: "The Applicant's Emergency Contact - First Name.",
  },
  {
    name: "emergencyContactLastName",
    type: "string",
    description: "The Applicant's Emergency Contact - Last Name.",
  },
  {
    name: "emergencyContactRelationship",
    type: "string",
    description: "The Applicant's Emergency Contact - Relationship.",
  },
  {
    name: "emergencyContactPhone1",
    type: "string",
    description: "The Applicant's Emergency Contact - Contact Phone Number.",
  },
  {
    name: "emergencyContactPhone2",
    type: "string",
    description:
      "The Applicant's Emergency Contact - Alternative Contact Phone Number.",
  },
  {
    name: "emergencyAltContactFirstName",
    type: "string",
    description: "The Applicant's Alternative Emergency Contact - First Name.",
  },
  {
    name: "emergencyAltContactLastName",
    type: "string",
    description: "The Applicant's Alternative Emergency Contact - Last Name.",
  },
  {
    name: "emergencyAltContactRelationship",
    type: "string",
    description:
      "The Applicant's Alternative Emergency Contact - Relationship.",
  },
  {
    name: "emergencyAltContactPhone1",
    type: "string",
    description:
      "The Applicant's Alternative Emergency Contact - Contact Phone Number.",
  },
  {
    name: "emergencyAltContactPhone2",
    type: "string",
    description:
      "The Applicant's Alternative Emergency Contact - Alternative Contact Phone Number.",
  },
  {
    name: "photoUrl",
    type: "string",
    description:
      "The Uri of the Uploaded Photo/Headshot of the Applicant (if applicable).",
  },
  {
    name: "uploads",
    type: "array of SkillUpload",
    description:
      "A collection of the Licences, Skills or Qualifications uploaded by the Applicant.",
    linkToTag: "#skill-upload-object",
  },
];
