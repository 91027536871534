import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit'
import {
  addErrorNotification,
  addNotification,
} from 'redux/system/system-action-creators'
import ApiService from './services/apiService'

const initialState = {
  entities: [],
  tags: [],
  settings: {},

  showArchived: false,
  loading: false,
}

// --------------
// Action Thunks
// --------------
export const initLoadData = createAsyncThunk(
  'manageClients/initLoadData',
  async (_, thunkAPI) => {
    try {
      let apis = [ApiService().getClients(), ApiService().getTags()]
      let result = await Promise.all(apis)

      return result
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, 'Unable to fetch data.'))
      throw err
    }
  },
)

export const deleteClient = createAsyncThunk(
  'manageClients/deleteClient',
  async (id, thunkAPI) => {
    try {
      await ApiService().deleteClient(id)

      thunkAPI.dispatch(
        addNotification('Client has been successfully deleted.'),
      )

      return id
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, 'Unable to delete client.'))
      throw err
    }
  },
)

export const undeleteClient = createAsyncThunk(
  'manageClients/undeleteClient',
  async (id, thunkAPI) => {
    try {
      await ApiService().undeleteClient(id)

      thunkAPI.dispatch(
        addNotification('Client has been successfully undeleted.'),
      )

      return id
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, 'Unable to undelete client.'))
      throw err
    }
  },
)

export const saveTags = createAsyncThunk(
  'manageClients/saveTags',
  async (clientTagsStr, thunkAPI) => {
    try {
      let result = await ApiService().saveTags(clientTagsStr)

      thunkAPI.dispatch(
        addNotification('You have successfully saved the tags.'),
      )

      return result.data
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, 'Unable to save the tags.'))
      throw err
    }
  },
)

export const attachTags = createAsyncThunk(
  'manageClients/attachTags',
  async (data, thunkAPI) => {
    try {
      await ApiService().attachTags(data)

      thunkAPI.dispatch(addNotification('You have saved the client tags.'))

      return data
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, 'Unable to save the client tags.'),
      )
      throw err
    }
  },
)

// ---------------
// Slice
// ---------------
export const manageClientsSlice = createSlice({
  name: 'manageClients',
  initialState,
  reducers: {
    keepDataTableSettings: (state, action) => {
      state.settings = action.payload
    },
    showArchived: (state, action) => {
      state.showArchived = action.payload
    },
  },
  extraReducers: (builder) => {
    //
    // initLoadData
    //
    builder.addCase(initLoadData.pending, (state) => {
      state.loading = true
    })

    builder.addCase(initLoadData.fulfilled, (state, action) => {
      state.loading = false

      let mappedTags = action.payload[1].data.clientTags.map((x) => ({
        value: x.id,
        label: x.value,
      }));

      state.tags = mappedTags;

      state.entities = action.payload[0].data.map(x => {
        if(x.clientTagIds.length > 0){
          return {
            ...x,
            clientTags: mappedTags.filter(tag => x.clientTagIds.includes(tag.value)).map(tag => tag.label).sort().join(", ")
          }
        }

        return {
          ...x,
          clientTags: ""
        }
      })
    })

    builder.addCase(initLoadData.rejected, (state) => {
      state.loading = false
    })

    //
    // deleteClient
    //
    builder.addCase(deleteClient.pending, (state) => {
      state.loading = true
    })

    builder.addCase(deleteClient.fulfilled, (state, action) => {
      state.loading = false
      let client = state.entities.find((f) => f.id === action.payload)
      client.isArchived = true
    })

    builder.addCase(deleteClient.rejected, (state) => {
      state.loading = false
    })

    //
    // undeleteClient
    //
    builder.addCase(undeleteClient.pending, (state) => {
      state.loading = true
    })

    builder.addCase(undeleteClient.fulfilled, (state, action) => {
      state.loading = false
      let client = state.entities.find((f) => f.id === action.payload)
      client.isArchived = false
    })

    builder.addCase(undeleteClient.rejected, (state) => {
      state.loading = false
    })

    //
    // Save tags
    //
    builder.addCase(saveTags.pending, (state) => {
      state.loading = true
    })

    builder.addCase(saveTags.fulfilled, (state, action) => {
      state.loading = false
      state.tags = action.payload.clientTags.map((x) => ({
        value: x.id,
        label: x.value,
      }))
    })

    builder.addCase(saveTags.rejected, (state) => {
      state.loading = false
    })

    //
    // Attach Client tags
    //
    builder.addCase(attachTags.pending, (state) => {
      state.loading = true
    })

    builder.addCase(attachTags.fulfilled, (state, action) => {
      state.loading = false
      state.entities = state.entities.map((x) =>
        x.id === action.payload.clientId
          ? { ...x, clientTagIds: action.payload.clientTags }
          : x,
      )
    })

    builder.addCase(attachTags.rejected, (state) => {
      state.loading = false
    })
  },
})

export const {
  keepDataTableSettings,
  showArchived,
} = manageClientsSlice.actions

export default manageClientsSlice.reducer

// ----------------
// Selectors
// ----------------
const selectSelf = (state) => state.manageClients

export const selectManageClients = createDraftSafeSelector(
  selectSelf,
  (state) => state,
)

export const selectArchivedClientItems = createDraftSafeSelector(
  selectSelf,
  (state) => {
    return state.showArchived
      ? state.entities
      : state.entities.filter((f) => !f.isArchived)
  },
)
