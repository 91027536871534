import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
  } from "@reduxjs/toolkit";
  import {
    addErrorNotification,
    addNotification,
  } from "redux/system/system-action-creators";
  import ApiService from "./../../services/apiService";
  
  const initialState = {
    entities: [],
    settings: {},
  
    loading: false,
  };
  
  // --------------
  // Action Thunks
  // --------------
  export const loadData = createAsyncThunk(
    "viewClients/transactionLogs/loadData",
    async (id, thunkAPI) => {
      try {
        let result = await ApiService().getTransactionLogs(id);
        return result.data;
      } catch (err) {
        thunkAPI.dispatch(addErrorNotification(err, "Unable to fetch data."));
        throw err;
      }
    }
  );
  
  // ---------------
  // Slice
  // ---------------
  export const transactionLogsSlice = createSlice({
    name: "viewClients/transactionLogs",
    initialState,
    reducers: {
      keepDataTableSettings: (state, action) => {
        state.settings = action.payload;
      },
    },
    extraReducers: (builder) => {
      //
      // initLoadData
      //
      builder.addCase(loadData.pending, (state) => {
        state.loading = true;
      });
  
      builder.addCase(loadData.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      });
  
      builder.addCase(loadData.rejected, (state) => {
        state.loading = false;
      });
    },
  });
  
  export const { keepDataTableSettings } = transactionLogsSlice.actions;
  
  export default transactionLogsSlice.reducer;
  
  // ----------------
  // Selectors
  // ----------------
  const selectSelf = (state) => state.transactionLogs;
  
  export const selectTransactionLogs = createDraftSafeSelector(
    selectSelf,
    (state) => state
  );
  