/**
 * ContentFx
 * - Content section with a style
 * 01.02.2022 rcnet@solvable
 * Valid fxType = curve, angle, linear, arc, waveCurve and plain
 **/
import React from "react";
import PropTypes from "prop-types";
import "./content-fx.scss";
import ContentFxFactory from "./layouts/content-fx-factory";
import BlockUiFx from "components/common/block-ui-fx";
import PageTitle from "components/common/page-title";

function ContentFx({
  id,
  children,
  loading,
  containerCss,
  fxType,
  options,
  contextFxCss,
  pageTitle,
  showPageTitleInContent,
  ...rest
}) {
  return (
    <div id={id} className={contextFxCss}>
      <BlockUiFx blocking={loading}>
        {pageTitle && <PageTitle title={pageTitle} />}
        <ContentFxFactory
          {...rest}
          type={fxType}
          options={options}
          containerCss={containerCss}
        >
          {pageTitle && showPageTitleInContent && <h2>{pageTitle}</h2>}
          {children}
        </ContentFxFactory>
      </BlockUiFx>
    </div>
  );
}

ContentFx.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerCss: PropTypes.string,
  fxType: PropTypes.string, // Effects type (curve, angle etc...)
  options: PropTypes.object,
  loading: PropTypes.bool,
  contentPadding: PropTypes.string, // Bootstrap padding, valid values:  "0, 1, 2, 3, 4, 5, 6"
  overrideContentSectionCss: PropTypes.bool, // If you want to remove content-section styles from "text-styles.scss"
  pageTitle: PropTypes.string,
  showPageTitleInContent: PropTypes.bool,
  contextFxCss: PropTypes.string,
};

ContentFx.defaultProps = {
  containerCss: "",
  contextFxCss: "content-fx",
  showPageTitleInContent: true,
};

export default ContentFx;
