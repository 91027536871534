import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/manage-subscriptions";
  let myAccountRootUrl = "/v1/my-account";

  const getAccountInfo = (id) => httpClient.get(`${myAccountRootUrl}/${id}`);

  const updatePaymentDetails = (id, payload) =>
    httpClient.patch(`${myAccountRootUrl}/${id}/payment-details`, payload);

  const getUpgradeDowngradeStatus = (id) =>
    httpClient.get(`${apiRootUrl}/confirmation-message/${id}`);

  const createNewSubscription = (payload) =>
    httpClient.post(`${apiRootUrl}/renew`, payload);

  const updateSubscription = (id, payload) =>
    httpClient.patch(`${apiRootUrl}/update/${id}`, payload);

  const cancelSubscription = (id) =>
    httpClient.patch(`${apiRootUrl}/cancel/${id}`);

  return {
    getAccountInfo,
    updatePaymentDetails,
    getUpgradeDowngradeStatus,
    createNewSubscription,
    updateSubscription,
    cancelSubscription,
  };
};

export default ApiService;
