export const attributes = [
  {
    name: "externalId",
    type: "string (required)",
    description:
      "The forgein key/external system ID number of the Employee. Values can include integers and GUIDs represented as a string value.",
  },
  {
    name: "firstName",
    type: "string (required)",
    description: "The First Name of the Employee.",
  },
  {
    name: "lastName",
    type: "string (required)",
    description: "The Last Name of the Employee.",
  },
  {
    name: "email",
    type: "string (required)",
    description: "The Email Address of the Employee.",
  },
  {
    name: "mobile",
    type: "string (required)",
    description: "The Mobile Phone Number of the Employee.",
  },
  {
    name: "employeeClassification",
    type: "string (optional)",
    description: "The Employee Classification Type of the Employee.",
  },
  {
    name: "isActive",
    type: "bool (required)",
    description:
      "Indicates whether or not the Employee is currently active and can access Staffd.",
  },
];

export const payload = [
  {
    externalId: "7743edd5-b8e7-4976-aa39-08d944211970",
    firstName: "Billy",
    lastName: "Bens",
    email: "bill@gnsnail.com",
    mobile: "0498 765 432",
    employeeClassification: "Casual - Traffic Controller",
    isActive: true,
  },
  {
    externalId: "ddb48e5a-e907-4812-83c7-08d95e0fd07f",
    firstName: "David",
    lastName: "Digger",
    email: "diggerdave@holes.com",
    mobile: "0432 128 128",
    employeeClassification: "Labourer",
    isActive: true,
  },
  {
    externalId: "f1322ce8-2e89-484b-aa38-08d944211970",
    firstName: "Fred",
    lastName: "Flintstone",
    email: "fred@gnsnail.com",
    mobile: "0493 433 333",
    employeeClassification: "Casual - Traffic Controller",
    isActive: true,
  },
  {
    externalId: "168b75bc-8692-4809-d8ed-08d95d40b5db",
    firstName: "Jimmy",
    lastName: "McGill",
    email: "jimmy@gnsnail.com",
    mobile: "0412 981 761",
    employeeClassification: "Casual - Traffic Controller",
    isActive: true,
  },
  {
    externalId: "a7f90939-622f-4dab-2d07-08da138fdd20",
    firstName: "Marty",
    lastName: "Martian",
    email: "marty@gnsnail.com",
    mobile: "0499 999 444",
    employeeClassification: "Casual - Traffic Controller",
    isActive: true,
  },
  {
    externalId: "7668ab6c-f63a-4182-83b0-08da2880d980",
    firstName: "Peter",
    lastName: "Pan",
    email: "peter@panland.com",
    mobile: "0449 938 833",
    employeeClassification: "Casual - Traffic Controller",
    isActive: true,
  },
  {
    externalId: "f42c6981-37b0-44a0-55cc-08d9f1176158",
    firstName: "Ryan",
    lastName: "Reynolds",
    email: "ryan@gnsnail.com",
    mobile: "0412 871 123",
    employeeClassification: "Casual - Traffic Controller",
    isActive: true,
  },
  {
    externalId: "81706d5d-4121-4127-2d7c-08d96cd9c32c",
    firstName: "Walter",
    lastName: "White",
    email: "chem@gnsnail.com",
    mobile: "0444 444 444",
    employeeClassification: "Plant Operator",
    isActive: true,
  },
];
