import React from "react";
import PropTypes from "prop-types";
import ContentSection from "components/common/content-section";
import "./linear.scss";

function Linear({
  children,
  containerCss,
  color,
  bgColor,
  contentPadding,
  ...rest
}) {
  return (
    <div className={`linear-content-fx ${containerCss}`}>
      <ContentSection
        {...rest}
        specificClass="content-container"
        containerStyle={{ backgroundColor: color }}
        padding={contentPadding}
      >
        {children}
      </ContentSection>
    </div>
  );
}

Linear.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerCss: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  contentPadding: PropTypes.string, // Bootstrap padding, valid values:  "0, 1, 2, 3, 4, 5"
};

Linear.defaultProps = {
  containerCss: "",
  color: "#f7f8f9",
  bgColor: "#fff",
  contentPadding: "5",
};

export default Linear;
