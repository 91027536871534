// Helper functions

import { PlanTerms } from "constants/plan-terms";
import { PlanType } from "constants/plan-type";

export const getPlanType = (key) => {
  return key === "basic"
    ? PlanType.BASIC
    : key === "standard"
    ? PlanType.STANDARD
    : key === "standardplusintegrations"
    ? PlanType.STANDARD_PLUS_INTEGRATIONS
    : PlanType.PREMIUM;
};

export const getPlanTerms = (term) => {
  return term.toLowerCase() === "monthly"
    ? PlanTerms.MONTHLY
    : PlanTerms.YEARLY;
};
