import React from "react";
import useScrollNavigate from "hooks/use-scroll-navigate";

const ApiLinks = ({ links }) => {
  const { scrollToSectionOffset } = useScrollNavigate(false, 100);

  const handleScroll = (el) => {
    scrollToSectionOffset(el, 100, null, true);
  };

  return (
    <ul>
      {links.map((link, i) => (
        <li key={i}>
          <a
            onClick={(e) => {
              handleScroll(link.tag);
              e.preventDefault();
            }}
            href={`/api-documentation/webhooks/${link.tag}`}
          >
            {link.text}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default ApiLinks;
