/**
 * System-wide Action types
 */
export const ActionTypes = {
  // Notifications
  SYS_ADD_NOTIFICATION: "ADD_NOTIFICATION",
  SYS_ADD_ERROR_NOTIFICATION: "ADD_NOTIFICATION",

  // Error Handler
  SYS_HANDLE_ERROR: "HANDLE_ERROR",
  SYS_HANDLE_ERROR_SHOW_FORM: "SYS_HANDLE_ERROR_SHOW_FORM",
  SYS_HANDLE_ERROR_HIDE_FORM: "SYS_HANDLE_ERROR_HIDE_FORM",

  // Profile
  SYS_GET_PROFILE_REQUEST: "SYS_GET_PROFILE_REQUEST",
  SYS_GET_PROFILE_SUCCESS: "SYS_GET_PROFILE_SUCCESS",
  SYS_GET_PROFILE_FAILURE: "SYS_GET_PROFILE_FAILURE",
  SYS_HIDE_SPLASH: "SYS_HIDE_SPLASH",

  SYS_UPDATE_PROFILE_REQUEST: "SYS_UPDATE_PROFILE_REQUEST",
  SYS_UPDATE_PROFILE_SUCCESS: "SYS_UPDATE_PROFILE_SUCCESS",
  SYS_UPDATE_PROFILE_FAILURE: "SYS_UPDATE_PROFILE_FAILURE",

  SYS_GET_FEATURE_FLAG_SUCCESS: "SYS_GET_FEATURE_FLAG_SUCCESS",
  SYS_GET_FEATURE_FLAG_FAILURE: "SYS_GET_FEATURE_FLAG_FAILURE",

  SYS_TOGGLE_LOG_OUT: "SYS_TOGGLE_LOG_OUT",
  SYS_UPDATE_PROFILE: "SYS_UPDATE_PROFILE",

  SYS_SHOW_SYSTEM_WIDE_MESSAGE: "SYS_SHOW_SYSTEM_WIDE_MESSAGE",
  SYS_HIDE_SYSTEM_WIDE_MESSAGE: "SYS_HIDE_SYSTEM_WIDE_MESSAGE",
};
