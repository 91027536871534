export const initialState = {
  filterData: {},
  keepSettings: {},
};

export const dataTableReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FILTER_DATA":
      let dataForUpdate = deepClone(state);
      dataForUpdate.filterData[action.id] =
        dataForUpdate.filterData[action.id] ?? {};
      dataForUpdate.filterData[action.id][action.fieldKey] = action.value;
      return dataForUpdate;

    case "KEEP_SETTINGS":
      let dataForUpdateSettings = deepClone(state);
      dataForUpdateSettings.keepSettings[action.id] =
        dataForUpdateSettings.keepSettings[action.id] ?? {};
      dataForUpdateSettings.keepSettings[action.id] = action.settings;
      return dataForUpdateSettings;

    default:
      return state;
  }
};

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
