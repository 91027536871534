import React from "react";
import { Row, Col } from "reactstrap";
import "./api-enum.scss";

const ApiEnum = ({ enums, tag }) => {
  return (
    <Row className="api-enum">
      <Col>
        {enums.map((attr, i) => (
          <div key={i} className="api-enum-item">
            <span className="api-enum-item-value">{attr.value}</span>
            <span className="api-enum-item-description">
              {attr.description}
            </span>
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default ApiEnum;
