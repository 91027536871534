import SignInView from "views/sign-in/sign-in-view";
import ContentPageView from "views/content-page/content-page-view";
import ForgotPassword from "views/forgot-password";
import ResetPassword from "views/reset-password";

var loginRoutes = [
  { path: "/login/", component: SignInView, exact: true },
  { path: "/forgot-password/", component: ForgotPassword, exact: true },
  { path: "/reset-password/", component: ResetPassword, exact: true },
  // Catch all Route
  {
    path: "*",
    component: ContentPageView,
    privateRoute: false,
  },
];

export default loginRoutes;
