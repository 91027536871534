/**
 * Configure additional middleware here
 */
import Config from "config";
import SignalRManager from "core/real-time-comms/signalr-manager";
import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./root-reducer";

const ConfigureStore = () => {
  // Setup SignalR manager
  const signalRManager = SignalRManager.getInstance(
    Config.modules.signalR.hubUrl,
    {
      logLevel: Config.modules.signalR.logLevel,
      autoReconnectSettings: Config.modules.signalR.autoReconnectSettings,
      disabled: Config.modules.signalR.disabled,
    }
  );

  const store = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: signalRManager,
        },
      }),
  });

  return store;

  //return createStore(RootReducer, compose(...enhancers));

  // // Grab the state from a global variable injected into the server-generated HTML
  // const preloadedState = window.__PRELOADED_STATE__;

  // // Allow the passed state to be garbage-collected
  // delete window.__PRELOADED_STATE__;

  // // Create Redux store with initial state
  // const store = createStore(
  //   RootReducer,
  //   preloadedState || ,
  //   compose(...enhancers)
  // );

  // // Tell react-snap how to save Redux state
  // window.snapSaveState = () => ({
  //   __PRELOADED_STATE__: store.getState()
  // });

  // return store;
};

export default ConfigureStore;
