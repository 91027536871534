import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as SignalRActions from "redux/system-real-time-comms/action-creators";

const Hub = ({ enableLogging, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SignalRActions.startConnection({ enableLogging }));
  }, []);

  const addSubscriber = (name, subscriptionFn) => {
    dispatch(SignalRActions.addSubscriber(name, subscriptionFn));
  };

  const invoke = (methodName, payload) =>
    dispatch(SignalRActions.invoke(methodName, payload));

  return <section>{children({ addSubscriber, invoke })}</section>;
};

Hub.propTypes = {
  enableLogging: PropTypes.bool,
};

Hub.defaultProps = { enableLogging: false };

export default Hub;
