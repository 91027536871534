import { css } from "@emotion/core";
import { BarLoader } from "react-spinners";
import { PropTypes } from "prop-types";

const override = css`
  position: absolute;
  bottom: 0;
  left: 0px;
  display: inline-block;
  margin: 0 auto;
`;

const relativeLayoutOverride = css`
  position: relative;
  display: inline-block;
  margin: 0 auto;
`;

function ActivityIndicator({
  color,
  size,
  width,
  containerCss,
  isLayoutRelative,
}) {
  return (
    <section className={`${containerCss}`}>
      <BarLoader
        css={isLayoutRelative ? relativeLayoutOverride : override}
        sizeUnit={"px"}
        size={size}
        width={width}
        color={color}
        loading={true}
      />
    </section>
  );
}

ActivityIndicator.propTypes = {
  size: PropTypes.number,
  isLayoutRelative: PropTypes.bool,
  width: PropTypes.number,
  color: PropTypes.string,
};

ActivityIndicator.defaultProps = {
  size: 10,
  width: 60,
  isLayoutRelative: false,
  color: "#2fc3f2",
};

export default ActivityIndicator;
