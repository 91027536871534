import { HttpClient } from "core/http";

const ApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/admin-manage-clients";
  let taxApiUrl = "/v1/tax-invoices";

  const getCustomerInformation = (id) => httpClient.get(`${apiRootUrl}/${id}`);

  const updateCustomerInformation = (id, payload) =>
    httpClient.patch(`${apiRootUrl}/${id}/update-customer-info`, payload);

  const getClientSubscriptions = (id) =>
    httpClient.get(`${apiRootUrl}/${id}/subscriptions`);

  const getTransactionLogs = (id) =>
    httpClient.get(`${apiRootUrl}/${id}/transaction-logs`);

  const getTaxInvoices = (id) => httpClient.get(`${taxApiUrl}/${id}`);

  const downloadPdf = (id) =>
    httpClient.get(`${taxApiUrl}/${id}/download`, {
      responseType: "blob",
    });

  return {
    getCustomerInformation,
    updateCustomerInformation,
    getClientSubscriptions,
    getTransactionLogs,
    getTaxInvoices,
    downloadPdf,
  };
};

export default ApiService;
