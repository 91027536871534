import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import planFormData from "constants/data/plan-form-data";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import { getPlanTerms, getPlanType } from "services/plan-value-mapping";
import ApiService from "../../services/apiService";
import UserIdentity from "core/auth/userIdentity";

const initialState = {
  activeData: planFormData[0],
  selectedPlanData: "basic",
  selectedPlanTerms: "select-opt1",
  showPaymentModule: false,
  upgradeDowngrade: {
    showModal: false,
    message: "",
    typeOfSubscription: "",
  },
  //redirectTo: null,
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
export const checkUpgradeDowngradeStatus = createAsyncThunk(
  "manageSubscription/planForm/checkUpgradeDowngradeStatus",
  async (data, thunkAPI) => {
    try {
      const { productCode } = selectSelectedPlanInfo(thunkAPI.getState());

      let result = await ApiService().getUpgradeDowngradeStatus(productCode);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to check subscription status.")
      );
      throw err;
    }
  }
);

export const upgradeDowngrade = createAsyncThunk(
  "manageSubscription/planForm/upgradeDowngrade",
  async (data, thunkAPI) => {
    try {
      const { productCode } = selectSelectedPlanInfo(thunkAPI.getState());

      let payload = {
        productCode: productCode,
      };

      await ApiService().updateSubscription(
        UserIdentity().getUserId(),
        payload
      );

      thunkAPI.dispatch(
        addNotification("Subscription has been successfully updated.")
      );

      thunkAPI.dispatch(clearStates());

      data.upgradeDowngradeCompleted();

      return true;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to update subscription.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const manageSubscriptionPlanFormSlice = createSlice({
  name: "manageSubscription/planForm",
  initialState,
  reducers: {
    changeActiveData: (state, action) => {
      state.activeData =
        action.payload === "monthly" ? planFormData[0] : planFormData[1];
    },
    changeSelectedPlanData: (state, action) => {
      state.selectedPlanData = action.payload;
    },
    changeDefaultPlan: (state, action) => {
      let selectedPlan = planFormData.reduce((acc, curr) => {
        let plan = curr.planSelectors.find(
          (f) => f.productCode === action.payload
        );
        if (plan != null) {
          acc.activeData = { ...curr };
          acc.selectedPlanData = plan.key;
          return acc;
        }

        return acc;
      }, {});

      if (selectedPlan == null) return;

      state.activeData = selectedPlan.activeData;
      state.selectedPlanData = selectedPlan.selectedPlanData;
      state.selectedPlanTerms =
        selectedPlan.activeData.term === "Monthly"
          ? "select-opt1"
          : "select-opt2";
    },
    createNewSubscription: (state) => {
      state.showPaymentModule = true;
    },
    closeUpgradeDowngradeConfirmationModal: (state) => {
      state.upgradeDowngrade = initialState.upgradeDowngrade;
      state.showPaymentModule = false;
    },
    showPlanForm: (state) => {
      state.showPaymentModule = false;
    },
    clearStates: (state) => {
      state.showPaymentModule = initialState.showPaymentModule;
      state.activeData = initialState.activeData;
      state.selectedPlanData = initialState.selectedPlanData;
      state.selectedPlanTerms = initialState.selectedPlanTerms;
      state.showPaymentModule = initialState.showPaymentModule;
      state.upgradeDowngrade = initialState.upgradeDowngrade;
    },
  },
  extraReducers: (builder) => {
    //
    // Upgrade / Downgrade Status
    //
    builder.addCase(checkUpgradeDowngradeStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkUpgradeDowngradeStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.upgradeDowngrade.showModal = true;
      state.upgradeDowngrade.message = action.payload.message;
      state.upgradeDowngrade.typeOfSubscription =
        action.payload.typeOfSubscription;
    });
    builder.addCase(checkUpgradeDowngradeStatus.rejected, (state) => {
      state.loading = false;
    });

    //
    // Perform Upgrade / Downgrade
    //
    builder.addCase(upgradeDowngrade.pending, (state) => {
      state.loading = true;
      state.upgradeDowngrade.showModal = false;
    });
    builder.addCase(upgradeDowngrade.fulfilled, (state, action) => {
      state.loading = false;
      state.showPaymentModule = false;
      state.upgradeDowngrade = initialState.upgradeDowngrade;
    });
    builder.addCase(upgradeDowngrade.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeActiveData,
  changeSelectedPlanData,
  changeDefaultPlan,
  createNewSubscription,
  closeUpgradeDowngradeConfirmationModal,
  showPlanForm,
  clearStates,
} = manageSubscriptionPlanFormSlice.actions;

export default manageSubscriptionPlanFormSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.manageSubscriptionPlanForm;

export const selectManageSubscriptionPlanForm = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

export const selectSelectedPlanInfo = createDraftSafeSelector(
  selectSelf,
  (state) => {
    let planState = state.activeData;
    // let priceRow = planState.features.find((f) => {
    //   return f["isPriceRow"] || false;
    // });

    //let price = priceRow[state.selectedPlanData];
    let planSelector = state.activeData.planSelectors.find(
      (f) => f.key === state.selectedPlanData
    );

    let planName = planSelector?.name;
    let productCode = planSelector?.productCode;

    return {
      price: planSelector.price,
      term: planState.term,
      priceText: `${planSelector.price}/${
        planState.term === "Monthly" ? "month" : "year"
      }`,
      plan: `${planName} Plan`,
      planTerms: getPlanTerms(planState.term),
      planType: getPlanType(state.selectedPlanData),
      productCode,
    };
  }
);

export const selectSelectedPlanProductCode = createDraftSafeSelector(
  selectSelf,
  (state) => {
    let planSelector = state.activeData.planSelectors.find(
      (f) => f.key === state.selectedPlanData
    );
    return planSelector?.productCode;
  }
);
