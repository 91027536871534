import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit'
import {
  addErrorNotification,
  addNotification,
} from 'redux/system/system-action-creators'
import {
  getCurrentCountryLookupValue,
  getCurrentTimezoneLookupValue,
} from './../../../../app-setup/helpers/helper'
import timeZoneData from 'constants/data/time-zone-data'
import countryData from 'constants/data/country-data'
import TenantApiService from '../../../../app-setup-v2/services/apiService'
import { toIsoDate } from 'core/utilities/date-service'

const initialState = {
  formValues: {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    companyName: '',
    mobileNumber: '',
    companyPhoneNumber: '',
    notificationsEmail: '',
    staffdUrl: '',
    logoImage: '',
    hasLogoUpload: false,
    timezone: '',
    country: '',
    isInvoiced: false, 
    nextBillingDate: null
  },
  loading: false,
  progressMessage: '',
  isValidatingCode: false,
  processLogoBusyMode: false,
  logoInfo: {
    processedImageBase64: '',
    width: '',
    height: '',
    hasBackgroundColour: false,
    bgColour: '',
  },
}

// --------------
// Action Thunks
// --------------
export const createNewTenantAsAdmin = createAsyncThunk(
  'createInstance/createNewTenantAsAdmin',
  async (data, thunkAPI) => {
    try {
      let state = thunkAPI.getState()
      let logoInfo = state.createInstance.logoInfo
      let country = getCurrentCountryLookupValue(countryData)
      let timezone = getCurrentTimezoneLookupValue(timeZoneData)

      let payload = {
        email: data.email,
        password: data.password,
        companyName: data.companyName,
        firstName: data.firstName,
        lastName: data.lastName,
        mobileNumber: data.mobileNumber,
        tenantCode: data.staffdUrl,
        notificationsEmail: data.notificationsEmail,
        timezoneId: timezone.value,
        companyPhoneNumber: data.companyPhoneNumber,
        logoImage: logoInfo.processedImageBase64,
        useLogoBackground: logoInfo.hasBackgroundColour,
        logoBgColor: logoInfo.hasBackgroundColour ? logoInfo.bgColour : '',
        countrySetupType: country.value,
        isInvoiced: data.isInvoiced,
        nextBillingDate: toIsoDate(data.nextBillingDate)
      }

      let result = await TenantApiService().createAndProvisionTenantAsAdmin(
        payload
      )

      showSuccessMessage(
        thunkAPI.dispatch,
        'Successfully created, subscribed, and provisioned the new tenant.',
      )

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(
          err,
          'Sorry, we were unable to finish creating the new tenant.',
        ),
      )
      return false
    }
  },
)

export const validateCode = createAsyncThunk(
  'createInstance/validateCode',
  async (data, thunkAPI) => {
    try {
      let result = await TenantApiService().validateCode(data)
      return result.data
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, 'Unable to validate code.'))
      throw err
    }
  },
)

export const processLogo = createAsyncThunk(
  'createInstance/processLogo',
  async (data, thunkAPI) => {
    try {
      let payload = { base64Image: data.imageStr }
      let result = await TenantApiService().processLogo(payload)

      return {
        ...result.data,
        hasBackgroundColour: data.hasBackgroundColour,
        bgColour: data.bgColour,
      }
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, 'Unable to process image.'))
      throw err
    }
  },
)

const showSuccessMessage = (dispatch, message) => {
  dispatch(addNotification(message))
}

// ---------------
// Slice
// ---------------
export const manageClientsSlice = createSlice({
  name: 'createInstance',
  initialState,
  reducers: {
    updateLogoInfo: (state, action) => {
      state.logoInfo.hasBackgroundColour = action.payload.hasBackgroundColour
      state.logoInfo.bgColour = action.payload.bgColour
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewTenantAsAdmin.pending, (state) => {
      state.loading = true
      state.progressMessage = 'Creating the tenant'
    })

    builder.addCase(createNewTenantAsAdmin.fulfilled, (state) => {
      state.loading = false
      state.progressMessage = ''
    })

    builder.addCase(createNewTenantAsAdmin.rejected, (state) => {
      state.loading = false
      state.progressMessage = ''
    })

    builder.addCase(validateCode.pending, (state) => {
      state.isValidatingCode = true
    })

    builder.addCase(validateCode.fulfilled, (state) => {
      state.isValidatingCode = false
    })

    builder.addCase(validateCode.rejected, (state) => {
      state.isValidatingCode = false
    })

    builder.addCase(processLogo.pending, (state) => {
      state.processLogoBusyMode = true
    })

    builder.addCase(processLogo.fulfilled, (state, action) => {
      state.logoInfo.processedImageBase64 = action.payload.imageBase64;
      state.logoInfo.width = action.payload.width
      state.logoInfo.height = action.payload.height
      state.logoInfo.hasBackgroundColour = action.payload.hasBackgroundColour
      state.logoInfo.bgColour = action.payload.bgColour
      state.processLogoBusyMode = false
    })

    builder.addCase(processLogo.rejected, (state) => {
      state.processLogoBusyMode = false
    })
  },
})

export const { updateLogoInfo } = manageClientsSlice.actions

export default manageClientsSlice.reducer

// ----------------
// Selectors
// ----------------
const selectSelf = (state) => state.createInstance

export const selectCreateInstance = createDraftSafeSelector(
  selectSelf,
  (state) => state,
)
