const ActionTypes = {
  FORM_DETAILS_GET_SUCCESS: "FORM_DETAILS_GET_SUCCESS",
  FORM_DETAILS_SAVE_SUCCESS: "FORM_DETAILS_SAVE_SUCCESS",
  FORM_OPERATE_REQUEST: "FORM_OPERATE_REQUEST",
  FORM_OPERATE_FAILURE: "FORM_OPERATE_FAILURE",

  FORM_OPERATE_SHOW_LOADING: "FORM_OPERATE_SHOW_LOADING",
  FORM_OPERATE_HIDE_LOADING: "FORM_OPERATE_HIDE_LOADING",

  FORM_DETAILS_CLEAN_UP: "FORM_DETAILS_CLEAN_UP",
};

export default ActionTypes;
