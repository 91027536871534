import { HttpClient } from "core/http";

const CommentApiService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/comments";

  const addComment = (payload) => httpClient.post(`${apiRootUrl}`, payload);

  const getComments = (id) => httpClient.get(`${apiRootUrl}/${id}`);

  const getCommentById = (id) => httpClient.get(`${apiRootUrl}/${id}/comment`);

  const deleteComment = (id) => httpClient.patch(`${apiRootUrl}/delete/${id}`);

  return {
    addComment,
    getComments,
    getCommentById,
    deleteComment,
  };
};

export default CommentApiService;
