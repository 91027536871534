import { HttpClient } from "core/http";

const CompanyContactService = () => {
  let httpClient = HttpClient();
  let apiRootUrl = "/v1/company-contacts";

  const deleteTenantCompanyContact = (payload) =>
    httpClient.post(`${apiRootUrl}/archive`, payload);

  const getTenantCompanyContacts = (id) =>
    httpClient.get(`${apiRootUrl}/${id}`);

  const addTenantCompanyContact = (payload) => {
    return httpClient.post(`${apiRootUrl}/`, payload);
  };

  const updateTenantCompanyContact = (payload) => {
    httpClient.put(`${apiRootUrl}/`, payload);
  };

  return {
    getTenantCompanyContacts,
    addTenantCompanyContact,
    updateTenantCompanyContact,
    deleteTenantCompanyContact,
  };
};

export default CompanyContactService;
