import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./services/apiService";
import UserIdentity from "core/auth/userIdentity";
import * as ManageSubscriptionPlanFormSlice from "./containers/plan-form/plan-form-slice";
import { AppUrls } from "constants/app-urls";
import { AccountSetupStatusTypes } from "constants/account-setup-status-types";

const initialState = {
  accountInformation: {
    userId: "",
    companyName: "",
    firstName: "",
    lastName: "",
    companyPhoneNumber: "",
    invoiceEmail: "",
    mobileNumber: "",
    cardLast4Digit: "",
    cardExpiryDate: "",
    accountSetupStatus: "",
    subscriptionStatus: "",
    hasActiveSubscription: null,
    subscribedProductCode: "",
    subscribedProductName: "",
    subscribedPlanTerms: "",
    subscribedPlanType: "",
    nextBillingDateText: "",
    isSpecialRate: false,
  },

  showChangeSubscription: false,
  showCancelSubscription: false,
  showChangePaymentDetails: false,
  redirectNow: null,
  loading: false,
  initLoading: true,
};

// ---------------
// Action Thunks
// ---------------
export const getAccountInfo = createAsyncThunk(
  "manageSubscription/getAccountInfo",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().getAccountInfo(
        UserIdentity().getUserId()
      );
      
      if (result.data.subscribedProductCode && !result.data.isSpecialRate) {
        // Select subscribed product in plan form selector component
        thunkAPI.dispatch(
          ManageSubscriptionPlanFormSlice.changeDefaultPlan(
            result.data.subscribedProductCode
          )
        );
      }

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch account information.")
      );
      throw err;
    }
  }
);

export const cancelSubscriptionNow = createAsyncThunk(
  "manageSubscription/cancelSubscriptionNow",
  async (data, thunkAPI) => {
    try {
      await ApiService().cancelSubscription(UserIdentity().getUserId());

      thunkAPI.dispatch(
        addNotification("Subscription has been successfully cancelled.")
      );

      thunkAPI.dispatch(getAccountInfo());

      return true;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch account information.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const manageSubscriptionSlice = createSlice({
  name: "manageSubscription",
  initialState,
  reducers: {
    changeSubscription: (state, action) => {
      state.showChangeSubscription = action.payload;
    },
    changeSubscriptionCompleted: (state) => {
      state.showChangeSubscription = false;
    },
    cancelSubscription: (state, action) => {
      state.showCancelSubscription = action.payload;
    },
    changePaymenDetails: (state, action) => {
      state.showChangePaymentDetails = action.payload;
    },
    clearStates: (state) => {
      state.showChangeSubscription = initialState.showChangeSubscription;
    },
    clearRedirectStates: (state) => {
      state.redirectNow = null;
    },
  },
  extraReducers: (builder) => {
    //
    // Get Account Info
    //
    builder.addCase(getAccountInfo.pending, (state) => {
      state.initLoading = true;
    });
    builder.addCase(getAccountInfo.fulfilled, (state, action) => {
      if (action.payload.isAdmin) {
        state.redirectNow = AppUrls.ADMIN;
        return;
      }

      if (
        action.payload.accountSetupStatus !==
        AccountSetupStatusTypes.TENANT_SETUP_COMPLETED
      ) {
        state.redirectNow = AppUrls.SIGNUP;
        return;
      }

      state.initLoading = false;
      state.accountInformation = action.payload;
    });
    builder.addCase(getAccountInfo.rejected, (state) => {
      state.loading = false;
    });

    //
    // Cancel Subscription
    //
    builder.addCase(cancelSubscriptionNow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(cancelSubscriptionNow.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(cancelSubscriptionNow.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeSubscription,
  changeSubscriptionCompleted,
  cancelSubscription,
  changePaymenDetails,
  clearStates,
  clearRedirectStates,
} = manageSubscriptionSlice.actions;

export default manageSubscriptionSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.manageSubscription;

export const selectManageSubscription = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
