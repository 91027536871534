import React, { createContext, useReducer } from "react";
import { dataTableReducer, deepClone, initialState } from "./store";

export const DataTableContext = createContext();

const DataTableProvider = ({ children }) => {
  const [dataTableState, dispatch] = useReducer(dataTableReducer, initialState);

  const persistSessionData = (id, fieldKey, value) =>
    dispatch({ type: "UPDATE_FILTER_DATA", id, fieldKey, value });

  const getFilterData = () => deepClone(dataTableState.filterData);

  const handleKeepSettings = (id, settings) =>
    dispatch({ type: "KEEP_SETTINGS", id, settings });

  const getSettings = (id) =>
    dataTableState.keepSettings[id]
      ? deepClone(dataTableState.keepSettings[id])
      : {};

  return (
    <DataTableContext.Provider
      value={{
        getFilterData,
        persistSessionData,
        handleKeepSettings,
        getSettings,
      }}
    >
      {children}
    </DataTableContext.Provider>
  );
};

export default DataTableProvider;
