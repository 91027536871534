const Config = {
  appVersion: "1.0",
  appAuthStorageKey: "staffd-web-auth",
  defaultPageTitle: "Staffd",
  apiUrl: process.env.REACT_APP_API_URL,
  clientId: "0bad66b8d0244394beeb726b8843f206",
  gtmId: process.env.REACT_APP_GTM_ID,
  tenantUiUrl: process.env.REACT_APP_TENANT_UI_URL,
  tenantStaffdDomain: process.env.REACT_APP_STAFFD_DOMAIN,
  locale: "en-au",
  currency: "AUD",

  modules: {
    signalR: {
      hubUrl: process.env.REACT_APP_SIGNALR_HUB,
      logLevel: "info",
      autoReconnectSettings: [0, 2000, 10000, 20000, 30000, 20000],
      disabled: true,
      allowAutoRefresh: true,
      refreshUIMessage:
        "Live updates have disconnected due to inactivity. Refreshing...",
      connectionLostMessage: `Connection lost due to error. Reconnecting...`,
    },
  },
};

export default Config;
