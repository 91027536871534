import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import ConfigureStore from "./redux/configure-store";
import HydrateStore from "./redux/hydrate-store";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";
import * as config from "./config";
import { loadableReady } from "@loadable/component";

if (
  config.default.gtmId !== "" &&
  config.default.gtmId !== "GTM-XXXXXXX" &&
  config.default.gtmId !== "GTM-XXXXXXX"
) {
  const tagManagerArgs = {
    gtmId: config.default.gtmId,
  };

  TagManager.initialize(tagManagerArgs);
}

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  const hyrdatedStore = HydrateStore();
  loadableReady(() => {
    hydrate(
      <Provider store={hyrdatedStore}>
        <App />
      </Provider>,
      rootElement
    );
  });
} else {
  const store = ConfigureStore();
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}

serviceWorker.unregister();
