import { ActionTypes } from "./action-types";

const initialState = {
  loading: false, // For update when loading data
  error: {},
  implementationMessages: "",
};

function FormBuilderUploaderReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FORM_BUILDER_UPLOADER_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
        implementationMessages: "",
      };

    case ActionTypes.FORM_BUILDER_UPLOADER_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        implementationMessages: action.payload,
      };

    case ActionTypes.FORM_BUILDER_UPLOADER_SAVE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ActionTypes.FORM_BUILDER_UPLOADER_CLEAN_UP:
      return {
        ...state,
        loading: false,
        error: {},
        implementationMessages: "",
      };

    default:
      return state;
  }
}

export default FormBuilderUploaderReducer;

// ----------------------------------------------------
//  Selectors
// ----------------------------------------------------
export const selectFormBuilderUploader = (state) => state.formBuilderUploader;
