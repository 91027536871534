import React from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import BgContent from "components/features/bg-content/bg-content";
import { SignIn } from "components/features/sign-in";
import useSigninAccount from "./hooks/useSigninAccount";
import useSignupProgressState from "hooks/use-signup-progress";
import { SignInValidator } from "./validators/sign-in-validator";
import { AppUrls } from "constants/app-urls";
import checkRedirectionRules from "services/redirect-rules-service";

function SignInView() {
  //const { changeLayout } = useContext(SignupContext);
  const history = useHistory();
  const location = useLocation();
  const { signInAsync, errorMessage, isLoading } = useSigninAccount();

  const { checkSignupProgressLoading, checkAndUpdateSignupProgressStatus } =
    useSignupProgressState();

  const handleSignIn = async (values) => {
    const result = await signInAsync(values.email, values.password);

    if (result.isSuccess) {

      checkAndUpdateSignupProgressStatus(values.email);

      if (location.state) {
        history.push(location.state.from);
      } else {
        if (result.loginResult.profile.userType === 3) {
          // admin user
          history.push(AppUrls.ADMIN);
          return;
        }else{
          history.push("/", { initialLoginRoute: true});
        }
      }
    }
  };

  if (checkSignupProgressLoading) return <></>;

  return (
    <BgContent>
      <SignIn
        userName={location?.state?.from?.state?.emailToLogin}
        validator={SignInValidator}
        loading={isLoading}
        errorMessage={errorMessage}
        onFormSubmit={handleSignIn}
      />
    </BgContent>
  );
}

export default SignInView;
