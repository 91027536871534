import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { toShortDate } from "core/utilities/date-service";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./services/apiService";

const initialState = {
  planLookups: [],

  subscriptionFormDefaults: {
    defaultSelectedPlan: null,
    nextBillingDate: "",
  },

  accountInformation: {
    userId: "",
    companyName: "",
    firstName: "",
    lastName: "",
    companyPhoneNumber: "",
    invoiceEmail: "",
    mobileNumber: "",
    cardLast4Digit: "",
    cardExpiryDate: "",
    accountSetupStatus: "",
    subscriptionStatus: "",
    hasActiveSubscription: null,
    subscribedProductCode: "",
    subscribedProductName: "",
    subscribedPlanTerms: "",
    subscribedPlanType: "",
    nextBillingDateText: "",
    nextBillingDate: "",
  },

  showCreateSubscription: false,
  showChangeSubscription: false,
  showCancelSubscription: false,
  showChangePaymentDetails: false,
  redirectNow: null,
  loading: false,
  initLoading: true,
};

// ---------------
// Action Thunks
// ---------------
export const initLoadData = createAsyncThunk(
  "manageSubscriptionAdmin/initLoadData",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().getAccountAndLookupsData(data);

      if (result.data.accountInfo.subscribedProductCode) {
        // Select subscribed product in plan form selector component
        thunkAPI.dispatch(
          setDefaultPlanSelected({
            planLookups: result.data.planLookups,
            subscribedProductCode:
              result.data.accountInfo.subscribedProductCode,
          })
        );
      }

      thunkAPI.dispatch(
        setDefaultNextBillingDate(result.data.accountInfo.nextBillingDate)
      );

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch initial load data.")
      );
      throw err;
    }
  }
);

export const getAccountInfo = createAsyncThunk(
  "manageSubscriptionAdmin/getAccountInfo",
  async (userId, thunkAPI) => {
    try {
      let result = await ApiService().getAccountInfo(userId);

      if (result.data.subscribedProductCode) {
        // Select subscribed product in plan form selector component
        thunkAPI.dispatch(
          setDefaultPlanSelected({
            planLookups:
              thunkAPI.getState().manageSubscriptionAdmin.planLookups,
            subscribedProductCode: result.data.subscribedProductCode,
          })
        );
      }

      thunkAPI.dispatch(setDefaultNextBillingDate(result.data.nextBillingDate));

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch account information.")
      );
      throw err;
    }
  }
);

export const cancelSubscriptionNow = createAsyncThunk(
  "manageSubscriptionAdmin/cancelSubscriptionNow",
  async (userId, thunkAPI) => {
    try {
      await ApiService().cancelSubscription(userId);

      thunkAPI.dispatch(
        addNotification("Subscription has been successfully cancelled.")
      );

      thunkAPI.dispatch(clearStates());

      thunkAPI.dispatch(getAccountInfo(userId));

      return true;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch account information.")
      );
      throw err;
    }
  }
);


// ------------
// Slice
// ------------
export const manageSubscriptionAdminSlice = createSlice({
  name: "manageSubscriptionAdmin",
  initialState,
  reducers: {
    createSubscription: (state, action) => {
      state.showCreateSubscription = action.payload;
    },
    changeSubscription: (state, action) => {
      state.showChangeSubscription = action.payload;
    },
    changeSubscriptionCompleted: (state) => {
      state.showChangeSubscription = false;
    },
    cancelSubscription: (state, action) => {
      state.showCancelSubscription = action.payload;
    },
    changePaymenDetails: (state, action) => {
      state.showChangePaymentDetails = action.payload;
    },
    setDefaultPlanSelected: (state, action) => {
      let matchedPlan = action.payload.planLookups.find(
        (f) => f.value === action.payload.subscribedProductCode
      );

      if (!matchedPlan) return;

      state.subscriptionFormDefaults.defaultSelectedPlan = matchedPlan;
    },
    setDefaultNextBillingDate: (state, action) => {
      state.subscriptionFormDefaults.nextBillingDate =
        action.payload == null ? "" : toShortDate(action.payload);
    },
    clearStates: (state) => {
      state.showChangeSubscription = initialState.showChangeSubscription;
      state.showCreateSubscription = initialState.showCreateSubscription;
      state.showChangeSubscription = initialState.showChangeSubscription;
      state.showCancelSubscription = initialState.showCancelSubscription;
      state.showChangePaymentDetails = initialState.showChangePaymentDetails;
      state.subscriptionFormDefaults = initialState.subscriptionFormDefaults;
    },
    clearRedirectStates: (state) => {
      state.redirectNow = null;
    },
  },
  extraReducers: (builder) => {
    //
    // Get Account Info
    //
    builder.addCase(getAccountInfo.pending, (state) => {
      state.initLoading = true;
    });
    builder.addCase(getAccountInfo.fulfilled, (state, action) => {
      state.initLoading = false;
      state.accountInformation = action.payload;
    });
    builder.addCase(getAccountInfo.rejected, (state) => {
      state.loading = false;
    });

    //
    // initLoadData
    //
    builder.addCase(initLoadData.pending, (state) => {
      state.initLoading = true;
    });
    builder.addCase(initLoadData.fulfilled, (state, action) => {
      state.initLoading = false;
      state.accountInformation = action.payload.accountInfo;
      state.planLookups = action.payload.planLookups;
    });
    builder.addCase(initLoadData.rejected, (state) => {
      state.initLoading = false;
    });

    //
    // Cancel Subscription
    //
    builder.addCase(cancelSubscriptionNow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(cancelSubscriptionNow.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(cancelSubscriptionNow.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  createSubscription,
  changeSubscription,
  changeSubscriptionCompleted,
  cancelSubscription,
  changePaymenDetails,
  setDefaultPlanSelected,
  setDefaultNextBillingDate,
  clearStates,
  clearRedirectStates,
} = manageSubscriptionAdminSlice.actions;

export default manageSubscriptionAdminSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.manageSubscriptionAdmin;

export const selectManageSubscriptionAdmin = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
