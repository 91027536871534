/**
 * Configure additional middleware here
 */
import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./root-reducer";
import SignalRManager from "core/real-time-comms/signalr-manager";
import Config from "config";

const HydrateStore = () => {
  // Setup SignalR manager
  const signalRManager = SignalRManager.getInstance(
    Config.modules.signalR.hubUrl,
    {
      logLevel: Config.modules.signalR.logLevel,
      autoReconnectSettings: Config.modules.signalR.autoReconnectSettings,
      disabled: Config.modules.signalR.disabled,
    }
  );

  // Grab the state from a global variable injected into the server-generated HTML
  const preloadedState = window.__PRELOADED_STATE__;

  // Allow the passed state to be garbage-collected
  delete window.__PRELOADED_STATE__;

  const store = configureStore({
    reducer: RootReducer,
    preloadedState: preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: signalRManager,
        },
      }),
  });

  return store;
};

export default HydrateStore;
