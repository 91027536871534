import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./../../services/apiService";
import UserIdentity from "core/auth/userIdentity";
import Auth from "core/auth/auth";

const initialState = {
  loading: false,
  redirectTo: "",
};

// ---------------
// Action Thunks
// ---------------
export const updateDetails = createAsyncThunk(
  "myAccount/details/update",
  async (data, thunkAPI) => {
    try {
      const {
        firstName,
        lastName,
        companyName,
        mobileNumber,
        companyPhoneNumber,
        invoiceEmail,
        loginEmail,
      } = data.values;

      let payload = {
        firstName,
        lastName,
        companyName,
        mobileNumber,
        companyPhoneNumber,
        invoiceEmail,
        loginEmail,
      };

      let resp = await ApiService().updateDetails(
        UserIdentity().getUserId(),
        payload
      );

      if (resp.data !== "jwt-reissue-false") {
        Auth.setToken(
          resp.data.access_token,
          resp.data.refresh_token,
          resp.data.expires_in
        );
      }

      thunkAPI.dispatch(
        addNotification("Company details has been successfully updated.")
      );

      data.onUpdateSuccess();

      return true;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to update details."));
      throw err;
    } finally {
      data.setSubmitting(false); // Formik form progress
    }
  }
);

// ------------
// Slice
// ------------
export const myAccountDetailsSlice = createSlice({
  name: "myAccount/details",
  initialState,
  extraReducers: (builder) => {
    //
    // Update details
    //
    builder.addCase(updateDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateDetails.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default myAccountDetailsSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.myAccountDetails;

export const selectUpdateDetails = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
