import { ActionTypes } from "./action-types";
import HttpService from "../services/service";
import { addNotification } from "./../../../redux/system/system-action-creators";

const httpService = HttpService();

export const requestChangePassword = (email) => (dispatch) => {
  dispatch(requestChangePasswordRequest());
  httpService
    .requestChangePassword(email)
    .then(() => {
      dispatch(requestChangePasswordSuccess());
    })
    .catch((err) => {
      dispatch(requestChangePasswordFailure(err));
    });
};

const requestChangePasswordRequest = () => ({
  type: ActionTypes.FORGOT_PASSWORD_REQUEST,
});

const requestChangePasswordSuccess = () => ({
  type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
});

const requestChangePasswordFailure = (err) => (dispatch) => {
  dispatch({
    type: ActionTypes.FORGOT_PASSWORD_FAILURE,
    payload: err.response.status === 500 ? err.response.data.Message : "Sorry, an unexpected error occurred.",
  });
};

export const cleanUp = () => ({
  type: ActionTypes.FORGOT_PASSWORD_CLEAN_UP,
});
