import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import Bs5Input from "./bs5-input";
import ValidationMessage from "../validation-message/validation-message";
import "./bs5-form-input.scss";
import { noop } from "lodash";

function Bs5FormInput({
  name,
  showValidationMessage,
  validate,
  onBlur,
  ...rest
}) {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [field] = useField({ name, validate });

  const handleOnBlur = () => {
    setFieldTouched(name, true);
    onBlur(field.value);
  };

  return (
    // <FormGroup className="bs5-form-input-wrapper w-100">
    <div className="bs5-form-input-wrapper w-100">
      <Bs5Input
        {...rest}
        name={name}
        value={field.value}
        onChange={(value) => {
          setFieldValue(name, value);
        }}
        onBlur={handleOnBlur}
      />
      {showValidationMessage && <ValidationMessage name={name} />}
    </div>
    // </FormGroup>
  );
}

Bs5FormInput.propTypes = {
  name: PropTypes.string,
  busyMode: PropTypes.bool,
  showValidationMessage: PropTypes.bool,
  validate: PropTypes.func,
  onBlur: PropTypes.func,
};

Bs5FormInput.defaultProps = {
  showValidationMessage: true,
  onBlur: noop,
};

export default Bs5FormInput;
