import React from "react";
import PropTypes from "prop-types";
import "./bs5-input.scss";
import { noop } from "lodash";
import ActivityIndicator from "../activity-indicator/activity-indicator";
import { Input } from "reactstrap";

const Bs5Input = ({
  type,
  name,
  label,
  value,
  placeholder,
  size,
  containerCss,
  textboxCss,
  withBorderRadius,
  disabled,
  busyMode,
  onChange,
  onBlur,
  onEnterKey,
  maxLength,
  ...rest
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const getSize = () => {
    switch (size) {
      case "xl":
        return "form-control-xl";
      case "lg":
        return "form-control-lg";
      case "sm":
        return "form-control-sm";

      default:
        return "form-control-md";
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      onEnterKey();
      event.preventDefault();
    }
  };

  return (
    <section className={`bs5-input-wrapper ${containerCss}`}>
      <div className="form-label-group">
        <Input
          id={name}
          type={type}
          value={value}
          className={`form-control ${getSize()} ${textboxCss} ${
            withBorderRadius ? "with-border-radius" : ""
          }`}
          placeholder={placeholder === null ? label : placeholder}
          autoComplete="off"
          disabled={disabled || busyMode}
          onChange={handleChange}
          onBlur={onBlur}
          onKeyUp={handleKeyUp}
          required
          maxLength={maxLength}
          {...rest}
        />
        {busyMode && <ActivityIndicator />}
        {type !== "textarea" && <label htmlFor={name}>{label}</label>}
      </div>
    </section>
  );
};

Bs5Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  containerCss: PropTypes.string,
  textboxCss: PropTypes.string,
  withBorderRadius: PropTypes.bool,
  disabled: PropTypes.bool,
  busyMode: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onEnterKey: PropTypes.func,
  maxLength: PropTypes.number,
};

Bs5Input.defaultProps = {
  type: "text",
  size: "md",
  textboxCss: "",
  withBorderRadius: true,
  placeholder: null,
  busyMode: false,
  onChange: noop,
  onBlur: noop,
  onEnterKey: noop,
  maxLength: 1000,
};

export default Bs5Input;
