/**
 * SignalR library (Real-time Comms)
 * Object type: Singleton instance
 * story: #5358
 * wiki:
 */
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { noop } from "lodash";
import {
  getAccessToken,
  reAuthenticateAsync,
  isAuthenticated,
} from "./access-token-provider";

const INITIAL_START_FAILURE_DELAY = 5000;

export default class SignalRManager {
  static signalRInstance = null;

  _url = null;
  _connection = null;

  /** Instance creation Options/Configurations */
  _options = {
    disabled: false,
    logLevel: "warn",
    /** Default to 5 attempts with varying reconnect delay */
    autoReconnectSettings: [0, 2000, 10000, 20000, 30000],
  };

  /** List of errors */
  _errors = [];
  _allowLog = true;
  _subscriptions = [];

  _onClose = noop;
  _onReconnected = noop;
  _onReconnecting = noop;
  _onAuthError = noop;

  /**
   * Ctor
   * @param {*} url
   * @param {*} options
   * @returns
   */
  constructor(url, options = this._options) {
    this._url = url;
    this._options = { ...this._options, ...options };

    if (this._options.disabled) return;

    this._connection = new HubConnectionBuilder()
      .withUrl(this._url, { accessTokenFactory: getAccessToken })
      .withAutomaticReconnect(this._options.autoReconnectSettings)
      .configureLogging(this._options.logLevel)
      .build();

    this._configureConnectionEvents();

    this._logInfo("SignalR connection created - OK...");
  }

  /**
   * Get Singleton instance
   * @param {*} url
   * @param {*} options
   * @returns
   */
  static getInstance(url, options) {
    if (SignalRManager.myInstance == null) {
      console.log("Get SignalR singleton instance...");
      SignalRManager.myInstance = new SignalRManager(url, options);
    }

    return this.myInstance;
  }

  /**
   * Start connection
   * @returns
   */
  async startAsync() {
    if (this._options.disabled) return;

    try {
      // Don't start if no active subscriptions
      if (this._subscriptions.length === 0) return;
      await this._connection.start();

      console.log("SignalR Connected.");
    } catch (err) {
      this._addErrors(err);
      setTimeout(
        async () => await this.startAsync(),
        INITIAL_START_FAILURE_DELAY
      );
    }
  }

  /**
   * Subscribe to signal events
   * @param {*} eventName
   * @param {*} subscriptionFn
   * @returns
   */
  subscribe(name, subscriptionFn) {
    if (this._options.disabled) return;
    if (this._subscriptions.some((f) => f === name)) return;

    this._subscriptions.push({ name, subscriptionFn });
    this._connection.on(name, subscriptionFn);
  }

  /**
   * Invoke a method in the Server
   * @param {*} serverMethodName
   * @param {*} payload
   * @returns
   */
  async invokeAsync(serverMethodName, payload) {
    if (this._options.disabled) return;

    if (this.getConnectionStatus() !== "Connected") {
      console.log(
        `Unable to invoke method. SignalR status not connected. Current status ${this.getConnectionStatus()}`
      );
      return;
    }

    try {
      if (payload == null) {
        await this._connection.invoke(serverMethodName);
        return;
      }

      await this._connection.invoke(serverMethodName, payload);
    } catch (err) {
      this._addErrors(err);

      if (this._isUnauthorised(err) && isAuthenticated()) {
        this._renewToken({ serverMethodName, payload });
        return;
      }

      throw err;
    }
  }

  /**
   * Reinitialize SignalR connection
   * Call this if system is logging out/in.
   * This will re-fetch token. 
   * WTH microsoft! They should expose an event to fire when auth error raised.
   * @returns 
   */
  async reinitialiseConnectionAsync() {
    if (this._options.disabled) return;
    this._logInfo("Reinitialise signalR connection...");
    await this._reInitializeConnectionInstance();
  }

  /**
   * Use logger
   * @param {*} allowLog
   * @param {*} severity
   * @returns
   */
  useLogger(allowLog) {
    this._allowLog = allowLog;
    return this;
  }

  getErrorsWithStackTrace() {
    return this._errors;
  }

  getLastError() {
    if (this._errors.length === 0) return null;
    return this._errors[this._errors.length - 1];
  }

  getConnectionStatus() {
    if (this._options.disabled) return null;
    return this._connection.state;
  }

  onReconnecting(onReconnectHandlerFn) {
    this._onReconnecting = onReconnectHandlerFn;
    return this;
  }

  onReconnected(onReconnectedHandlerFn) {
    this._onReconnected = onReconnectedHandlerFn;
    return this;
  }

  onClose(onCloseHandlerFn) {
    this._onClose = onCloseHandlerFn;
    return this;
  }

  onAuthError(onAuthErrorHandlerFn) {
    this._onAuthError = onAuthErrorHandlerFn;
    return this;
  }

  // -----------
  // Helpers
  // -----------
  _configureConnectionEvents = () => {
    // Reconnecting handler
    this._connection.onreconnecting((error) => {
      console.assert(
        this._connection.state === HubConnectionState.Reconnecting
      );

      this._logInfo(`Connection lost due to error "${error}". Reconnecting.`);
      this._onReconnecting(error);
    });

    // Reconnected handler
    this._connection.onreconnected((connectionId) => {
      console.assert(this._connection.state === HubConnectionState.Connected);

      this._logInfo(
        `Connection reestablished. Connected with connectionId "${connectionId}".`
      );
      this._onReconnected(connectionId);
    });

    // Close handler
    this._connection.onclose((error) => {
      console.assert(this._connection.state === HubConnectionState.Connected);

      this._logInfo(
        `Connection closed due to error "${error}". Try refreshing this page to restart the connection.`
      );
      this._addErrors(error);
      this._onClose(error);
    });
  };

  /** Renew auth token  */
  async _renewToken(replayObject) {
    try {
      await reAuthenticateAsync();
      await this._reInitializeConnectionInstance();

      // Replay request
      this._logInfo("Replaying request...");
      await this.invokeAsync(
        replayObject.serverMethodName,
        replayObject.payload
      );
      this._logInfo("Method invocation replayed successfully...");
    } catch (err) {
      this._addErrors(err);
      //this._onAuthError("Unable to renew auth token.");
    }
  }

  async _reInitializeConnectionInstance() {
    this._subscriptionOff();

    this._connection = new HubConnectionBuilder()
      .withUrl(this._url, { accessTokenFactory: getAccessToken })
      .withAutomaticReconnect(this._options.autoReconnectSettings)
      .configureLogging(this._options.logLevel)
      .build();

    this._logInfo("SignalR connection re-created - OK...");

    // Wire events
    this._configureConnectionEvents();
    this._logInfo("Re-wiring events - OK...");

    // Attach previous subscriptions
    this._subscriptionOn();

    // Start connection
    this._logInfo("Starting connection...");
    await this.startAsync();
    this._logInfo("Connection started - OK...");
  }

  _subscriptionOn() {
    this._subscriptions.forEach(({ name, subscriptionFn }) =>
      this._connection.on(name, subscriptionFn)
    );
  }

  _subscriptionOff() {
    this._subscriptions.forEach(({ name, subscriptionFn }) =>
      this._connection.off(name, subscriptionFn)
    );
  }

  _isUnauthorised(err) {
    console.error(err?.message);
    return err && err.message.includes("unauthorized");
  }

  _addErrors(error) {
    this._logError(error);
    this._logInfo(this._getStateInfo());

    if (this._errors.length + 1 > 50) {
      this._errors = [];
    }

    this._errors.push(error);
  }

  _getStateInfo() {
    return `Connection state: ${this._connection.state}`;
  }

  _logError(message) {
    console.error(message);
  }

  _logInfo(message) {
    console.info(message);
  }

  _fireAndForgetEventHandlers(arrayOfEventHandlers) {
    arrayOfEventHandlers.forEach((f) => {
      try {
        (f.handlerFn || noop)();
      } catch (err) {
        //   Just Eat Exception
      }
    });
  }
}
