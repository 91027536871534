import Auth from "core/auth/auth";
import { HttpClient } from "core/http";
import Config from "config";
import UserIdentity from "core/auth/userIdentity";
import ProfileService from "./profile-service";

const AuthTokenService = () => {
  /**
   * Login
   * @param {} userName
   * @param {*} password
   * @returns
   */
  const loginAsync = async (userName, password) => {
    let profileService = ProfileService();

    const payload = {
      UserName: userName,
      Password: password,
      ClientId: Config.clientId,
      GrantType: "password",
    };

    return new Promise((resolve, reject) => {
      HttpClient()
        .post("/v1/core/auth/token", payload)
        .then((resp) => {
          if (typeof resp.data === "string" && isValidURL(resp.data)) {
            window.location.replace(resp.data);
          } else {
            Auth.setToken(
              resp.data.access_token,
              resp.data.refresh_token,
              resp.data.expires_in
            );

            profileService
              .getProfile(UserIdentity().getUserId())
              .then((res) => {
                resolve({
                  ...resp.data,
                  profile: { ...res.data },
                });
              })
              .catch((err) => {
                reject(err);
              });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  function isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  /**
   * Replace User Token
   * @param {*} newToken
   */
  const replaceUserToken = (newToken, pageFullRefresh = true) => {
    Auth.setToken(
      newToken.access_token,
      newToken.refresh_token,
      newToken.expires_in
    );

    pageFullRefresh && window.location.reload();
  };

  /**
   * Logout
   * @param {} redirectUrl
   */
  const logout = (redirectUrl) => {
    Auth.removeToken();

    //if (redirectUrl) window.location.assign(redirectUrl);
    //else window.location.reload();
    redirectUrl && window.location.assign(redirectUrl);

    localStorage.clear();
  };

  return {
    loginAsync,
    replaceUserToken,
    logout,
  };
};

export default AuthTokenService;
