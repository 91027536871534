import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./../../services/apiService";

const initialState = {
  loading: false,
  redirectTo: "",
};

// ---------------
// Action Thunks
// ---------------
export const changePassword = createAsyncThunk(
  "myAccount/changePassword/change",
  async (data, thunkAPI) => {
    try {
      const { currentPassword, newPassword, confirmNewPassword } = data.values;

      let payload = {
        existingPassword: currentPassword,
        password: newPassword,
        confirmPassword: confirmNewPassword,
      };

      let result = await ApiService().changePassword(payload);

      thunkAPI.dispatch(addNotification("Password successfully changed."));

      data.onUpdateSuccess();

      return result;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to change password.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const myAccountChangePasswordSlice = createSlice({
  name: "myAccount/changePassword",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(changePassword.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default myAccountChangePasswordSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.myAccountChangePassword;

export const selectChangePassword = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
