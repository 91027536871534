import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./../../services/apiService";
import { toIsoDate } from "core/utilities/date-service";

const initialState = {
  loading: false,
  redirectTo: "",
};

// ---------------
// Action Thunks
// ---------------
export const createNew = createAsyncThunk(
  "manageSubscriptionAdmin/createSubscription/createNew",
  async (data, thunkAPI) => {
    try {
      const { userId, values, onPaymentCompleted } = data;

      let payload = {
        userId,
        productCode: values.selectedPlan?.value,
        nextBillingDate: toIsoDate(values.nextBillingDate),
        cardNumber: values.isInvoiced ? null : values.cardNumber,
        expirationDate: values.isInvoiced ? null : values.expiryDate,
        securityCode: values.isInvoiced ? null : values.cvc,
        isInvoiced: values.isInvoiced
      };

      let result = await ApiService().createNewSubscription(payload);

      thunkAPI.dispatch(
        addNotification("Subscription has been successfully created.")
      );

      onPaymentCompleted();

      return result;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to create subscription.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const manageSubscriptionAdminCreateSubsSlice = createSlice({
  name: "manageSubscriptionAdmin/createSubscription",
  initialState,
  reducers: {
    clearStates: (state) => {
      //state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNew.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createNew.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(createNew.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { clearStates } = manageSubscriptionAdminCreateSubsSlice.actions;

export default manageSubscriptionAdminCreateSubsSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.manageSubscriptionAdminCreateSubs;

export const selectManageSubscriptionAdminCreateSubs = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
