import {
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";
import ApiService from "./../../services/commentApiService";

const commentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.commentedDate.localeCompare(a.commentedDate), //Sort Descending //a.commentedDate.localeCompare(b.commentedDate),
});

// ---------------
// Action Thunks
// ---------------
export const getComments = createAsyncThunk(
  "viewClients/comments/getComments",
  async (customerId, thunkAPI) => {
    try {
      let result = await ApiService().getComments(customerId);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch comment(s).")
      );
      throw err;
    }
  }
);

export const addComment = createAsyncThunk(
  "viewClients/comments/add",
  async (data, thunkAPI) => {
    try {
      let payload = {
        entityLinkId: data.customerId,
        comment: data.values.comment,
        fileAttachmentJson:
          data.values.attachment != null
            ? JSON.stringify(data.values.attachment)
            : null,
        commentType: 1,
      };

      let result = await ApiService().addComment(payload);

      thunkAPI.dispatch(
        addNotification("Comment has been successfully added.")
      );

      return {
        id: result.data.id,
        comment: data.values.comment,
        hasUpload: result.data.hasUpload,
        commentedBy: result.data.commentedBy,
        commentedDate: result.data.commentedDate,
      };
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to add comment."));
      throw err;
    }
  }
);

export const getCommentById = createAsyncThunk(
  "viewClients/comments/getCommentById",
  async (id, thunkAPI) => {
    try {
      let result = await ApiService().getCommentById(id);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to fetch comment."));
      throw err;
    }
  }
);

export const deleteComment = createAsyncThunk(
  "viewClients/comments/deleteComment",
  async (id, thunkAPI) => {
    try {
      await ApiService().deleteComment(id);

      thunkAPI.dispatch(
        addNotification("Comment has been successfully deleted.")
      );

      return id;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to delete comment."));
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const clientCommentsSlice = createSlice({
  name: "viewClients/comments",
  initialState: commentsAdapter.getInitialState({
    settings: {}, // Last current settings of solvable dataTable
    viewCommentData: {
      comment: "",
      fileAttachments: [],
    },
    loading: false,
    showComment: false,
    showViewComment: false,
    showDeleteCommentConfirmation: false,
  }),
  reducers: {
    keepDataTableSettings: (state, action) => {
      state.settings = action.payload;
    },
    showCommentDialog: (state, action) => {
      state.showComment = action.payload;
    },
    showViewCommentDialog: (state, action) => {
      state.showViewComment = action.payload;
    },
    showDeleteCommentConfirmationDialog: (state, action) => {
      state.showDeleteCommentConfirmation = action.payload;
    },
    clearStates: (state) => {
      commentsAdapter.removeAll(state);
      state.viewCommentData = {
        comment: "",
        fileAttachments: [],
      };
    },
  },
  extraReducers: (builder) => {
    //
    // Get comments
    //
    builder.addCase(getComments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getComments.fulfilled, (state, action) => {
      commentsAdapter.addMany(state, action.payload);
      state.loading = false;
    });
    builder.addCase(getComments.rejected, (state) => {
      state.loading = false;
    });

    //
    // Add comment
    //
    builder.addCase(addComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addComment.fulfilled, (state, action) => {
      commentsAdapter.addOne(state, action.payload);
      state.loading = false;
      state.showComment = false;
    });
    builder.addCase(addComment.rejected, (state) => {
      state.loading = false;
    });

    //
    // Get comment by id
    //
    builder.addCase(getCommentById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCommentById.fulfilled, (state, action) => {
      state.viewCommentData.comment = action.payload.commentText;

      if (action.payload.fileData != null) {
        let fileJson = JSON.parse(action.payload.fileAttachment);

        state.viewCommentData.fileAttachments = [
          {
            name: fileJson.OriginalFileName,
            data: action.payload.fileData,
            size: fileJson.Size,
          },
        ];
      } else {
        state.viewCommentData.fileAttachments = [];
      }

      state.loading = false;
      state.showViewComment = true;
    });
    builder.addCase(getCommentById.rejected, (state) => {
      state.loading = false;
    });

    //
    // Delete comment
    //
    builder.addCase(deleteComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteComment.fulfilled, (state, action) => {
      commentsAdapter.removeOne(state, action.payload);
      state.loading = false;
      state.showDeleteCommentConfirmation = false;
    });
    builder.addCase(deleteComment.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  keepDataTableSettings,
  showCommentDialog,
  showViewCommentDialog,
  showDeleteCommentConfirmationDialog,
  clearStates,
} = clientCommentsSlice.actions;

export default clientCommentsSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.clientComments;

export const selectClientCommentsEntities = commentsAdapter.getSelectors(
  (state) => state.clientComments
);

export const selectClientComments = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
