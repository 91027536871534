import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import planFormData from "constants/data/plan-form-data";
import { addErrorNotification } from "redux/system/system-action-creators";
import { getPlanTerms, getPlanType } from "../../services/plan-value-mapping";
import ApiService from "./services/apiService";
import { AppUrls } from 'constants/app-urls';

const initialState = {
  activeData: planFormData[0],
  selectedPlanData: "standard", //"basic",
  selectedPlanTerms: "select-opt1",
  redirectTo: null,
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
export const trackSelectedPlan = createAsyncThunk(
  "accountPlanForm/trackSelectedPlan",
  async (data, thunkAPI) => {
    try {
      let payload = {
        terms: data.terms,
        planType: data.planType,
      };

      await ApiService().trackSelectedPlan(payload);
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to save selected plan.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const accountPlanFormSlice = createSlice({
  name: "accountPlanForm",
  initialState,
  reducers: {
    changeActiveData: (state, action) => {
      state.activeData =
        action.payload === "monthly" ? planFormData[0] : planFormData[1];
    },
    changeSelectedPlanData: (state, action) => {
      state.selectedPlanData = action.payload;
    },
    changeDefaultPlan: (state, action) => {
      let selectedPlan = planFormData.reduce((acc, curr) => {
        let plan = curr.planSelectors.find(
          (f) => f.productCode === action.payload
        );
        if (plan != null) {
          acc.activeData = { ...curr };
          acc.selectedPlanData = plan.key;
          return acc;
        }

        return acc;
      }, {});

      if (selectedPlan == null) return;

      state.activeData = selectedPlan.activeData;
      state.selectedPlanData = selectedPlan.selectedPlanData;
      state.selectedPlanTerms =
        selectedPlan.activeData.term === "Monthly"
          ? "select-opt1"
          : "select-opt2";
    },
    clearStates: (state) => {
      state.redirectTo = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(trackSelectedPlan.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(trackSelectedPlan.fulfilled, (state) => {
      state.loading = false;
      //state.redirectTo = AppUrls.CREDIT_OPTION;  // Commented Temporarily!!!
      state.redirectTo = AppUrls.FREE_TRIAL_OPTION;
    });

    builder.addCase(trackSelectedPlan.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeActiveData,
  changeSelectedPlanData,
  changeDefaultPlan,
  clearStates,
} = accountPlanFormSlice.actions;

export default accountPlanFormSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.accountPlanForm;

export const selectAccountPlanForm = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

export const selectSelectedPlanInfo = createDraftSafeSelector(
  selectSelf,
  (state) => {
    let planState = state.activeData;
    // let priceRow = planState.features.find((f) => {
    //   return f["isPriceRow"] || false;
    // });

    //let price = priceRow[state.selectedPlanData];
    let planSelector = state.activeData.planSelectors.find(
      (f) => f.key === state.selectedPlanData
    );

    let planName = planSelector?.name;
    let productCode = planSelector?.productCode;

    return {
      price: planSelector.price,
      term: planState.term,
      priceText: `${planSelector.price}/${planState.term === "Monthly" ? "month" : "year"}`,
      plan: `${planName} Plan`,
      planTerms: getPlanTerms(planState.term),
      planType: getPlanType(state.selectedPlanData),
      productCode,
    };
  }
);


export const selectSelectedPlanProductCode = createDraftSafeSelector(
  selectSelf,
  (state) => {
    let planSelector = state.activeData.planSelectors.find(
      (f) => f.key === state.selectedPlanData
    );
    return planSelector?.productCode;
  }
);