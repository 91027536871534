import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import AceEditor from "react-ace";
import useScrollNavigate from "hooks/use-scroll-navigate";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";
import "./api-model.scss";

const ApiModel = ({
  attributes,
  payloadObj,
  responseAttributes,
  successResponseObj,
  failureResponseObj,
  payloadHeight,
  responseHeight,
}) => {
  const { scrollToSectionOffset } = useScrollNavigate(false, 100);

  const [payload, setPayload] = useState(payloadObj);
  const [successResponse, setSuccessResponse] = useState(successResponseObj);
  const [failureResponse, setFailureResponse] = useState(failureResponseObj);

  const isJson = (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  };

  const handleScroll = (el) => {
    scrollToSectionOffset(el, 100, null, true);
  };

  return (
    <>
      {attributes && (
        <Row className="api-model">
          <Col lg={payload ? 5 : 12} xl={payload ? 4 : 12}>
            {responseAttributes && <h5>Request Attributes</h5>}
            {attributes.map((attr, i) => (
              <div key={i} className="api-model-attribute">
                <span className="api-model-attribute-name">{attr.name}</span>
                <span className="api-model-attribute-type">
                  {attr.linkToTag ? (
                    <a
                      onClick={(e) => {
                        handleScroll(attr.linkToTag);
                        e.preventDefault();
                      }}
                      href={`/api-documentation/webhooks/${attr.linkToTag}`}
                    >
                      {attr.type}
                    </a>
                  ) : (
                    attr.type
                  )}
                </span>
                {attr.description && (
                  <span className="api-model-attribute-description">
                    {attr.description}
                  </span>
                )}
              </div>
            ))}
          </Col>
          {payload && (
            <Col lg={7} xl={8}>
              <h5>Example Payload</h5>
              <AceEditor
                mode="json"
                theme="textmate"
                fontSize={14}
                showPrintMargin={false}
                showGutter={false}
                highlightActiveLine={true}
                width="100%"
                height={payloadHeight ? payloadHeight : "500px"}
                value={JSON.stringify(payload, 0, 2)}
                onBlur={(e, code) => {
                  let data = code.getValue();
                  let isValid = isJson(data);

                  if (isValid) {
                    setPayload(JSON.parse(data));
                  }
                }}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </Col>
          )}
        </Row>
      )}
      {responseAttributes && (
        <Row className="api-model mt-3">
          <Col lg={successResponse ? 5 : 12} xl={successResponse ? 4 : 12}>
            <h5>Response Attributes</h5>
            {responseAttributes.map((attr, i) => (
              <div key={i} className="api-model-attribute">
                <span className="api-model-attribute-name">{attr.name}</span>
                <span className="api-model-attribute-type">
                  {attr.linkToTag ? (
                    <a
                      onClick={(e) => {
                        handleScroll(attr.linkToTag);
                        e.preventDefault();
                      }}
                      href={`/api-documentation/webhooks/${attr.linkToTag}`}
                    >
                      {attr.type}
                    </a>
                  ) : (
                    attr.type
                  )}
                </span>
                {attr.description && (
                  <span className="api-model-attribute-description">
                    {attr.description}
                  </span>
                )}
              </div>
            ))}
          </Col>
          {successResponse && (
            <Col lg={7} xl={8}>
              <h5>Example {failureResponse ? "Successful " : ""}Response</h5>
              <p>HTTP Response Code: 200</p>
              <AceEditor
                mode="json"
                theme="textmate"
                fontSize={14}
                showPrintMargin={false}
                showGutter={false}
                highlightActiveLine={true}
                width="100%"
                height={responseHeight ? responseHeight : "500px"}
                value={JSON.stringify(successResponse, 0, 2)}
                onBlur={(e, code) => {
                  let data = code.getValue();
                  let isValid = isJson(data);

                  if (isValid) {
                    setSuccessResponse(JSON.parse(data));
                  }
                }}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />

              {failureResponse && (
                <>
                  <h5 className="mt-3">Example Failure Response</h5>
                  <p>HTTP Response Code: 4XX or 5XX</p>
                  <AceEditor
                    mode="json"
                    theme="textmate"
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={false}
                    highlightActiveLine={true}
                    width="100%"
                    height={responseHeight ? responseHeight : "500px"}
                    value={JSON.stringify(failureResponse, 0, 2)}
                    onBlur={(e, code) => {
                      let data = code.getValue();
                      let isValid = isJson(data);

                      if (isValid) {
                        setFailureResponse(JSON.parse(data));
                      }
                    }}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                  />
                </>
              )}
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default ApiModel;
