import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  isDeleting: false,
  settings: {},
  submissionType: 1,
  data: [],
};

export default function ManageUsersReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.MANAGE_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.MANAGE_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case ActionTypes.MANAGE_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.MANAGE_USERS_DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case ActionTypes.MANAGE_USERS_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        data: state.data.filter((x) => x.id != action.payload),
      };
    case ActionTypes.MANAGE_USERS_DELETE_FAILURE:
      return {
        ...state,
        isDeleting: false,
      };
    case ActionTypes.MANAGE_USERS_CLEAN_UP:
      return initialState;
    case ActionTypes.MANAGE_USERS_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    default:
      return state;
  }
}
