import { ConnectionState } from "./connection-state-enum";

const getConnectionStatus = (signalRManager) => {
  switch (signalRManager.getConnectionStatus()) {
    case "Connecting":
      return ConnectionState.CONNECTING;
    case "Connected":
      return ConnectionState.CONNECTED;
    case "Disconnecting":
      return ConnectionState.DISCONNECTING;
    case "Reconnecting":
      return ConnectionState.RECONNECTING;

    default:
      return ConnectionState.DISCONNECTED;
  }
};

export { getConnectionStatus };
