import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/common/scroll-to-top";
import ToastNotificationContainer from "./components/common/toast/toast-container";
import indexRoutes from "./routes";
import PrivateRoute from "./components/common/private-route";
import "./App.scss";
import "./custom.scss";
import InterceptorEventBus from "components/common/interceptor-eventbus/interceptor-event-bus.js";
import Hub from "components/real-time-comms/coreHub/hub";
import PingSubscriber from "components/real-time-comms/subscribers/ping-subscriber";
import AuthSubscriber from "components/real-time-comms/subscribers/auth-subscriber";
import ErrorBoundary from "components/common/error-boundary/error-boundary";
import DataTableProvider from "components/common/solvable-datatable/context/data-table-context";

const App = () => {
  return (
    <Router>
      <Hub enableLogging={true}>
        {(props) => (
          <>
            <AuthSubscriber {...props} />
            <PingSubscriber {...props} />
          </>
        )}
      </Hub>
      <ErrorBoundary>
        <ScrollToTop>
          <ToastNotificationContainer />
          <InterceptorEventBus />
          <DataTableProvider>
            <div className="sw-app custom-styles">
              <Switch>
                {indexRoutes.map((prop, key) => {
                  if (prop.privateRoute) {
                    return (
                      <PrivateRoute
                        path={prop.path}
                        key={key}
                        component={prop.component}
                      />
                    );
                  } else {
                    return (
                      <Route
                        path={prop.path}
                        key={key}
                        component={prop.component}
                      />
                    );
                  }
                })}
              </Switch>
            </div>
          </DataTableProvider>
        </ScrollToTop>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
