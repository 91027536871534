const clientOnboardingLearnMoreData = [
  {
    id: "feature-client-onboarding",
    heading:
      "Streamlined online <span class='highlight-text'>Client Onboarding Experience</span>",
    subHeading:
      "Enhance your client's first impression with a seamless onboarding experience. Our platform streamlines the process, providing clients with all the information and resources they need to get started, making it easy and enjoyable.",
    bulletPoints: [
      "Gather all the information you need",
      "User-friendly platform",
      "Provide Insurance Certificates to your clients",
      "Digital signatures for Terms of Credit",
      "Positive start to the client relationship.",
    ],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/company-form.webp",
    imageAlt: "Engaging and simple eLearning on any device",
    marginTop: "0",
  },
  {
    id: "Build-your-own-steps",
    heading: "<span class='highlight-text'>Build Your Own Steps</span>",
    subHeadings: [
      "With Staffd's Client Onboarding system, you have the flexibility to design the onboarding process that works best for your business. Our user-friendly configuration tool makes it easy to set up your own custom steps and requirements.",
      "Whether you're looking to streamline your process or make it more engaging, our client onboarding system gives you the power to create a unique and effective onboarding experience for your clients.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/company-steps.webp",
    imageAlt: "Build Your Own Steps",
  },
  {
    id: "gather-the-information-you-need",
    heading:
      "<span class='highlight-text'>Gather the Information You Need</span>",
    subHeadings: [
      "Gather the information you need with ease, by selecting the right questions to ask in your online application form. With Staffd, you have the ability to customise your form, ensuring it captures the information relevant to your business.",
      "Say goodbye to manual, time-consuming processes and embrace a modern, streamlined solution that saves you time and resources.",
    ],
    bulletPoints: [],
    imagePath: "https://staffd.azureedge.net/www/screenshots/v4/company.webp",
    imageAlt: "Gather the Information You Need",
  },
  {
    id: "downloads",
    heading: "<span class='highlight-text'>Document Downloads</span>",
    subHeadings: [
      "Our platform offers the ability to download important documents such as Insurance Certificates, making sure that they have everything they need to meet their own compliance requirements. This not only makes the onboarding process smoother, but it also helps ensure that your clients are well equipped to handle any compliance-related tasks or issues that may arise.",
      "With easy access to all the necessary information and resources, your clients will feel confident and prepared from the moment they begin working with you.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/company-downloads.webp",
    imageAlt: "Document Downloads",
  },
  {
    id: "agreement",
    heading: "<span class='highlight-text'>Create Your Own Agreements</span>",
    subHeadings: [
      "With our Agreement Builder, you can customise your Agreements or Terms and Conditions to suit your business and have them easily accessible for clients to review and sign digitally.",
      "Stay compliant with the latest regulations and provide a professional, streamlined experience for your clients.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/company-agreement.webp",
    imageAlt: "Create Your Own Agreements",
  },
  {
    id: "manage-applications",
    heading: "<span class='highlight-text'>Manage Client Applications</span>",
    subHeadings: [
      "Easily manage client applications with our user-friendly platform. Quickly access all required information to make informed decisions and keep track of the application process from start to finish. Our platform eliminates manual processes and streamlines the application process, making it efficient and stress-free for both you and your clients.",
      "Contact us today to learn more about how we can help your team succeed.",
    ],
    bulletPoints: [],
    imagePath:
      "https://staffd.azureedge.net/www/screenshots/v4/company-manage.webp",
    imageAlt: "Manage Client Applications",
  },
];

export default clientOnboardingLearnMoreData;
