import React from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import { useFormikContext, useField } from "formik";
import MaterialInput from "./mat-input";
import ValidationMessage from "../validation-message/validation-message";

function MatFormInput({ name, ...rest }) {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [field] = useField({ name });

  return (
    <FormGroup>
      <MaterialInput
        {...rest}
        name={name}
        value={field.value}
        onChange={(value) => {
          setFieldValue(name, value);
        }}
        onBlur={() => setFieldTouched(name, true)}
      />
      <ValidationMessage name={name} />
    </FormGroup>
  );
}

MatFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  textAreaSize: PropTypes.string,
  onChange: PropTypes.func,
};

MatFormInput.defaultProps = {
  labelBold: false,
  onChange: () => {},
};

export default MatFormInput;
