import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { addErrorNotification } from "redux/system/system-action-creators";
import ApiService from "./services/apiService";
import UserIdentity from "core/auth/userIdentity";
import { AccountSetupStatusTypes } from 'constants/account-setup-status-types';
import { AppUrls } from 'constants/app-urls';

const initialState = {
  accountInformation: {
    userId: "",
    loginEmail: "",
    companyName: "",
    firstName: "",
    lastName: "",
    companyPhoneNumber: "",
    invoiceEmail: "",
    mobileNumber: "",
    cardLast4Digit: "",
    cardExpiryDate: "",
    accountSetupStatus: "",
    subscriptionStatus: "",
    hasActiveSubscription: false,
    subscribedProductCode: "",
    subscribedProductName: "",
    subscribedPlanTerms: "",
    subscribedPlanType: "",
    nextBillingDateText: "",
  },
  openMyDetailsDialog: false,
  openChangePasswordDialog: false,
  openPaymentDialog: false,
  redirectNow: null,
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
export const getAccountInfo = createAsyncThunk(
  "myAccount/getAccountInfo",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().getAccountInfo(
        UserIdentity().getUserId()
      );

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to fetch account information.")
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const myAccountSlice = createSlice({
  name: "myAccount",
  initialState,
  reducers: {
    openUpdateMyDetails: (state, action) => {
      state.openMyDetailsDialog = action.payload;
    },
    openChangePassword: (state, action) => {
      state.openChangePasswordDialog = action.payload;
    },
    openPaymentDetails: (state, action) => {
      state.openPaymentDialog = action.payload;
    },
    clearStates: (state) => {
      state.redirectNow = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountInfo.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAccountInfo.fulfilled, (state, action) => {
      if (action.payload.isAdmin) {
        state.redirectNow = AppUrls.ADMIN;
        return;
      }

      if (action.payload.accountSetupStatus !== AccountSetupStatusTypes.TENANT_SETUP_COMPLETED) {
        state.redirectNow = AppUrls.SIGNUP;
        return;
      }

      state.loading = false;
      state.accountInformation = action.payload;
    });

    builder.addCase(getAccountInfo.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { openUpdateMyDetails, openChangePassword, openPaymentDetails, clearStates } =
  myAccountSlice.actions;

export default myAccountSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.myAccount;

export const selectMyAccount = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

export const selectMyAccountStatus = createDraftSafeSelector(
  selectSelf,
  (state) => state.accountInformation.subscriptionStatus
);
