/**
 * Interceptor event bus
 * Imperative to declarative bridge mechanism for Http interceptor to contact declarative/component based code.
 * This allows imperative code to contact Redux inside the context of component.
 * rcnet@solvable 2020
 */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import postal from "postal";
import { useHistory } from "react-router-dom";
import { handleError } from "redux/system/system-action-creators";

const InterceptorEventBus = () => {
  let subscription;
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    console.log("Register EventBus...");

    subscription = postal.subscribe({
      channel: "httpInterceptorChannel",
      topic: "interceptor.navigate",
      callback: (data, envelope) => {
        switch (data.cmd) {
          case "navigate":
            history.push(data.data);
            break;
          case "notify":
            dispatch(handleError("Connection Error"));
            break;
        }
      },
    });

    return () => {
      console.log("Remove EventBus...");
      subscription.unsubscribe();
    };
  }, []);

  return <></>;
};

export default InterceptorEventBus;
