import React from "react";
import PropTypes from "prop-types";
import ContentSection from "components/common/content-section";

function Plain({ children, containerCss, bgColor, contentPadding, ...rest }) {
  return (
    <div className={`plain-container ${containerCss}`}>
      <ContentSection
        {...rest}
        specificClass="content-container"
        containerStyle={{ backgroundColor: bgColor }}
        padding={contentPadding}
      >
        {children}
      </ContentSection>
    </div>
  );
}

Plain.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  containerCss: PropTypes.string,
  bgColor: PropTypes.string,
  contentPadding: PropTypes.string, // Bootstrap padding, valid values:  "0, 1, 2, 3, 4, 5"
};

Plain.defaultProps = {
  data: {},
  containerCss: "",
  bgColor: "transparent",
  contentPadding: "4",
};

export default Plain;
