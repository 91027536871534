import React, { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { NavLink } from "react-router-dom";
import ButtonFx from "components/common/button-fx";
import { Bs5FormInput } from "components/common/form-fields";
import CustomCheckbox from "components/common/custom-checkbox/custom-checkbox";

function EntryForm({ loading, errorMessage, containerCss, onSubmit }) {
  const [rememberMe, setRememberMe] = useState(false);

  const handleOnChecked = () => setRememberMe(!rememberMe);

  return (
    <>
      <section
        className={`d-flex flex-column justify-content-center ${containerCss}`}
      >
        <Bs5FormInput
          size="xl"
          name="email"
          label="Email"
          placeholder="Email"
          textboxCss="text-box"
        />
        <Bs5FormInput
          type="password"
          size="xl"
          name="password"
          label="Password"
          placeholder="Password"
          textboxCss="text-box"
          onEnterKey={onSubmit}
        />
        <ButtonFx
          type="submit"
          className="sw-signup-btn-rounded-primary mt-4"
          buttonSize={"md"}
          isLoading={loading}
          onClick={onSubmit}
        >
          SIGN IN
        </ButtonFx>
        <div className="d-flex flex-row justify-content-between mt-2">
          <div>
            <CustomCheckbox
              name="rememberMe"
              label="Remember me"
              showLabel={true}
              value={rememberMe}
              onChecked={handleOnChecked}
            />
          </div>
          <div>
            <NavLink to="/forgot-password/" className="need-help-link">
              Forgot Password
            </NavLink>
          </div>
        </div>
        <div className="error-message">{errorMessage}</div>
      </section>
    </>
  );
}

EntryForm.propTypes = {
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  containerCss: PropTypes.string,
  onSubmit: PropTypes.func,
};

EntryForm.defaultProps = {
  loading: false,
  onSubmit: noop,
};

export default EntryForm;
