import React from "react";
import "./section.scss";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { SCREEN_SIZE } from "constants/app-constants";

const ContentSection = ({
  bgColor,
  bgClass,
  maxWidth,
  padding,
  specificClass,
  overrideContentSectionCss,
  containerStyle,
  children,
  isFullWidth,
}) => {
  let defaultPadding = 4;
  let defaultColor = "";
  let defaultMaxWidth = isFullWidth ? "100%" : SCREEN_SIZE;

  const isSmallDevice = useMediaQuery({
    query: "(max-width: 750px)",
  });

  const getContainerClass = () => {
    if (bgColor) return `container-bg-${bgColor}`;

    if (bgClass) return bgClass;

    if (defaultColor) return `container-bg-${defaultColor}`;

    return "";
  };

  const getContainerMaxWidth = () => {
    return `${maxWidth ? maxWidth : defaultMaxWidth}px`;
  };

  const definedPadding = padding ? padding : defaultPadding;
  const definedSpecificClass = specificClass ? ` ${specificClass}` : "";
  const defaultClass = isFullWidth
    ? `container-fluid py-${definedPadding} content-full-width ${definedSpecificClass}`
    : `container py-${definedPadding} ${
        overrideContentSectionCss ? "" : "content-section"
      } ${definedSpecificClass}`;
  const smallDeviceClass = `container-fluid content-full-width py-${definedPadding} ${
    overrideContentSectionCss ? "" : "content-section"
  }  ${definedSpecificClass}`;

  return (
    <div
      className={`content-section-container ${getContainerClass()}`}
      style={containerStyle}
    >
      <div
        className={isSmallDevice ? smallDeviceClass : defaultClass}
        style={{ maxWidth: getContainerMaxWidth() }}
      >
        <div className="row">
          <div className="col">{children}</div>
        </div>
      </div>
    </div>
  );
};

ContentSection.propTypes = {
  containerStyle: PropTypes.object,
  overrideContentSectionCss: PropTypes.bool,
};

ContentSection.defaultProps = { overrideContentSectionCss: false };

export default ContentSection;
