import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { addErrorNotification } from "redux/system/system-action-creators";
import ApiService from "./services/apiService";
import timeZoneData from "constants/data/time-zone-data";
import {
  getCurrentCountryLookupValue,
  getCurrentTimezoneLookupValue,
} from "./helpers/helper";
import { checkRedirectionRules } from "services/redirect-rules-service";
import { AccountSetupStatusTypes } from "constants/account-setup-status-types";
import countryData from "./../../constants/data/country-data";
import { AppUrls } from "constants/app-urls";

const initialState = {
  timezoneLookups: timeZoneData,
  countryLookups: countryData,
  entryFormDefaults: {
    staffdUrl: "",
    invalidCode: false,
    timezone: "",
    country: "",
    adminUserFirstName: "",
    adminUserLastName: "",
  },

  staffdUrlToUpdate: "",

  logoInfo: {
    processedImageBase64: "",
    width: "",
    height: "",
    hasBackgroundColour: false,
    bgColour: "",
  },

  oneTimeLinkRedirect: "",
  nextProcessRedirectTo: "",

  companyBusyMode: false,
  validateCodeBusyMode: false,
  processLogoBusyMode: false,
  loading: false,
  provisioning: false,
  provisioningFailed: false,
};

// ---------------
// Action Thunks
// ---------------
export const generateTenantCode = createAsyncThunk(
  "appSetupV2/generateTenantCode",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().generateCode(data);

      if (typeof result.data === "object") return "";

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to generate code."));
      throw err;
    }
  }
);

export const validateCode = createAsyncThunk(
  "appSetupV2/validateCode",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().validateCode(data);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to validate code."));
      throw err;
    }
  }
);

export const processLogo = createAsyncThunk(
  "appSetupV2/processLogo",
  async (data, thunkAPI) => {
    try {
      let payload = { base64Image: data.imageStr };
      let result = await ApiService().processLogo(payload);

      return {
        ...result.data,
        hasBackgroundColour: data.hasBackgroundColour,
        bgColour: data.bgColour,
      };
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to process image."));
      throw err;
    }
  }
);

export const provisionTenant = createAsyncThunk(
  "appSetupV2/provisionTenant",
  async (data, thunkAPI) => {
    try {
      let logoInfoState = getLogoInfo(thunkAPI.getState);
      let formDefaults = getEntryFormDefaults(thunkAPI.getState);

      let payload = {
        tenantCode: data.staffdUrl,
        timezoneId: data.timezone.value,
        companyPhoneNumber: data.companyPhoneNumber,
        adminUserFirstName: formDefaults.adminUserFirstName,
        adminUserLastName: formDefaults.adminUserLastName,
        notificationsEmail: data.notificationsEmail,
        logoImage: data.logoImage.replace("data:image/png;base64,", ""),
        useLogoBackground: logoInfoState.hasBackgroundColour,
        logoBgColor: logoInfoState.bgColour,
        countrySetupType: data.country.value,
      };

      let result = await ApiService().provisionTenant(payload);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        addErrorNotification(err, "Unable to provision tenant.")
      );
      throw err;
    }
  }
);

export const initLoadData = createAsyncThunk(
  "appSetupV2/initLoadData",
  async (data, thunkAPI) => {
    try {
      let result = await ApiService().getInitialTenantInfo(data);

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(addErrorNotification(err, "Unable to load data."));
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const appSetupV2Slice = createSlice({
  name: "appSetupV2",
  initialState,
  reducers: {
    updateLogoInfo: (state, action) => {
      state.logoInfo.hasBackgroundColour = action.payload.hasBackgroundColour;
      state.logoInfo.bgColour = action.payload.bgColour;
    },
    hideProvisioningError: (state) => {
      state.provisioningFailed = false;
    },
    testToggle: (state, action) => {
      state.provisioning = !state.provisioning;
    },
    removeLogoInfo: (state, action) => {
      state.logoInfo.processedImageBase64 = "";
      state.logoInfo.width = "";
      state.logoInfo.height = "";
      state.logoInfo.hasBackgroundColour = false;
      state.logoInfo.bgColour = "";
    },
  },
  extraReducers: (builder) => {
    //
    // generateTenantCode extra reducers
    //
    builder.addCase(generateTenantCode.pending, (state) => {
      state.companyBusyMode = true;
    });

    builder.addCase(generateTenantCode.fulfilled, (state, action) => {
      state.companyBusyMode = false;
      state.staffdUrlToUpdate = action.payload;
      //state.entryFormDefaults.staffdUrl = action.payload;
      state.entryFormDefaults.invalidCode = false;
    });

    builder.addCase(generateTenantCode.rejected, (state, action) => {
      state.companyBusyMode = false;
    });

    //
    // validateCode extra reducers
    //
    builder.addCase(validateCode.pending, (state) => {
      state.validateCodeBusyMode = true;
    });

    builder.addCase(validateCode.fulfilled, (state, action) => {
      state.validateCodeBusyMode = false;
      state.entryFormDefaults.invalidCode = !action.payload.isSuccess;
    });

    builder.addCase(validateCode.rejected, (state, action) => {
      state.validateCodeBusyMode = false;
    });

    //
    // processLogo extra reducers
    //
    builder.addCase(processLogo.pending, (state) => {
      state.processLogoBusyMode = true;
    });

    builder.addCase(processLogo.fulfilled, (state, action) => {
      state.logoInfo.processedImageBase64 = `data:image/png;base64,${action.payload.imageBase64}`;
      state.logoInfo.width = action.payload.width;
      state.logoInfo.height = action.payload.height;
      state.logoInfo.hasBackgroundColour = action.payload.hasBackgroundColour;
      state.logoInfo.bgColour = action.payload.bgColour;
      state.processLogoBusyMode = false;
    });

    builder.addCase(processLogo.rejected, (state) => {
      state.processLogoBusyMode = false;
    });

    //
    // provision tenant
    //
    builder.addCase(provisionTenant.pending, (state) => {
      state.provisioning = true;
    });

    builder.addCase(provisionTenant.fulfilled, (state, action) => {
      state.provisioning = false;
      state.oneTimeLinkRedirect = action.payload;
    });

    builder.addCase(provisionTenant.rejected, (state) => {
      state.provisioning = false;
      state.provisioningFailed = true;
    });

    //
    // get initial tenant
    //
    builder.addCase(initLoadData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(initLoadData.fulfilled, (state, action) => {
      if (action.payload.isAdmin) {
        state.nextProcessRedirectTo = AppUrls.ADMIN;
        return;
      }

      let redirecTo = checkRedirectionRules(
        action.payload.accountStatus.status
      );

      if (
        action.payload.accountStatus.status !==
        AccountSetupStatusTypes.SIGNUP_COMPLETED
      ) {
        state.nextProcessRedirectTo = redirecTo;
        return;
      }

      state.loading = false;
      state.entryFormDefaults.userId = action.payload.userId;
      state.entryFormDefaults.staffdUrl = action.payload.tenantCode;
      state.entryFormDefaults.adminUserFirstName = action.payload.firstName;
      state.entryFormDefaults.adminUserLastName = action.payload.lastName;

      let tzLookups = state.timezoneLookups.map((o, i) => ({
        label: o.label,
        value: o.value,
      }));
      state.entryFormDefaults.timezone =
        getCurrentTimezoneLookupValue(tzLookups);

      let countryLookups = state.countryLookups.map((o, i) => ({
        label: o.label,
        value: o.value,
      }));
      state.entryFormDefaults.country =
        getCurrentCountryLookupValue(countryLookups);
    });

    builder.addCase(initLoadData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  updateLogoInfo,
  testToggle,
  hideProvisioningError,
  removeLogoInfo,
} = appSetupV2Slice.actions;

export default appSetupV2Slice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.appSetupV2;

export const selectappSetupV2 = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

// -----------
// Helpers
// -----------
const getLogoInfo = (stateFn) => stateFn().appSetupV2.logoInfo;

const getEntryFormDefaults = (stateFn) =>
  stateFn().appSetupV2.entryFormDefaults;
