import { ActionTypes } from "./action-types";
import { ConnectionState } from "./connection-state-enum";

const initialState = {
  status: ConnectionState.DISCONNECTED,
  signalRInitialised: false,

  loading: false,
  error: {},
};

function SystemRealTimeCommsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SYSTEM_REAL_TIME_COMMS_SET_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };

    case ActionTypes.SYSTEM_REAL_TIME_COMMS_INIT_SIGNALR:
      return {
        ...state,
        signalRInitialised: action.payload,
      };

    default:
      return state;
  }
}

export default SystemRealTimeCommsReducer;

// ----------------------------------------------------
//  Selectors
// ----------------------------------------------------
export const SystemRealTimeCommsSelector = (state) => state.systemRealTimeComms;

/** Return if ONLINE or OFFLINE */
export const IsOnlineRealTimeCommsSelector = (state) =>
  SystemRealTimeCommsSelector(state).status === ConnectionState.CONNECTED;
