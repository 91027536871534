import React from "react";
import PropTypes from "prop-types";
import "./mat-select-field.scss";
import { noop } from "lodash";

function MatSelectField({ name, label, data, value, onChange, onClick }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <section className="select-field-material">
      <div className="select">
        <select
          id={name}
          className="select-text"
          onChange={handleChange}
          onClick={onClick}
          value={value}
          required
        >
          {/* <optgroup label="Select Preferred Day" >  */}
          <option value="" disabled></option>
          {data.map((o, index) => (
            <option key={index} value={o.id}>
              &nbsp;&nbsp;&nbsp;&nbsp;{o.value}
            </option>
          ))}
          <option value="" disabled></option>
          {/* </optgroup>  */}
        </select>
        <span className="select-highlight"></span>
        <span className="select-bar"></span>
        <label className="select-label" htmlFor={name}>
          {label}
        </label>
      </div>
    </section>
  );
}

MatSelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

MatSelectField.defaultProps = {
  label: "Label",
  value: "",
  data: [],
  onChange: noop,
  onClick: noop
};

export default MatSelectField;
