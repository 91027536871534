export const attributes = [
  {
    name: "data",
    type: "DeleteEmployeeSkillData object",
    description: "The Delete Employee's skill payload object.",
    linkToTag: "#delete-employee-skill-data-object",
  },
  {
    name: "signature",
    type: "string",
    description: "A secure salted hash of the data object.",
  },
];

export const payload = {
  data: {
    externalUserId: "1234",
    externalSkillId: "68",
  },
  signature: "059392598ce733fd657c04bed8467e94",
};

export const responseAttributes = [
  {
    name: "message",
    type: "string (optional)",
    description:
      "An optional message that can be saved as an Employee comment in Staffd. When a validation or system error occurs, it is recommended to include a human readable message for the admin to understand the reason why the sync failed.",
  },
];

export const successResponse = {
  message: null,
};

export const failureResponse = {
  message: "The employee's skill was not found.",
};
