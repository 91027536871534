const timeZoneData = [
  {
    label: "(UTC-10:00) Hawaii",
    value: "Hawaiian Standard Time",
  },
  {
    label: "(UTC-09:00) Alaska",
    value: "Alaskan Standard Time",
  },
  {
    label: "(UTC-08:00) Pacific Time (US & Canada)",
    value: "Pacific Standard Time",
  },
  {
    label: "(UTC-07:00) Arizona",
    value: "US Mountain Standard Time",
  },
  {
    label: "(UTC-07:00) Mountain Time (US & Canada)",
    value: "Mountain Standard Time",
  },
  {
    label: "(UTC-06:00) Central Time (US & Canada)",
    value: "Central Standard Time",
  },
  {
    label: "(UTC-05:00) Eastern Time (US & Canada)",
    value: "Eastern Standard Time",
  },
  {
    label: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    value: "GMT Standard Time",
  },
  {
    label: "(UTC+08:00) Perth",
    value: "W. Australia Standard Time",
  },
  {
    label: "(UTC+09:30) Adelaide",
    value: "Cen. Australia Standard Time",
  },
  {
    label: "(UTC+09:30) Darwin",
    value: "AUS Central Standard Time",
  },
  {
    label: "(UTC+10:00) Brisbane",
    value: "E. Australia Standard Time",
  },
  {
    label: "(UTC+10:00) Canberra, Melbourne, Sydney",
    value: "AUS Eastern Standard Time",
  },
  {
    label: "(UTC+10:00) Hobart",
    value: "Tasmania Standard Time",
  },
  {
    label: "(UTC+12:00) Auckland, Wellington",
    value: "New Zealand Standard Time",
  },
];

export default timeZoneData;
