export const AppUrls = Object.freeze({
  SIGNUP: "/signup",
  REGISTRATION: "/signup/account-intro",
  EXISTING_CHECK: "/signup/existing-check",
  REGISTRATION_FORM: "/signup/account",
  PLAN: "/signup/plan-intro",
  PLAN_FORM: "/signup/plan",
  CREDIT_OPTION: "/signup/creditoption",
  FREE_TRIAL_OPTION: "/signup/free-trial",
  //MANAGE_SETUP: "/manage/setup",
  MANAGE_SETUP: "/signup/app-setup",
  MY_ACCOUNT: "/manage/my-account",
  TAX_INVOICES: "/manage/tax-invoices",
  ADMIN: "/admin",
});
